import { takeLatest, call, put, select, all, delay } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { BlockBlobClient } from '@azure/storage-blob';
import {
  setStorageLocationUrl,
  setFileUploadedToStorage,
  setFileUploadState,
  setBulkOperationState,
  setBulkOperations,
  setOperationDetails,
  setOperationsDetailsList,
  setRunningOperationsDetails,
  setOperationsDetailsListState,
} from './reducers';
import {
  selectOperationsDetailsList,
  selectBulkOperations,
  selectRunningOperationsDetails,
  selectStorageLocationUrl,
} from './selectors';
import * as actions from './actions';
import * as services from './services';
import * as datetimeHelper from '../../utilities/datetime-helper';
import * as fieldHelper from '../../utilities/field-helper';
import { selectOrganisationId } from '../auth/selectors';
import {
  BulkOperationDetailsResponse,
  BulkOperationRequest,
  BulkOperationResponse,
  BulkOperationsResponse,
} from '../../entities/cardOperations';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import {
  BulkOperationDetailsModel,
  BulkOperationDetailsResult,
  BulkOperationDetailsSummary,
  BulkOperationsItemModel,
  BulkOperationsModel,
} from '../../models/cardOperationModel';
import { setGenericErrorData } from '../generic-error/reducers';
import { getGenericErrorMessage } from '../../utilities/errorhandler';
import { LoadingStatus } from '../../constants/loading-constants';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_BULK_OPERATIONS, loadBulkOperations);
  yield takeLatest(actions.INIT_LOAD_OPERATION_BY_ID, initLoadOperationById);
  yield takeLatest(actions.LOAD_OPERATION_BY_ID_STORE_IN_LIST, loadOperationByIdStoreInList);
  yield takeLatest(actions.LOAD_RUNNING_OPERATION_BY_ID, loadRunningOperationById);
  yield takeLatest(actions.BULK_CARDS_OPERATION, bulkCardsOperation);
  yield takeLatest(actions.UPLOAD_FILE_TO_STORAGE, uploadFileToStorage);
  yield takeLatest(actions.SET_IS_FILE_UOLOADED_TO_STORAGE, setIsFileUploadedToStorage);
}

export function* loadBulkOperations() {
  try {
    const organisationId: string = yield select(selectOrganisationId);
    yield put(setBulkOperationState(LoadingStatus.LOADING));
    let response: BulkOperationsResponse = yield call(services.getBulkOperations, organisationId);
    let records: BulkOperationsModel = yield call(MapBulkOperationsEntityToBulkOperationsModel, response);

    if (records?.runningOperations?.length > 0) {
      const runningOperationDetails: BulkOperationDetailsModel[] = yield all(
        records.runningOperations.map((operation) => {
          return call(function* () {
            try {
              const response: BulkOperationDetailsModel = yield call(loadRunningOperationById, {
                type: actions.INIT_LOAD_OPERATION_BY_ID,
                payload: operation.id,
              });
              return response;
            } catch (error) {
              return { data: 'error' };
            }
          });
        })
      );
      yield put(setRunningOperationsDetails(runningOperationDetails));
    }

    yield put(setBulkOperations(records));
    yield put(setBulkOperationState(LoadingStatus.SUCCESS));

    if (records.pendingOperations.length > 0 || records.completedOperations.length > 0) {
      yield call(reLoadBulkOperations);
    }
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
  }
}

export function* reLoadBulkOperations() {
  try {
    const runningOperationDetails: BulkOperationDetailsModel[] = yield select(selectRunningOperationsDetails);
    const bulkOperations: BulkOperationsModel = yield select(selectBulkOperations);
    const pendingOperations = bulkOperations.pendingOperations;

    let pendingOperationsLength = pendingOperations?.length;
    let runningOperationsLength = runningOperationDetails?.length;

    while (pendingOperationsLength > 0 || runningOperationsLength > 0) {
      yield delay(15000);
      const organisationId: string = yield select(selectOrganisationId);
      let response: BulkOperationsResponse = yield call(services.getBulkOperations, organisationId);
      let records: BulkOperationsModel = yield call(MapBulkOperationsEntityToBulkOperationsModel, response);
      let runningOperationDetails: BulkOperationDetailsModel[] = [];
      if (records.runningOperations.length > 0) {
        runningOperationDetails = yield all(
          records.runningOperations.map((operation) => {
            return call(function* () {
              try {
                const response: BulkOperationDetailsModel = yield call(loadRunningOperationById, {
                  type: actions.LOAD_RUNNING_OPERATION_BY_ID,
                  payload: operation.id,
                });
                return response;
              } catch (error) {
                return { data: 'error' };
              }
            });
          })
        );
      }

      pendingOperationsLength = records.pendingOperations.length;
      runningOperationsLength = records.runningOperations.length;

      if (runningOperationDetails.length > 0) {
        yield put(setRunningOperationsDetails(runningOperationDetails));
        yield put(setBulkOperations(records));
      } else {
        yield put(setBulkOperations(records));
      }
    }
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
  }
}

export function* initLoadOperationById(action: PayloadAction<string>) {
  try {
    const organisationId: string = yield select(selectOrganisationId);
    yield put(setBulkOperationState(LoadingStatus.LOADING));
    let response: BulkOperationDetailsResponse = yield call(
      services.getBulkOperationById,
      action.payload,
      organisationId
    );
    let records: BulkOperationDetailsModel = yield call(
      MapBulkOperationDetailsEntityToBulkOperationDetailsModel,
      response
    );
    yield put(setOperationDetails(records));
    yield put(setBulkOperationState(LoadingStatus.SUCCESS));
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
  }
}

export function* loadOperationByIdStoreInList(action: PayloadAction<string>) {
  try {
    const organisationId: string = yield select(selectOrganisationId);
    yield put(setOperationsDetailsListState(LoadingStatus.LOADING));
    let response: BulkOperationDetailsResponse = yield call(
      services.getBulkOperationById,
      action.payload,
      organisationId
    );
    let newRecord: BulkOperationDetailsModel = yield call(
      MapBulkOperationDetailsEntityToBulkOperationDetailsModel,
      response
    );
    const records: BulkOperationDetailsModel[] = yield select(selectOperationsDetailsList);
    let newRecords;
    if (records) {
      newRecords = records.concat(newRecord);
    } else {
      newRecords = [newRecord];
    }
    yield put(setOperationsDetailsList(newRecords));
    yield put(setOperationsDetailsListState(LoadingStatus.SUCCESS));
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
  }
}

export function* loadRunningOperationById(action: PayloadAction<string>) {
  try {
    const organisationId: string = yield select(selectOrganisationId);
    let response: BulkOperationDetailsResponse = yield call(
      services.getBulkOperationById,
      action.payload,
      organisationId
    );
    let records: BulkOperationDetailsModel = yield call(
      MapBulkOperationDetailsEntityToBulkOperationDetailsModel,
      response
    );
    return records;
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
  }
}

export function* bulkCardsOperation(action: PayloadAction<string>) {
  const organisationId: string = yield select(selectOrganisationId);
  const bulkCardRequest: BulkOperationRequest = {
    type: action.payload,
    organisationId: organisationId,
  };
  let createBulkResponse: BulkOperationResponse = yield call(services.createBulkOperation, bulkCardRequest);
  yield put(setFileUploadedToStorage(false));
  yield put(setStorageLocationUrl(createBulkResponse.storageLocationUrl));
}

export function* uploadFileToStorage(action: PayloadAction<File>) {
  const storageUrl: string = yield select(selectStorageLocationUrl);
  try {
    yield put(setFileUploadedToStorage(false));
    const blockSize = 4 * 1024 * 1024;
    const concurrency = 25;
    yield uploadFileToStorageLink(storageUrl, action.payload, blockSize, concurrency);
    yield put(setFileUploadedToStorage(true));
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
      yield put(setFileUploadedToStorage(false));
    }
  }
}

function* uploadFileToStorageLink(storageLink: string, file: File, blockSize: number, concurrency: number) {
  try {
    // Initialize the options for upload
    const options = {
      blockSize,
      concurrency,
    };
    // Create a BlockBlobClient with the pre-signed URL
    const blobClient = new BlockBlobClient(storageLink);

    yield put(setFileUploadState(LoadingStatus.LOADING));
    yield blobClient.uploadData(file, options);
    yield put(setFileUploadState(LoadingStatus.SUCCESS));
  } catch (error) {
    let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
    put(setGenericErrorData(genericErrorData));
    yield put(setFileUploadState(LoadingStatus.ERROR));
  }
}

export function* setIsFileUploadedToStorage(action: PayloadAction<boolean>) {
  yield put(setFileUploadedToStorage(action.payload));
}

const MapBulkOperationsEntityToBulkOperationsModel = (bulkOperations_response: BulkOperationsResponse) => {
  if (bulkOperations_response && bulkOperations_response.items.length > 0) {
    const runningOperations: BulkOperationsItemModel[] = bulkOperations_response.items
      .filter((it) => it.state === 'running')
      .map((item) => {
        return {
          id: item?.id,
          type: item?.type,
          state: item?.state,
          healthIndicator: fieldHelper.getBulkStateIndicator(item?.state),
          errorMessage: item?.errorMessage,
          createdDateTimeUtc: item?.createdDateTimeUtc,
          lastUpdatedDateTimeUtc: datetimeHelper.getDayCounter(item?.lastUpdatedDateTimeUtc),
        };
      });

    const pendingOperations: BulkOperationsItemModel[] = bulkOperations_response.items
      .filter((it) => it.state === 'pending')
      .map((item) => {
        return {
          id: item?.id,
          type: item?.type,
          state: item?.state,
          healthIndicator: fieldHelper.getBulkStateIndicator(item?.state),
          errorMessage: item?.errorMessage,
          createdDateTimeUtc: item?.createdDateTimeUtc,
          lastUpdatedDateTimeUtc: datetimeHelper.getDayCounter(item?.lastUpdatedDateTimeUtc),
        };
      })
      .slice(0, 5);

    const completedOperations: BulkOperationsItemModel[] = bulkOperations_response.items
      .filter((it) => it.state === 'completed' || it.state === 'failed')
      .map((item) => {
        return {
          id: item?.id,
          type: item?.type,
          state: item?.state,
          healthIndicator: fieldHelper.getBulkStateIndicator(item?.state),
          errorMessage: item?.errorMessage,
          createdDateTimeUtc: item?.createdDateTimeUtc,
          lastUpdatedDateTimeUtc: datetimeHelper.getDayCounter(item?.lastUpdatedDateTimeUtc),
        };
      })
      .slice(0, 10);

    const result: BulkOperationsModel = {
      count: bulkOperations_response.count,
      runningOperations: runningOperations,
      pendingOperations: pendingOperations,
      completedOperations: completedOperations,
    };

    return result;
  }

  return {} as BulkOperationsModel;
};

const MapBulkOperationDetailsEntityToBulkOperationDetailsModel = (
  bulkOperationDetails_response: BulkOperationDetailsResponse
) => {
  if (bulkOperationDetails_response) {
    const bulkOperationDetailsSummary: BulkOperationDetailsSummary = {
      totalItems: bulkOperationDetails_response.result.summary.totalItems,
      processedItems: bulkOperationDetails_response.result.summary.processedItems,
      succeededItems: bulkOperationDetails_response.result.summary.succeededItems,
      failedItems: bulkOperationDetails_response.result.summary.failedItems,
      skippedItems: bulkOperationDetails_response.result.summary.skippedItems,
      createdItems: bulkOperationDetails_response.result.summary.createdItems,
      updatedItems: bulkOperationDetails_response.result.summary.updatedItems,
      deletedItems: bulkOperationDetails_response.result.summary.deletedItems,
    };

    const bulkOperationDetailsResult: BulkOperationDetailsResult = {
      summary: bulkOperationDetailsSummary,
      itemsInResultSet: bulkOperationDetails_response.result.itemsInResultSet,
      items: bulkOperationDetails_response.result.items,
    };

    const result: BulkOperationDetailsModel = {
      id: bulkOperationDetails_response.id,
      organisationId: bulkOperationDetails_response.organisationId,
      type: bulkOperationDetails_response.type,
      state: bulkOperationDetails_response.state,
      userId: bulkOperationDetails_response.userId,
      storageLocationUrl: bulkOperationDetails_response.storageLocationUrl,
      result: bulkOperationDetailsResult,
      createdDateTimeUtc: bulkOperationDetails_response.createdDateTimeUtc,
      lastUpdatedDateTimeUtc: bulkOperationDetails_response.lastUpdatedDateTimeUtc,
    };

    return result;
  }

  return {} as BulkOperationDetailsModel;
};
