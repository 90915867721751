import React from 'react';
import classNames from 'classnames';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/material';
import { PumpDetailModel, PumpRecordModel } from '../../models/pumpModel';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import { ItemDetailModel } from '../../models/baseModels/itemDetailModel';
import { PumpStatusType } from '../../constants/status';
import { getStatusMessageCount } from '../../utilities/general-helpers';
import CollapsibleCard from '../../components/collapsible-card/collapsible-card';
import Card from '../../components/cards/cards';
import Item from '../../components/box-items/box-item';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import * as generalHelper from '../../utilities/general-helper';
import './styles/pumps-item.scss';

interface PumpItemProps {
  id: number;
  pumpItem: PumpRecordModel;
  hasDeleteAccess: boolean;
  hasUpdateAccess: boolean;
  onClick: (id: string) => void;
  onDelete: (id: string, name: string) => void;
  onEdit: (id: string) => void;
}

const PumpItem: React.FC<PumpItemProps> = (props: PumpItemProps) => {
  const { id, pumpItem, onDelete, onEdit, hasDeleteAccess, hasUpdateAccess, onClick } = props;

  const displayMenu: boolean = hasDeleteAccess || hasUpdateAccess;

  let fixedItemDetail: PumpDetailModel[] = [
    {
      id: id,
      name: 'Status',
      value: fieldMappingHelper.getDisplayValue(pumpItem.status, PumpStatusType),
      status: pumpItem.status,
      healthIndicator: pumpItem.healthIndicator,
    },
    {
      id: id,
      name: 'Last Status Update',
      value: pumpItem.lastUpdatedDateTimeUtc,
      status: pumpItem.status,
      healthIndicator: 'display-none',
    },
  ];
  let actionItems: FloatingMenuItem[] = [];
  let moreItemDetails: ItemDetailModel[] = [];

  if (hasUpdateAccess) {
    actionItems.push({
      label: 'Edit Pump',
      handler: () => {
        onEdit(pumpItem.id);
      },
    });
  }

  if (hasDeleteAccess) {
    actionItems.push({
      label: 'Delete Pump',
      handler: () => {
        onDelete(pumpItem.id, pumpItem.number.toString());
      },
    });
  }

  if (pumpItem?.transactions?.current?.products[0]?.name) {
    moreItemDetails.push({
      name: 'Product Name',
      value: `${pumpItem.transactions.current.products[0].name}`,
    });
  }

  if (pumpItem?.transactions?.current?.products[0]?.hoseNumber) {
    moreItemDetails.push({
      name: 'Hose Number',
      value: `${pumpItem.transactions.current.products[0].hoseNumber}`,
    });
  }

  if (pumpItem?.transactions?.current?.products[0]?.amount) {
    moreItemDetails.push({
      name: 'Amount',
      value: `$${generalHelper.numberHandlerWithDP(pumpItem.transactions.current.products[0].amount, 2)}`,
    });
  }

  if (pumpItem?.transactions?.current?.products[0]?.quantity) {
    moreItemDetails.push({
      name: 'Quantity',
      value: `${generalHelper.numberHandlerWithDP(pumpItem.transactions.current.products[0].quantity, 2)}L`,
    });
  }

  if (pumpItem?.transactions?.current?.products[0]?.unitPrice) {
    moreItemDetails.push({
      name: 'Unit Price',
      value: `$${generalHelper.numberHandlerWithDP(pumpItem.transactions.current.products[0].unitPrice, 3)}`,
    });
  }

  if (pumpItem?.transactions?.current?.reference) {
    moreItemDetails.push({
      name: 'Refrence',
      value: `${pumpItem.transactions.current.reference}`,
    });
  }

  const onItemClick = () => {
    if (onClick) {
      onClick(pumpItem.id);
    }
  };

  const StatusDetail = () => {
    return (
      <span>{pumpItem?.messages && pumpItem?.messages?.length > 0 && getStatusMessageCount(pumpItem?.messages)}</span>
    );
  };

  return (
    <React.Fragment>
      <Card className={classNames('box-card-parent')}>
        <Box className={classNames('primary-details')}>
          <Item onClick={onItemClick} className={classNames('box-row-columns')}>
            <Item className={classNames('box-row-item-header')}>
              <div className={classNames('icon-name-header')}>
                <Item className={classNames('list-icon-size', 'img-size-mob', 'pumps-icon-img')}></Item>
                <div>
                  <Item className={classNames('list-column-header', 'item-header-name')}>
                    {pumpItem.number || 'Unspecified'}
                  </Item>
                  <Item className={classNames('list-column-content', 'item-primary-header-name')}>Pump</Item>
                </div>
              </div>
            </Item>
            <Item className={classNames('item-primary-header', 'item-primary-header-mobile')}>
              <div>
                <Item className={classNames('list-column-header', 'item-primary-header-name')}>Status</Item>
                <Item className={classNames('list-column-content')}>
                  <Item className={classNames('status-icon', pumpItem.healthIndicator)} />
                  {fieldMappingHelper.getDisplayValue(pumpItem.status, PumpStatusType)}
                </Item>
              </div>
            </Item>
            <Item className={classNames('item-primary-header', 'item-primary-header-tablet')}>
              <div>
                <Item className={classNames('list-column-header', 'item-primary-header-name')}>Last Status Update</Item>
                <Item className={classNames('list-column-content')}>{pumpItem.lastUpdatedDateTimeUtc}</Item>
              </div>
            </Item>
          </Item>
          {displayMenu ? (
            <Item className={classNames('row-item-ellipse')}>
              <FloatingMenu key={id} buttonNode={<MoreVertIcon className='ellipse-icon' />} items={actionItems} />
            </Item>
          ) : (
            <Box className='box-item' onClick={onItemClick}></Box>
          )}
        </Box>
        <CollapsibleCard
          id={id.toString()}
          itemDetails={moreItemDetails}
          fixeditemDetails={fixedItemDetail}
          rowViewItemDetails={pumpItem?.messages}
          showMoreDetailText={pumpItem?.messages && pumpItem?.messages?.length > 0 && StatusDetail()}
          onClick={onItemClick}
        ></CollapsibleCard>
      </Card>
    </React.Fragment>
  );
};

export default PumpItem;
