import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectFileUploadedToStorage, selectFileUploadState } from '../../../store/card-operations/selectors';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import * as cardOperationActions from '../../../store/card-operations/actions';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import * as headerActions from '../../../store/header/actions';
import * as dialogActions from '../../../store/dialog-box/actions';
import CardOperation from './card-operation';

const mapStateToProps = (state: RootState) => {
  return {
    filedUploadedToStorage: selectFileUploadedToStorage(state),
    fileUploadState: selectFileUploadState(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    bulkCardOperation: (data: string) =>
      dispatch({
        type: cardOperationActions.BULK_CARDS_OPERATION,
        payload: data,
      }),
    uploadFileToStorage: (data: any) =>
      dispatch({
        type: cardOperationActions.UPLOAD_FILE_TO_STORAGE,
        payload: data,
      }),
    setIsFileUploadedToStorage: (data: boolean) =>
      dispatch({
        type: cardOperationActions.SET_IS_FILE_UOLOADED_TO_STORAGE,
        payload: data,
      }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
  };
};

const CardOperationPage = connect(mapStateToProps, mapDispatchToProps)(CardOperation);

export default CardOperationPage;
