import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, Link, MenuItem, Paper, Popper } from '@mui/material';
import { ValidationError } from '../../models/baseModels/validationModel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import classNames from 'classnames';
import Chip from '../chip/chip';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import CustomTextField from '../text-field/text-field.container';
import CustomCheckBox from '../checkbox/checkbox';
import CustomTooltip from '../tooltip/custom-tooltip';
import LoadingSkeleton from '../loading-skeleton/loading-skeleton';
import { LoadingStatus } from '../../constants/loading-constants';
import './multiselect-dropdown.scss';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

interface MultiSelectDropdownProps {
  id?: string;
  name: string;
  label: string;
  inlineLabel?: string;
  className?: string;
  placeholder: string;
  dataList: KeyValuePair[];
  value: KeyValuePair[];
  maxHeight: number;
  isMandatory?: boolean;
  validateCounter?: number;
  readOnly?: boolean;
  selectAllOption?: boolean;
  selectedAll?: boolean;
  noOptionsText?: string;
  tooltipTitle?: string;
  createOptionsLink?: string;
  keyValuePairLoadingStatus?: string;
  onBindingValue?: (newvalue: KeyValuePair[]) => void | undefined;
  fieldValidationStatus: (name: string) => ValidationError | undefined;
  setFieldValidation: (validation: ValidationError) => void;
  removeValidation: (name: string) => void;
  setShowValidationMessage: (value: KeyValuePair) => void;
  isSelectedAll?: (newvalue: boolean) => void;
  keyValuePairLoding?: () => void;
}

const MultiSelectDropdown = (props: MultiSelectDropdownProps) => {
  const {
    id,
    name,
    label,
    inlineLabel,
    className,
    placeholder,
    dataList,
    value,
    maxHeight,
    isMandatory,
    validateCounter,
    readOnly,
    selectAllOption,
    selectedAll,
    noOptionsText,
    tooltipTitle,
    createOptionsLink,
    keyValuePairLoadingStatus,
    isSelectedAll,
    onBindingValue,
    setFieldValidation,
    removeValidation,
    fieldValidationStatus,
    setShowValidationMessage,
    keyValuePairLoding,
  } = props;

  const [isShowMessage, setIsShowMessage] = useState(false);
  const popperAnchor = useRef({} as HTMLDivElement);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([] as KeyValuePair[]);
  const [isKeyValuePairLoadingTriggered, setIsKeyValuePairLoadingTriggered] = useState(false);

  const navigate = useNavigate();
  const isInitValueLoaded = useRef(false);

  const VALIDATION_MESSAGE_MANDATORY_FIELD = 'Required field';
  let inputRef: { focus: () => void };

  const onChangeValue = (event: React.SyntheticEvent<Element, Event> | null, value: (string | KeyValuePair)[]) => {
    if (onBindingValue) {
      setIsSelectAll(false);
      if (isSelectedAll) {
        isSelectedAll(false);
      }
      onBindingValue(value as KeyValuePair[]);
      if (isMandatory) {
        CheckMandatoryValue(value as KeyValuePair[]);
      }
      setSelectedOptions(value as KeyValuePair[]);
    }
  };

  /**FOR VALIDATION */
  const CheckMandatoryValue = useCallback(
    (values: KeyValuePair[]) => {
      if (isSelectAll) {
        removeValidation(name);
        return;
      }
      if (values?.length <= 0) {
        setFieldValidation({
          name,
          hasError: true,
          showErrorMessage: false,
          validationMessage: VALIDATION_MESSAGE_MANDATORY_FIELD,
        });
      } else {
        removeValidation(name);
      }
    },
    [VALIDATION_MESSAGE_MANDATORY_FIELD, name, isSelectAll, setFieldValidation, removeValidation]
  );

  const validateData = useCallback(
    (value: KeyValuePair[]) => {
      if (isMandatory) {
        CheckMandatoryValue(value);
      }
    },
    [CheckMandatoryValue, isMandatory, isSelectAll]
  );

  useEffect(() => {
    if (validateCounter && validateCounter > 0) {
      setIsShowMessage(true);
      validateData(value?.length > 0 ? value : []);
      setShowValidationMessage({ key: name, value: true });
    }
  }, [validateCounter, value?.length, name, validateData, setShowValidationMessage]);

  // from implementation, parent component control
  useEffect(() => {
    if (selectedAll && isSelectedAll && selectAllOption) {
      setIsSelectAll(true);
      setSelectedOptions(dataList);
    }
  }, [selectedAll, dataList]);

  // component control
  useEffect(() => {
    if (isSelectAll) {
      setSelectedOptions(dataList);
    }
    if (isSelectedAll) {
      isSelectedAll(isSelectAll);
    }
    if (!isSelectAll && isSelectedAll) {
      isSelectedAll(false);
    }
  }, [isSelectAll]);

  // init check
  useEffect(() => {
    if (dataList && value?.length > 0 && !value?.find((it) => it?.key === 'selectAll') && !isInitValueLoaded.current) {
      setSelectedOptions(value?.filter((it) => it?.key !== 'selectAll'));
      isInitValueLoaded.current = true;
    }
  }, [value]);

  useEffect(() => {
    if (selectedOptions?.length === dataList?.length && selectAllOption) {
      setIsSelectAll(true);
    }
    if (isMandatory) {
      CheckMandatoryValue(selectedOptions);
    }
  }, [selectedOptions]);

  const selectFocusHandler = (event: React.SyntheticEvent) => {
    setIsShowMessage(true);
    setShowValidationMessage({ key: name, value: true });
    if (keyValuePairLoding && !isKeyValuePairLoadingTriggered) {
      setIsKeyValuePairLoadingTriggered(true);
      keyValuePairLoding();
    }
  };

  const selectBlurHandler = (event: React.SyntheticEvent) => {
    setIsShowMessage(true);
    setShowValidationMessage({ key: name, value: true });
  };

  const selectAllCheckedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isSelectAll) {
      setSelectedOptions([]);
    }
    setIsSelectAll(event.target.checked);
    if (isSelectedAll) {
      isSelectedAll(event.target.checked);
    }
  };

  const selectAllHandler = () => {
    setIsSelectAll(!isSelectAll);
    if (isSelectAll) {
      setSelectedOptions([]);
    }
  };

  const removeItemHandler = (item: KeyValuePair) => {
    onChangeValue(
      null,
      selectedOptions?.filter((v) => v.key !== item?.key)
    );
  };

  const emptyItemHandler = () => {
    onChangeValue(null, []);
  };

  const readOnlyValue: string =
    value?.length > 0
      ? `${String(
          value?.map((s) => {
            return ` ${s.value}`;
          })
        )}`.trimStart()
      : '';

  return (
    <div className={classNames('multiselect-field-container')}>
      {readOnly && selectedAll ? (
        <CustomTextField name={name} value='All' type='string' label={label} readOnly />
      ) : readOnly && inlineLabel ? (
        value?.length > 0 && (
          <CustomTextField name={name} value={readOnlyValue} type='string' label={label} isMultiline={true} readOnly />
        )
      ) : readOnly ? (
        <CustomTextField name={name} value={readOnlyValue} type='string' label={label} isMultiline={true} readOnly />
      ) : (
        <>
          {!inlineLabel && (
            <div className={classNames('multiselect-field-label', className)}>
              {label} {tooltipTitle && <CustomTooltip title={tooltipTitle} />}
              {isMandatory && <strong className='asterisk'> *</strong>}
            </div>
          )}
          <div className={inlineLabel ? classNames('inline-multiselect-field') : classNames('multiselect-field')}>
            {inlineLabel && <p className='inline-label'>{inlineLabel}</p>}
            <div ref={popperAnchor}>
              <Autocomplete
                className={
                  isShowMessage && fieldValidationStatus(name)?.hasError
                    ? classNames('autocomplete', 'autocomplete-error')
                    : classNames('autocomplete')
                }
                multiple
                disableClearable
                disableCloseOnSelect
                sx={{ maxHeight: maxHeight, overflow: 'auto' }}
                id={`size-small-filled-multi-${id}`}
                size='small'
                options={dataList || []}
                getOptionLabel={(data) => data?.value?.toString()}
                isOptionEqualToValue={(option, value) => option?.key === value?.key}
                noOptionsText={
                  createOptionsLink &&
                  (keyValuePairLoding
                    ? keyValuePairLoadingStatus !== LoadingStatus.LOADING && isKeyValuePairLoadingTriggered
                    : false) ? (
                    <Link
                      className='create-new-entity-link'
                      onMouseDown={() => {
                        navigate(createOptionsLink);
                      }}
                    >
                      {noOptionsText ? noOptionsText : 'No options'}, Click Here to Create
                    </Link>
                  ) : (
                    (keyValuePairLoding
                      ? keyValuePairLoadingStatus === LoadingStatus.SUCCESS && isKeyValuePairLoadingTriggered
                      : false) && noOptionsText
                  )
                }
                value={
                  selectedOptions ? selectedOptions : isSelectAll ? [{ key: 'selectAll', value: 'Select All' }] : []
                }
                onChange={onChangeValue}
                onBlur={selectBlurHandler}
                onOpen={selectFocusHandler}
                PaperComponent={({ style, children, ...props }) => {
                  return (
                    <Popper {...props} open={isShowMessage} anchorEl={popperAnchor.current} placement='bottom'>
                      <Paper {...props} style={{ width: popperAnchor.current.clientWidth }}>
                        {selectAllOption && dataList && dataList?.length > 0 && (
                          <>
                            <Box
                              className='multiselect-options'
                              onMouseDown={(e) => e.preventDefault()}
                              pl={1.5}
                              py={0.5}
                            >
                              <MenuItem onClick={selectAllHandler}>
                                <CustomCheckBox
                                  onChange={selectAllCheckedHandler}
                                  name='selectAllCheck'
                                  label='Select All'
                                  defaultState={false}
                                  isChecked={isSelectAll}
                                />
                              </MenuItem>
                            </Box>
                            <Divider />
                          </>
                        )}
                        {(!dataList || dataList?.length <= 0) &&
                          Array.from({ length: 3 }).map((_, i) => (
                            <MenuItem key={i} className='dropdownlist-item-loading'>
                              <LoadingSkeleton customHeight={30} />
                            </MenuItem>
                          ))}
                        {children}
                      </Paper>
                    </Popper>
                  );
                }}
                renderTags={(value, getTagProps) =>
                  isSelectAll ? (
                    <Chip
                      onDeleteChip={() => {
                        setIsSelectAll(false);
                        emptyItemHandler();
                      }}
                      label='All'
                      id={'selectAll'}
                      {...getTagProps}
                    />
                  ) : (
                    value?.map((option, index) => (
                      <Chip
                        onDeleteChip={() => removeItemHandler(option)}
                        label={option?.value?.toString()}
                        id={option?.key?.toString()}
                        {...getTagProps({ index })}
                      />
                    ))
                  )
                }
                renderInput={(params) => (
                  <TextField
                    className={classNames('multiselect', className)}
                    autoComplete='on'
                    placeholder={isSelectAll ? '' : placeholder}
                    inputRef={(input) => {
                      inputRef = input;
                    }}
                    {...params}
                  />
                )}
                renderOption={(props, option: KeyValuePair, { selected }) => (
                  <li
                    {...props}
                    style={{
                      display: option?.additionalValue ? 'block' : 'flex',
                      width: '100%',
                      justifyContent: 'flex-start',
                    }}
                  >
                    {option?.additionalValue ? (
                      <div className='dropdown-item-with-addtional-value-container'>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        <span className='dropdown-item-with-addtional-value'>
                          <span>{option?.value}</span>
                          <span className='dropndown-item-addtional-value'>{option?.additionalValue}</span>
                        </span>
                      </div>
                    ) : (
                      <>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        <span>{option?.value}</span>
                      </>
                    )}
                  </li>
                )}
              />
              {isShowMessage && fieldValidationStatus(name)?.hasError && (
                <div className='validation-text-message-error'>{fieldValidationStatus(name)?.validationMessage}</div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
