import {initialState} from '././productInitState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import { ProductInfoModel } from '../../models/productModel';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

const productSlice = createSlice({
    name: 'productState',
    initialState,
    reducers: {
        setProductStatus: (state, action: PayloadAction<string>) => {
            state.productStatus = action.payload;
            state.hasError = false;
        },
        setProductData: (state, action: PayloadAction<ProductInfoModel[]>) => {
            state.productData = action.payload;
            state.hasError = false;
        },
        setProductError: (state) => {
            state.hasError = true;
            state.productData = [];
            state.productStatus = LoadingStatus.ERROR;
        },
        setProductInfo: (state, action: PayloadAction<ProductInfoModel>) => {
            state.productItem = action.payload;
            state.hasError = false;
        },
        setProductListName: (state, action: PayloadAction<KeyValuePair[]>) => {
            state.productListName = action.payload;
            state.hasError = false;
        }
    }
});

export const {
    setProductStatus, 
    setProductData, 
    setProductError,
    setProductInfo,
    setProductListName
} = productSlice.actions;

export default productSlice.reducer;