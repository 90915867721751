import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { ForecourtControllerModel, ForecourtControllerRecordModel } from '../../../models/forecourtControllerModel';
import { ModuleName } from '../../../constants/module-constants';
import {
  DEFAULT_DROP_DOWN_VALUE,
  forecourtControllerBackOfficeRecordFormat,
  forecourtControllerCommunicationType,
  forecourtControllerType,
} from '../../../constants/dropdown-constants';
import { LoadingStatus } from '../../../constants/loading-constants';
import { FormActionType } from '../../../constants/form-constants';
import { TextFieldType } from '../../../constants/textfield-constants';
import { findSmallestNumberNotInArray } from '../../../utilities/general-helper';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import CustomTextField from '../../../components/text-field/text-field.container';
import Form from '../../../components/form/form.container';
import DropDown from '../../../components/dropdown/dropdown.container';
import '../styles/forecourt-controllers.scss';

interface ForecourtControllerPageProps {
  pageTitle?: string;
  action?: string;
  hasValidationError: boolean;
  backDropActionStatus: string;
  forecourtControllerStatus: string;
  forecourtControllerItem: ForecourtControllerModel;
  forecourtControllerContent: ForecourtControllerRecordModel[];
  loadForecourtControllerInfo: (data: string) => void;
  loadForecourtControllers: () => void;
  createForecourtController: (data: ForecourtControllerModel) => void;
  editForecourtController: (data: ForecourtControllerModel) => void;
  userAccess: (moduleName: string) => AuthorisationModel;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  removeAllValidation: () => void;
  removeValidation: (name: string) => void;
}

const ForecourtController: React.FC<ForecourtControllerPageProps> = (props: ForecourtControllerPageProps) => {
  const {
    pageTitle,
    action,
    hasValidationError,
    backDropActionStatus,
    forecourtControllerStatus,
    forecourtControllerItem,
    forecourtControllerContent,
    loadForecourtControllerInfo,
    loadForecourtControllers,
    createForecourtController,
    editForecourtController,
    userAccess,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    removeAllValidation,
    removeValidation,
  } = props;

  const [forecourtControllerValue, setForecourtControllerValue] = useState({} as ForecourtControllerModel);
  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [isUpdatingForm, setIsUpdatingForm] = useState(false);
  const [isClickFromViewPage, setIsClickFromViewPage] = useState(false);
  const [existingForecourtControllerNumberList, setExistingForecourtControllerNumberList] = useState([] as number[]);

  const navigate = useNavigate();
  const location = useLocation();
  const { orgId, siteId, forecourtControllerId } = useParams();
  const isLoadForecourtControllerInfoRequested = useRef(false);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  //ACCESS
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.RECEIPT_TEMPLATE).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.RECEIPT_TEMPLATE).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.RECEIPT_TEMPLATE).hasUpdateAccess);
  }, [userAccess]);

  useMemo(() => {
    setLoading(forecourtControllerStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(forecourtControllerStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(forecourtControllerStatus === LoadingStatus.ERROR);
  }, [hasReadAccess, forecourtControllerStatus]);

  useEffect(() => {
    if (forecourtControllerItem?.id) {
      setForecourtControllerValue(forecourtControllerItem);
    }
  }, [forecourtControllerItem]);

  useEffect(() => {
    setIsUpdatingForm(action === FormActionType.CREATE || action === FormActionType.EDIT);
  }, [location]);

  useEffect(() => {
    setIsPageDirty(false);
    if (
      !isLoadForecourtControllerInfoRequested.current &&
      hasReadAccess &&
      (action === FormActionType.VIEW || (action === FormActionType.EDIT && !isClickFromViewPage))
    ) {
      loadForecourtControllerInfo(forecourtControllerId ? forecourtControllerId : '');
      setIsSaveButtonEnabled(hasUpdateAccess);
      isLoadForecourtControllerInfoRequested.current = true;
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      setIsSaveButtonEnabled(hasCreateAccess);
      removeAllValidation();
    }
  }, [
    action,
    hasCreateAccess,
    hasReadAccess,
    hasUpdateAccess,
    forecourtControllerId,
    siteId,
    orgId,
    isClickFromViewPage,
    loadForecourtControllerInfo,
    setIsPageDirty,
    setIsSaveButtonEnabled,
    removeAllValidation,
  ]);

  useEffect(() => {
    if (action === FormActionType.CREATE && hasCreateAccess) {
      if (existingForecourtControllerNumberList?.length > 0) {
        setForecourtControllerValue({
          number: findSmallestNumberNotInArray(existingForecourtControllerNumberList, 1, 5),
          siteId: siteId,
          organisationId: orgId,
          type: 'none',
        } as ForecourtControllerModel);
      } else {
        setForecourtControllerValue({
          number: 1,
          siteId: siteId,
          organisationId: orgId,
          type: 'none',
        } as ForecourtControllerModel);
      }
    }
  }, [existingForecourtControllerNumberList]);

  useEffect(() => {
    switch (action) {
      case FormActionType.CREATE:
        setSuccess(hasCreateAccess);
        break;
      case FormActionType.EDIT:
        setSuccess(hasUpdateAccess);
        break;
      case FormActionType.VIEW:
        setSuccess(hasReadAccess);
        break;
    }
  }, [action, hasCreateAccess, hasReadAccess, hasUpdateAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: false,
      showSiteHeader: true,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, pageTitle, error]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: action === FormActionType.VIEW,
    } as PageSettingStateModel);
  }, [action, setPageConfiguration]);

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS || backDropActionStatus === LoadingStatus.WARNING) {
      navigate(`/organisations/${orgId}/sites/${siteId}/forecourt-controllers`);
    }
  }, [backDropActionStatus, orgId, siteId, navigate]);

  useEffect(() => {
    if (!forecourtControllerContent) {
      loadForecourtControllers();
      return;
    }
    if (
      forecourtControllerContent?.length !== existingForecourtControllerNumberList?.length &&
      action === FormActionType.CREATE &&
      hasCreateAccess
    )
      setExistingForecourtControllerNumberList(
        forecourtControllerContent.map((it) => {
          return it.number;
        })
      );
  }, [forecourtControllerContent]);

  useEffect(() => {
    if (forecourtControllerValue?.type === 'none') {
      removeValidation('serialCommunicationPort');
      removeValidation('networkCommunicationIpAddress');
      removeValidation('networkCommunicationPort');
      removeValidation('eptId');
      removeValidation('backOfficeRecordFormat');
      removeValidation('communicationType');
      setForecourtControllerValue({
        id: forecourtControllerId,
        number: forecourtControllerValue.number,
        siteId: siteId,
        organisationId: orgId,
        type: 'none',
      } as ForecourtControllerModel);
    } else if (forecourtControllerValue?.type === 'doms') {
      removeValidation('serialCommunicationPort');
    } else if (forecourtControllerValue?.type === 'postec') {
      removeValidation('networkCommunicationIpAddress');
      removeValidation('networkCommunicationPort');
      removeValidation('eptId');
      removeValidation('backOfficeRecordFormat');
    }
  }, [forecourtControllerValue?.type]);

  const onEditHandler = () => {
    setIsClickFromViewPage(true);
    navigate(`/organisations/${orgId}/sites/${siteId}/forecourt-controllers/${forecourtControllerId}/details/edit`);
  };

  const onSaveClickHandler = () => {
    setValidateCounterFlag((prev) => ++prev);
    if (!hasValidationError) {
      switch (action) {
        case FormActionType.EDIT:
          editForecourtController(forecourtControllerValue);
          break;
        case FormActionType.CREATE:
          createForecourtController(forecourtControllerValue);
          break;
      }
      setValidateCounterFlag(0);
    }
  };

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setIsPageDirty(isSaveButtonEnabled);
      setForecourtControllerValue((prevstate) => {
        return {
          ...prevstate,
          [newvalue.key]: newvalue.value,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const onCancel = () => {
    setValidateCounterFlag(0);
  };

  const parsEnums = (value: string | number) => {
    if (value !== undefined) {
      return value.toString();
    } else {
      return DEFAULT_DROP_DOWN_VALUE;
    }
  };

  return (
    <>
      <Form
        displayLoadingIndicator={(action === FormActionType.VIEW || action === FormActionType.EDIT) && loading}
        displayErrorDetails={error}
        displayNoAccessMessage={
          (action === FormActionType.VIEW && !hasReadAccess) ||
          (action === FormActionType.CREATE && !hasCreateAccess) ||
          (action === FormActionType.EDIT && !hasUpdateAccess)
        }
        displayForm={success}
        isSaveButtonEnabled={isSaveButtonEnabled}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        onEditClick={onEditHandler}
        formDataloading={loading}
        hasUpdateAccess={hasUpdateAccess}
        listURL={`/organisations/${orgId}/sites/${siteId}/forecourt-controllers`}
        isClickFromViewPage={isClickFromViewPage}
      >
        <CustomTextField
          validateCounter={validateCounterFlag}
          isMandatory={true}
          key='number'
          label='Number'
          placeholder='Enter Number'
          onBindingValue={onTextChangeHandler}
          name='number'
          value={forecourtControllerValue?.number}
          type={TextFieldType.INTEGER}
          minRange={1}
          maxRange={5}
          readOnly={!isUpdatingForm}
        ></CustomTextField>

        <DropDown
          key='type'
          name='type'
          value={parsEnums(forecourtControllerValue?.type)}
          onBindingValue={onTextChangeHandler}
          label='Type'
          keyValuePair={forecourtControllerType}
          readOnly={!isUpdatingForm}
        />

        <CustomTextField
          validateCounter={validateCounterFlag}
          isMandatory={forecourtControllerValue?.type === 'doms'}
          key='eptId'
          label='EPT ID'
          placeholder='Enter EPT ID'
          onBindingValue={onTextChangeHandler}
          name='eptId'
          value={forecourtControllerValue?.eptId}
          type='input'
          minCharLength={2}
          maxCharLength={2}
          readOnly={!isUpdatingForm || forecourtControllerValue?.type !== 'doms'}
          inputTooltip={
            forecourtControllerValue?.type === 'none' || forecourtControllerValue?.type === 'postec'
              ? `EPT ID cannot be configured for ${forecourtControllerValue?.type} type forecourt controller.`
              : ''
          }
        ></CustomTextField>

        <DropDown
          key='backOfficeRecordFormat'
          name='backOfficeRecordFormat'
          isMandatory={forecourtControllerValue?.type === 'doms'}
          value={parsEnums(forecourtControllerValue?.backOfficeRecordFormat)}
          onBindingValue={onTextChangeHandler}
          label='Back Office Record Format'
          keyValuePair={forecourtControllerBackOfficeRecordFormat}
          readOnly={!isUpdatingForm || forecourtControllerValue?.type !== 'doms'}
          inputTooltip={
            forecourtControllerValue?.type === 'none' || forecourtControllerValue?.type === 'postec'
              ? `Back Office Record Format cannot be configured for ${forecourtControllerValue?.type} type forecourt controller.`
              : ''
          }
        />

        <DropDown
          key='communicationType'
          name='communicationType'
          isMandatory={forecourtControllerValue?.type !== 'none'}
          value={parsEnums(forecourtControllerValue?.communicationType)}
          onBindingValue={onTextChangeHandler}
          label='Communication Type'
          keyValuePair={forecourtControllerCommunicationType}
          readOnly={!isUpdatingForm || forecourtControllerValue?.type === 'none'}
          inputTooltip={
            forecourtControllerValue?.type === 'none'
              ? `Communication Type cannot be configured for ${forecourtControllerValue?.type} type forecourt controller.`
              : ''
          }
        />

        {forecourtControllerValue?.communicationType === 'network' && (
          <>
            <CustomTextField
              validateCounter={validateCounterFlag}
              isMandatory={forecourtControllerValue?.type === 'postec' || forecourtControllerValue?.type === 'doms'}
              key='networkCommunicationIpAddress'
              label='IP Address'
              placeholder='Enter IP Address'
              onBindingValue={onTextChangeHandler}
              name='networkCommunicationIpAddress'
              value={forecourtControllerValue?.networkCommunicationIpAddress}
              type='input'
              minCharLength={7}
              maxCharLength={16}
              readOnly={!isUpdatingForm || forecourtControllerValue?.type === 'none'}
            ></CustomTextField>
            <CustomTextField
              validateCounter={validateCounterFlag}
              isMandatory={forecourtControllerValue?.type === 'postec' || forecourtControllerValue?.type === 'doms'}
              key='networkCommunicationPort'
              label='Network Port'
              placeholder='Enter Network Port'
              onBindingValue={onTextChangeHandler}
              name='networkCommunicationPort'
              value={forecourtControllerValue?.networkCommunicationPort}
              type={TextFieldType.INTEGER}
              minCharLength={1}
              maxCharLength={5}
              minRange={1}
              maxRange={65535}
              readOnly={!isUpdatingForm || forecourtControllerValue?.type === 'none'}
            ></CustomTextField>
          </>
        )}

        {forecourtControllerValue?.communicationType === 'serial' && (
          <CustomTextField
            validateCounter={validateCounterFlag}
            isMandatory={forecourtControllerValue?.type === 'postec' || forecourtControllerValue?.type === 'doms'}
            key='serialCommunicationPort'
            label='Serial Port'
            placeholder='Enter Serial Port'
            onBindingValue={onTextChangeHandler}
            name='serialCommunicationPort'
            value={forecourtControllerValue?.serialCommunicationPort}
            type='input'
            minCharLength={1}
            maxCharLength={5}
            readOnly={!isUpdatingForm || forecourtControllerValue?.type === 'none'}
          ></CustomTextField>
        )}
      </Form>
    </>
  );
};

export default ForecourtController;
