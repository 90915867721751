import api, { setAuthorize } from '../../utilities/api';
import { convertObjectToQueryString } from '../../utilities/querystring-helper';
import { CardEntity, CardRequest, PinEntity } from '../../entities/card';

const CARD_API_PREFIX = 'card-management';

export const getCardList = async (request: CardRequest, fuzzyRequest?: string) => {
  const qsRequest = convertObjectToQueryString(request);
  await setAuthorize();
  const result = await api.get(
    `/${CARD_API_PREFIX}/cards?${qsRequest}${fuzzyRequest ? `&pan:contains=${fuzzyRequest}` : ''}`
  );
  return result.data;
};

export const deleteCard = async (id: string, organisationId: string) => {
  await setAuthorize();
  await api.delete(`/${CARD_API_PREFIX}/cards/${id}?organisationId=${organisationId}`);
};

export const createCard = async (data: CardEntity) => {
  await setAuthorize();
  let result = await api.post(`/${CARD_API_PREFIX}/cards`, data);
  return result.data;
};

export const getCardById = async (id: string, organisationId: string) => {
  await setAuthorize();
  let result = await api.get(`/${CARD_API_PREFIX}/cards/${id}?organisationId=${organisationId}`);
  return result.data;
};

export const editCard = async (data: CardEntity) => {
  await setAuthorize();
  await api.put(`/${CARD_API_PREFIX}/cards/${data.id}`, data);
};

export const updatePin = async (id: string, data: PinEntity) => {
  await setAuthorize();
  await api.put(`/${CARD_API_PREFIX}/cards/${id}/pin`, data);
};

export const checkCardPin = async (id: string, organisationId: string) => {
  await setAuthorize();
  let result = await api.get(`/${CARD_API_PREFIX}/cards/${id}/pin?organisationId=${organisationId}`);
  return result.data;
};

export const removePin = async (id: string, organisationId: string) => {
  await setAuthorize();
  await api.delete(`/${CARD_API_PREFIX}/cards/${id}/pin?organisationId=${organisationId}`);
};
