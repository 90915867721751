import React, { useCallback, useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import classes from './dropdown.module.scss';
import classNames from 'classnames';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import CustomTextField from '../text-field/text-field.container';
import CustomTooltip from '../tooltip/custom-tooltip';
import LoadingSkeleton from '../loading-skeleton/loading-skeleton';
import { DEFAULT_DROP_DOWN_VALUE } from '../../constants/dropdown-constants';
import { ValidationError } from '../../models/baseModels/validationModel';
import { AUFlagIcon, NZFlagIcon } from '../icons';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import './dropdown.scss';

interface DropDownProps {
  validateCounter?: number;
  isMandatory?: boolean;
  className?: string;
  label?: string;
  keyValuePair?: KeyValuePair[];
  name: string;
  value?: string | number;
  dropdownRef?: React.Ref<null | HTMLSelectElement> | undefined;
  disabledKeys?: string[];
  autoFocus?: boolean;
  readOnly?: boolean;
  hideDefaultSelect?: boolean;
  hideAsteriskSymbol?: boolean;
  tooltipTitle?: string;
  inputTooltip?: string;
  displayPrefixAdditionalValue?: boolean;
  fieldValidationStatus: (name: string) => ValidationError | undefined;
  onBindingValue?: (newvalue: KeyValuePair) => void | undefined;
  setFieldValidation: (validation: ValidationError) => void;
  removeValidation: (name: string) => void;
  setShowValidationMessage: (value: KeyValuePair) => void;
  keyValuePairLoding?: () => void;
}

const DropDownControl = (props: DropDownProps) => {
  const {
    validateCounter,
    isMandatory,
    className,
    label,
    keyValuePair,
    name,
    value,
    autoFocus,
    dropdownRef,
    disabledKeys,
    readOnly,
    hideDefaultSelect,
    hideAsteriskSymbol,
    tooltipTitle,
    inputTooltip,
    displayPrefixAdditionalValue,
    onBindingValue,
    setFieldValidation,
    removeValidation,
    fieldValidationStatus,
    setShowValidationMessage,
    keyValuePairLoding,
  } = props;

  const [isShowMessage, setIsShowMessage] = useState(false);
  const [isKeyValuePairLoadingTriggered, setIsKeyValuePairLoadingTriggered] = useState(false);

  const VALIDATION_MESSAGE_MANDATORY_FIELD = 'Required field';

  const onChangeValue = (event: SelectChangeEvent<string | number>) => {
    if (onBindingValue) {
      onBindingValue({
        key: event.target.name,
        value: event.target.value,
      });
      validateData(event?.target?.value);
    }
  };

  /**FOR VALIDATION */
  const CheckMandatoryValue = useCallback(
    (value: string | number): boolean => {
      if (value === undefined || value.toString() === '' || value === DEFAULT_DROP_DOWN_VALUE) {
        setFieldValidation({
          name,
          hasError: true,
          showErrorMessage: false,
          validationMessage: VALIDATION_MESSAGE_MANDATORY_FIELD,
        });
        return true;
      } else {
        removeValidation(name);
      }
      return false;
    },
    [VALIDATION_MESSAGE_MANDATORY_FIELD, name, setFieldValidation, removeValidation]
  );

  const validateData = useCallback(
    (value: string | number) => {
      if (isMandatory) {
        CheckMandatoryValue(value);
      }
    },
    [CheckMandatoryValue, isMandatory]
  );

  useEffect(() => {
    validateData(value ? value : '');
  }, [value, validateData]);

  useEffect(() => {
    if (validateCounter && validateCounter > 0) {
      setIsShowMessage(true);
      validateData(value ? value : '');
      setShowValidationMessage({ key: name, value: true });
    }
  }, [validateCounter, value, name, validateData, setShowValidationMessage]);

  const selectFocusHandler = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!autoFocus) {
      setIsShowMessage(true);
      setShowValidationMessage({ key: name, value: true });
    }
  };

  const selectBlurHandler = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    validateData(event.target.value);
    setIsShowMessage(true);
    setShowValidationMessage({ key: name, value: true });
  };

  const selectOpenHandler = () => {
    if ((!keyValuePair || keyValuePair?.length <= 0) && keyValuePairLoding && !isKeyValuePairLoadingTriggered) {
      setIsKeyValuePairLoadingTriggered(true);
      keyValuePairLoding();
    }
  };

  const getReadonlyValue = () => {
    return fieldMappingHelper.getDisplayValue(value, keyValuePair);
  };

  return (
    <div className={classNames('select-field-container', className)}>
      {!readOnly && (
        <div className={classNames('select-field-label', className)}>
          {label} {tooltipTitle && <CustomTooltip title={tooltipTitle} />}
          {label && isMandatory && !hideAsteriskSymbol && !readOnly && <strong className='asterisk'> *</strong>}
        </div>
      )}
      {readOnly ? (
        <CustomTextField
          name={name}
          value={getReadonlyValue()}
          type='string'
          readOnly
          label={label}
          inputTooltip={inputTooltip}
        />
      ) : (
        <Select
          autoFocus={autoFocus || false}
          onBlur={selectBlurHandler}
          onFocus={selectFocusHandler}
          onOpen={selectOpenHandler}
          error={isShowMessage && fieldValidationStatus(name)?.hasError}
          className={classNames('select', className)}
          name={name}
          value={value || DEFAULT_DROP_DOWN_VALUE}
          onChange={onChangeValue}
          ref={dropdownRef}
          inputProps={{
            classes: {
              icon: classes.icon,
              name: name,
            },
          }}
        >
          {!hideDefaultSelect && (
            <MenuItem className={classNames('list-menu-test')} value={DEFAULT_DROP_DOWN_VALUE}>
              <em className={classNames('default-select')}>{DEFAULT_DROP_DOWN_VALUE}</em>
            </MenuItem>
          )}
          {keyValuePair?.map((item, i) => (
            <MenuItem
              key={i}
              value={item.key}
              disabled={disabledKeys?.includes(item.key.toString())}
              className={classNames('dropdownlist-item', {
                'dropdownlist-item-with-prefix-value': displayPrefixAdditionalValue,
              })}
            >
              {displayPrefixAdditionalValue && (
                <span className='dropdownlist-additional-item-prefix'>
                  {item?.additionalValue === 'au-flag' ? (
                    <AUFlagIcon />
                  ) : item?.additionalValue === 'nz-flag' ? (
                    <NZFlagIcon />
                  ) : (
                    item.additionalValue
                  )}
                </span>
              )}
              <span>{item.value}</span>
              {!displayPrefixAdditionalValue && (
                <span className='dropdownlist-additional-item'>{item.additionalValue}</span>
              )}
            </MenuItem>
          ))}

          {
            (!keyValuePair || keyValuePair?.length <= 0) &&
            Array.from({ length: 5 }).map((_, i) => (
              <MenuItem key={i} className='dropdownlist-item-loading'>
                <LoadingSkeleton customHeight={30} />
              </MenuItem>
            ))
          }
        </Select>
      )}
      {isShowMessage && fieldValidationStatus(name)?.hasError && (
        <div className='validation-text-message-error'>{fieldValidationStatus(name)?.validationMessage}</div>
      )}
    </div>
  );
};

export default DropDownControl;
