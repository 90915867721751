import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PeopleIcon from '@mui/icons-material/People';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { LoadingStatus } from '../../constants/loading-constants';
import { ModuleName } from '../../constants/module-constants';
import { Messages } from '../../constants/messages';
import FooterBar from '../../components/footer/footer';
import MessageDisplay from '../../components/message-display/message-display';
import SearchCreate from '../../components/search-create/search-create';
import EmptyList from '../../components/empty-list/empty-list';
import './styles/alerts.scss';

interface alertsProps {
  authStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
}

const Alerts: React.FC<alertsProps> = (props: alertsProps) => {
  const { authStatus, userAccess, setHeaderConfiguration, setPageConfiguration, setIsPageDirty } = props;

  const navigate = useNavigate();
  const { orgId } = useParams();

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
  }, [authStatus]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.ALERT).hasReadAccess);
  }, [userAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      title: 'Alert History',
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      error: false,
      pageURL: 'alerts',
    } as HeaderStateModel);
  }, [setHeaderConfiguration, authSuccess]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  const searchInputRef = useRef<HTMLInputElement>(null!);

  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    window.scrollTo(0, 0);
  };

  const onAlertRuleButtonHandler = () => {
    navigate(`/organisations/${orgId}/alerts/alert-rules`);
  };

  const onUserGroupButtonHandler = () => {
    navigate(`/organisations/${orgId}/alerts/user-groups`);
  };

  return (
    <>
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}

      {hasReadAccess && (
        <div className={classNames('alert-list')}>
          {
            <div className='filter-list'>
              <SearchCreate
                placeholder={'Search Alert History'}
                onKeyUpHandler={onKeyUpHandler}
                searchRef={searchInputRef}
                firstActionButton='Alert Rules'
                firstActionButtonIcon={<NotificationsNoneIcon />}
                onFirstActionButtonHandler={onAlertRuleButtonHandler}
                secondActionButton='User Groups'
                secondActionButtonIcon={<PeopleIcon />}
                onSecondActionButtonHandler={onUserGroupButtonHandler}
                showCreateButton={false}
              />
            </div>
          }

          <div className='empty-div-container'>
            <EmptyList message={'No alert history found'}></EmptyList>
          </div>
        </div>
      )}

      {
        <div className='for-mobile'>
          <FooterBar className='footer-no-content' />
        </div>
      }
    </>
  );
};

export default Alerts;
