import { useEffect, useMemo, useState } from 'react';
import { Box, Collapse, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { BulkOperationDetailsModel, BulkOperationsItemModel } from '../../models/cardOperationModel';
import { bulkOperationType } from '../../constants/dropdown-constants';
import { BulkOperationDisplayType } from '../../constants/status';
import { ButtonStyle } from '../../constants/button-constants';
import { LoadingStatus } from '../../constants/loading-constants';
import { bulkActionType } from '../../constants/dropdown-constants';
import { DoubleArrowUpIcon, DoubleArrowDownIcon } from '../../components/icons';
import CustomButton from '../../components/button/custom-button';
import Item from '../../components/box-items/box-item';
import StackedBarChart from '../../components/stacked-bar-chart/stacked-bar-chart';
import DoughnutChart from '../../components/doughnut-chart/doughnut-chart';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import './styles/operationItem.scss';

interface OperationItemProps {
  item: BulkOperationsItemModel;
  details?: BulkOperationDetailsModel;
  opeationDetailsList?: BulkOperationDetailsModel[];
  operationsDetailsListState?: string;
  loadOperationById: (data: string) => void;
}

const OperationItem: React.FC<OperationItemProps> = (props: OperationItemProps) => {
  const { item, details, opeationDetailsList, operationsDetailsListState, loadOperationById } = props;

  const navigate = useNavigate();
  const { orgId } = useParams();

  const [displayDetails, setDisplayDetails] = useState(details);
  const [showDetails, setShowDetails] = useState(false);
  const [stackedBarChartData, setStackedBarChartData] = useState([] as KeyValuePair[]);
  const [doughnutChartData, setDoughnutChartData] = useState([] as KeyValuePair[]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);

  useMemo(() => {
    setLoading(operationsDetailsListState === LoadingStatus.LOADING);
  }, [operationsDetailsListState]);

  const onOperationClickHandler = (id: string) => {
    navigate(`/organisations/${orgId}/cards/card-operations/${id}/details`);
  };

  const showDetailHandler = () => {
    if (item.state !== 'running' && !opeationDetailsList?.find((it) => it.id === item.id) && !showDetails) {
      loadOperationById(item.id);
    }
    setShowDetails((prev) => !prev);
  };

  const onCollapseSectionClick = (e: any) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (item.state === 'running') setShowDetails(true);
  }, [item]);

  useEffect(() => {
    setDisplayDetails(opeationDetailsList?.filter((it) => it.id === item.id)[0]);
  }, [opeationDetailsList]);

  useEffect(() => {
    if (displayDetails?.result?.summary) {
      setDoughnutChartData([
        {
          key: 'Created Items',
          value: displayDetails?.result?.summary.createdItems,
          additionalValue: '#03953D',
        },
        {
          key: 'Updated Items',
          value: displayDetails?.result?.summary.updatedItems,
          additionalValue: '#007AFB',
        },
        {
          key: 'Skipped Items',
          value: displayDetails?.result?.summary.skippedItems,
          additionalValue: '#667c94',
        },
        {
          key: 'Deleted Items',
          value: displayDetails?.result?.summary.deletedItems,
          additionalValue: '#ff001b',
        },
        {
          key: 'Failed Items',
          value: displayDetails?.result?.summary.failedItems,
          additionalValue: '#FF7A00',
        },
      ]);
    }
  }, [displayDetails]);

  useEffect(() => {
    if (details?.result?.summary) {
      setStackedBarChartData([
        {
          key: 'Succeeded Items',
          value: details?.result?.summary.succeededItems,
          additionalValue: '#03953D',
        },
        {
          key: 'Failed Items',
          value: details?.result?.summary.failedItems,
          additionalValue: '#FF7A00',
        },
        {
          key: 'Unprocessed Items',
          value: details?.result?.summary.totalItems - details?.result?.summary.processedItems,
          additionalValue: '#ffffff',
        },
      ]);
    }
  }, [details]);

  const CardDetailsTable = () => {
    if (details?.result) {
      const displayDetails = details?.result.items.slice(0, 3);
      return (
        <Table className='card-details-table'>
          <TableHead className='table-header'>
            <TableRow>
              <TableCell>PAN</TableCell>
              <TableCell>BIN Range</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className='hidden-mobile'>Action Type</TableCell>
              <TableCell className='hidden-small-desktop hidden-mobile'>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayDetails.map((detail, i) => {
              return (
                <TableRow key={detail.pan} className={i % 2 === 0 ? 'even-row' : 'odd-row'}>
                  <TableCell component='th' scope='row'>
                    {detail.pan}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {detail.binRangeName}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {detail.success ? 'Success' : 'Failure'}
                  </TableCell>
                  <TableCell component='th' scope='row' className='hidden-mobile'>
                    {fieldMappingHelper.getDisplayValue(detail.actionType, bulkActionType)}
                  </TableCell>
                  <TableCell component='th' scope='row' className='hidden-small-desktop hidden-mobile'>
                    {detail.errorMessage}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    } else return <></>;
  };

  const CardDetailsSectionTable = () => {
    if (displayDetails?.result) {
      const displayDetail = displayDetails?.result.items.slice(0, 3);
      return (
        <Table className='card-details-table'>
          <TableHead className='table-header'>
            <TableRow>
              <TableCell>PAN</TableCell>
              <TableCell>BIN Range</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className='hidden-mobile'>Action Type</TableCell>
              <TableCell className='hidden-small-desktop hidden-mobile'>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayDetail.map((detail, i) => {
              return (
                <TableRow key={detail.pan} className={i % 2 === 0 ? 'even-row' : 'odd-row'}>
                  <TableCell component='th' scope='row'>
                    {detail.pan}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {detail.binRangeName}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {detail.success ? 'Success' : 'Failure'}
                  </TableCell>
                  <TableCell component='th' scope='row' className='hidden-mobile'>
                    {fieldMappingHelper.getDisplayValue(detail.actionType, bulkActionType)}
                  </TableCell>
                  <TableCell component='th' scope='row' className='hidden-small-desktop hidden-mobile'></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    } else return <></>;
  };

  const CardFailureDetailsTable = () => {
    if (displayDetails?.result && displayDetails?.result.items && displayDetails?.result.items.length > 0) {
      const displayDetail = displayDetails?.result.items.slice(0, 3);
      return (
        <Table className='card-details-table'>
          <TableHead className='table-header'>
            <TableRow>
              <TableCell>PAN</TableCell>
              <TableCell>BIN Range</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className='hidden-small-desktop hidden-mobile'>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayDetail.map((detail, i) => {
              return (
                <TableRow key={detail.pan} className={i % 2 === 0 ? 'even-row' : 'odd-row'}>
                  <TableCell component='th' scope='row'>
                    {detail.pan}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {detail.binRangeName}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {detail.success ? 'Success' : 'Failure'}
                  </TableCell>
                  <TableCell component='th' scope='row' className='hidden-small-desktop hidden-mobile'>
                    {detail.errorMessage}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    } else return <></>;
  };

  return (
    <>
      <Grid
        container
        className='operation-grid-container'
        alignItems='center'
        key={item.id}
        onClick={showDetailHandler}
      >
        <Grid xs={10} sm={11}>
          <Grid container alignItems='flex-start'>
            <Grid item xs={12} sm={4}>
              <Item className='operation-grid-item-container'>
                <Box>
                  <Item className='operation-card-field-header'>Type</Item>
                </Box>
                <Box
                  sx={{
                    display: { xs: 'flex', sm: 'none' },
                  }}
                >
                  <Item className='operation-card-field-value'>
                    <div>{fieldMappingHelper.getDisplayValue(item?.type, bulkOperationType)}</div>
                  </Item>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Item className='operation-grid-item-container'>
                <div>
                  <Box>
                    <Item className='operation-card-field-header'>State</Item>
                  </Box>
                  <Box
                    sx={{
                      display: { xs: 'flex', sm: 'none' },
                    }}
                  >
                    <Item className='operation-card-field-value'>
                      <div>{fieldMappingHelper.getDisplayValue(item?.state, BulkOperationDisplayType)}</div>
                    </Item>
                  </Box>
                </div>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Item className='operation-grid-item-container'>
                <div>
                  <Box>
                    <Item className='operation-card-field-header'>Last Status Update</Item>
                  </Box>
                  <Box
                    sx={{
                      display: { xs: 'flex', sm: 'none' },
                    }}
                  >
                    <Item className='operation-card-field-value'>
                      <div>{item?.state}</div>
                    </Item>
                  </Box>
                </div>
              </Item>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems='flex-start'
            sx={{
              display: { xs: 'none', sm: 'flex' },
            }}
          >
            <Grid item xs={12} sm={4}>
              <Item className='operation-card-field-value'>
                {fieldMappingHelper.getDisplayValue(item?.type, bulkOperationType)}
              </Item>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Item className='operation-card-field-value'>
                <Item className={classNames('status-icon', item?.healthIndicator)} />
                {fieldMappingHelper.getDisplayValue(item?.state, BulkOperationDisplayType)}
              </Item>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Item className='operation-card-field-value'>{item?.lastUpdatedDateTimeUtc}</Item>
            </Grid>
          </Grid>

          {/* Collapse Section*/}
          <Collapse in={showDetails} className='collapse-container' onClick={onCollapseSectionClick}>
            {loading && !opeationDetailsList?.find((it) => it.id === item.id) && (
              <div className='loading-error-handler-container'>
                <LoadingDisplay />
              </div>
            )}

            {/* on-going operations, display stacked bar */}
            {item?.state === 'running' && details?.result?.summary.totalItems && (
              <Grid container alignItems='flex-start' className='stacked-bar-frid-container'>
                <StackedBarChart chartData={stackedBarChartData} totalItemCount={details?.result?.summary.totalItems} />
              </Grid>
            )}
            {/* on-going operations, display recent card details */}
            {item?.state === 'running' &&
              details?.result &&
              details?.result?.items?.length > 0 &&
              details?.result?.summary?.processedItems > 0 && (
                <Grid container alignItems='flex-start'>
                  <CardDetailsTable />
                  <Grid item xs={12} sm={12}>
                    <Item className='operation-card-more-details-link' onClick={() => onOperationClickHandler(item.id)}>
                      More
                    </Item>
                  </Grid>
                </Grid>
              )}

            {/* completed operation, display doughnut chart and recent card details */}
            {item?.state === 'completed' &&
              doughnutChartData.length > 0 &&
              displayDetails?.result?.summary.totalItems && (
                <Grid container alignItems='flex-start'>
                  <Grid item xs={12} md={6}>
                    <DoughnutChart
                      chartData={doughnutChartData}
                      totalItemCount={displayDetails?.result?.summary.totalItems}
                      width={550}
                      height={300}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CardDetailsSectionTable />
                    <Item className='operation-card-more-details-link' onClick={() => onOperationClickHandler(item.id)}>
                      More
                    </Item>
                  </Grid>
                </Grid>
              )}

            {/* failed operation, display failure message or display doughnut chart and recent card details*/}
            {item?.state === 'failed' && (
              <Grid item xs={12} sm={12}>
                {item?.errorMessage && (
                  <Item className='operation-grid-item-container'>
                    <div>
                      <Box>
                        <Item className='operation-card-field-header'>Failed Details</Item>
                      </Box>
                      <Item className='operation-card-field-value'>{item?.errorMessage}</Item>
                    </div>
                  </Item>
                )}
                {!item?.errorMessage && doughnutChartData.length > 0 && displayDetails?.result?.summary.totalItems && (
                  <Grid container alignItems='flex-start'>
                    <Grid item xs={12} md={6}>
                      <DoughnutChart
                        chartData={doughnutChartData}
                        totalItemCount={displayDetails?.result?.summary.totalItems}
                        width={550}
                        height={300}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CardFailureDetailsTable />
                      <Item
                        className='operation-card-more-details-link'
                        onClick={() => onOperationClickHandler(item.id)}
                      >
                        More
                      </Item>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Collapse>
        </Grid>

        {item?.state !== 'pending' && (
          <Grid item xs={2} sm={1} alignItems='flex-start'>
            {
              <Item className='operation-grid-item-container'>
                <div>
                  <Item className='operation-grid-button-container'>
                    {
                      <CustomButton
                        buttonStyle={ButtonStyle.ICON}
                        icon={
                          showDetails ? (
                            <DoubleArrowUpIcon className='button-icon-color' />
                          ) : (
                            <DoubleArrowDownIcon className='button-icon-color' />
                          )
                        }
                        iconTooltip={showDetails ? 'Show less' : 'Show more'}
                      ></CustomButton>
                    }
                  </Item>
                </div>
              </Item>
            }
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default OperationItem;
