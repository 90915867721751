import KeyValuePair from '../models/baseModels/keyValuePairModel';

export const HealthStatus = Object.freeze({
  UNAVAILABLE: 'unavailable',
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  ONLINE: 'online',
  OFFLINE: 'offline',
  ERROR: 'error',
  HEALTHY: 'healthy',
  UNHEALTHY: 'unhealthy',
  DEGRADED: 'degraded',
  TERMINAL_DISABLED: 'terminaldisabled',
  DISABLED: 'disabled',
});

export const HealthStatusDisplayType: KeyValuePair[] = [
  { key: 'ok', value: 'Ok' },
  { key: 'warning', value: 'Warning' },
  { key: 'error', value: 'Error' },
  { key: 'unavailable', value: 'Unavailable' },
  { key: 'online', value: 'Online' },
  { key: 'offline', value: 'Offline' },
  { key: 'failed', value: 'Failed' },
  { key: 'running', value: 'Running' },
  { key: 'completed', value: 'Completed' },
  { key: 'queued', value: 'Queued' },
  { key: 'healthy', value: 'Healthy' },
  { key: 'unhealthy', value: 'Unhealthy' },
  { key: 'degraded', value: 'Degraded' },
  { key: 'terminalDisabled', value: 'Terminal Disabled' },
  { key: 'disabled', value: 'Disabled' },
];

export const PumpStatus = Object.freeze({
  UNAVAILABLE: 'unavailable',
  INOPERATIVE: 'inoperative',
  CLOSED: 'closed',
  IDLE: 'idle',
  CALLING: 'calling',
  AUTHORISED: 'authorised',
  STARTED: 'started',
  SUSPENDEDSTARTED: 'suspendedstarted',
  FUELLING: 'fuelling',
  SUSPENDEDFUELLING: 'suspendedfuelling',
  INVALID: 'invalid',
  OFFLINE: 'offline',
  DISABLED: 'disabled',
});

export const PumpStatusType: KeyValuePair[] = [
  { key: 'unavailable', value: 'Unavailable' },
  { key: 'inoperative', value: 'Inoperative' },
  { key: 'closed', value: 'Closed' },
  { key: 'idle', value: 'Idle' },
  { key: 'calling', value: 'Calling' },
  { key: 'authorised', value: 'Authorised' },
  { key: 'started', value: 'Started' },
  { key: 'suspendedStarted', value: 'Suspended Started' },
  { key: 'fuelling', value: 'Fuelling' },
  { key: 'suspendedFuelling', value: 'Suspended Fuelling' },
  { key: 'invalid', value: 'Invalid' },
  { key: 'offline', value: 'Offline' },
  { key: 'disabled', value: 'Disabled' },
];

export const TankStatus = Object.freeze({
  UNAVAILABLE: 'unavailable',
  ONLINE: 'online',
  OFFLINE: 'offline',
  DELIVERYINPROGRESS: 'deliveryinprogress',
  ERROR: 'error',
});

export const TankStatusType: KeyValuePair[] = [
  { key: 'unavailable', value: 'Unavailable' },
  { key: 'online', value: 'Online' },
  { key: 'offline', value: 'Offline' },
  { key: 'deliveryInProgress', value: 'Delivery In Progress' },
  { key: 'error', value: 'Error' },
];

export const PriceSignStatus = Object.freeze({
  DEGRADEDCOMMUNICATION: 'degradedcommunication',
  UNAVAILABLE: 'unavailable',
  ONLINE: 'online',
  OFFLINE: 'offline',
});

export const PriceSignStatusType: KeyValuePair[] = [
  { key: 'unavailable', value: 'Unavailable' },
  { key: 'online', value: 'Online' },
  { key: 'offline', value: 'Offline' },
  { key: 'degradedCommunication', value: 'Degraded Communication' },
];

export const BulkOperationState = Object.freeze({
  PENDING: 'pending',
  RUNNING: 'running',
  FAILED: 'failed',
  COMPLETED: 'completed',
});

export const BulkOperationDisplayType: KeyValuePair[] = [
  { key: 'pending', value: 'Pending' },
  { key: 'running', value: 'Running' },
  { key: 'failed', value: 'Failed' },
  { key: 'completed', value: 'Completed' },
];

export const AlertRuleOperatorType: KeyValuePair[] = [{ key: 'isEqualTo', value: 'Is Equal To' }];

export const HealthStatusIndicatorDisplayType: KeyValuePair[] = [
  { key: '', value: '' },
  { key: 'status-healthy-icon', value: 'Healthy' },
  { key: 'status-unhealthy-icon', value: 'Unhealthy' },
  { key: 'status-warning-icon', value: 'Degraded' },
];
