import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../constants/loading-constants";
import { PermissionGroupItemsModel } from "../../models/permissionGroupModel";
import { UserPermissionAssignmentDetailModal, UserPermissionModel } from "../../models/userPermissionModel";
import { UserModel } from "../../models/usersModel";
import { initialState } from "./usersInitState";

const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setUserStatus: (state, action: PayloadAction<string>) => {
            state.hasError = false;
            state.userStatus = action.payload;
        },
        setUsersContent: (state, action: PayloadAction<UserModel[]>) => {
            state.usersContent = action.payload;
        },
        setUserPermissions: (state, action: PayloadAction<UserPermissionModel[]>) => {
            state.userPermission = action.payload;
        },
        setUsersError: (state) => {
            state.hasError = true;
            state.usersContent = [];
            state.userStatus = LoadingStatus.ERROR;
        }, 
        setUserInfo: (state, action: PayloadAction<UserModel>) => {
            state.hasError = false;
            state.userInfo = action.payload;
        },
        setUserListPermissionGroups: (state, action: PayloadAction<PermissionGroupItemsModel[]>) => {
            state.userListPermissionGroups = action.payload;
        },
        setUserListPermissions: (state, action: PayloadAction<UserPermissionAssignmentDetailModal[]>) => {
            state.userListPermission = action.payload;
        },
        setUserContent: (state, action: PayloadAction<UserModel>) => {
            const i = state.usersContent.findIndex(u => u.id === action.payload.id);
            state.usersContent[i] = action.payload;
        },
        setUserPermissionsError: (state, action: PayloadAction<string>) => {
            const i = state.usersContent.findIndex(u => u.id === action.payload);
            state.usersContent[i].permissionsHasError = true;
        },
    }
});

export const {
    setUserStatus,
    setUsersContent, 
    setUserInfo,
    setUserPermissions,
    setUsersError,
    setUserListPermissionGroups,
    setUserListPermissions,
    setUserContent,
    setUserPermissionsError
} = userSlice.actions;

export default userSlice.reducer; 