import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import { ReceiptTemplateItemModel } from '../../models/receiptTemplateModel';
import { initialState } from './receiptTemplateInitState';

const ReceiptTemplateSlice = createSlice({
  name: 'permmissionGroupState',
  initialState,
  reducers: {
    setReceiptTemplateStatus: (state, action: PayloadAction<string>) => {
      state.receiptTemplateStatus = action.payload;
      state.hasError = false;
    },
    setReceiptTemplateItem: (state, action: PayloadAction<ReceiptTemplateItemModel>) => {
      state.receiptTemplateItem = action.payload;
      state.hasError = false;
    },
    setReceiptTemplateContent: (state, action: PayloadAction<ReceiptTemplateItemModel[]>) => {
      state.receiptTemplateContent = action.payload;
      state.hasError = false;
    },
    setReceiptTemplateError: (state) => {
      state.hasError = true;
      state.receiptTemplateContent = [];
      state.receiptTemplateStatus = LoadingStatus.ERROR;
    },
  },
});

export const { setReceiptTemplateStatus, setReceiptTemplateItem, setReceiptTemplateContent, setReceiptTemplateError } =
  ReceiptTemplateSlice.actions;

export default ReceiptTemplateSlice.reducer;
