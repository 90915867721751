import { LoadingStatus } from '../../constants/loading-constants';
import { AlertRuleModel, AlertRuleTriggerOptionModel } from '../../models/alertRuleModel';

interface AlertRuleState {
  alertRuleStatus: string;
  alertRuleList: AlertRuleModel[];
  alertRuleInfo: AlertRuleModel;
  alertRuleTriggerList: AlertRuleTriggerOptionModel[];
  hasError: boolean;
}

export const initialState: AlertRuleState = {
  alertRuleStatus: LoadingStatus.LOADING,
} as AlertRuleState;
