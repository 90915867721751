import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import * as services from './services';
import * as userGroupServices from '../alert-usergroups/services';
import * as actions from './actions';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { AlertRuleModel, AlertRuleTriggerOptionModel } from '../../models/alertRuleModel';
import { getApiErrorMessage, getGenericErrorMessage } from '../../utilities/errorhandler';
import { selectOrganisationId } from '../auth/selectors';
import { setGenericErrorData } from '../generic-error/reducers';
import {
  setAlertRuleStatus,
  setAlertRuleListData,
  setAlertRuleInfoData,
  setAlertRuleError,
  setAlertRuleTriggerListData,
} from './reducers';
import { hideBackdrop, setBackDropActionStatus, setBackDropError, showBackdrop } from '../backdrop/reducers';
import { setIsPageDirty } from '../page-configuration/reducers';
import { setSnackBarError, setSnackBarSuccess } from '../snackbar/reducers';
import { setDialogBoxActionStatus, closeDialogBox } from '../dialog-box/reducers';
import { clearAllFieldValidation } from '../fieldValidation/reducers';
import { AlertRuleEntity, AlertRuleListResponse, AlertRuleTriggerListResponse } from '../../entities/alert-rule';
import { AlertUserGroupListResponse } from '../../entities/alert-usergroup';
import { Messages } from '../../constants/messages';
import { LoadingStatus } from '../../constants/loading-constants';
import { notificationMethod } from '../../constants/dropdown-constants';
import { loadSiteNameList } from '../sites/sagas';
import { selectSiteList } from '../sites/selectors';
import { selectAlertRuleTriggerListData } from './selectors';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_ALERT_RULES, loadAlertRules);
  yield takeLatest(actions.LOAD_ALERT_RULE_TRIGGERS, loadAlertRuleTriggers);
  yield takeLatest(actions.CREATE_ALERT_RULE, createAlertRule);
  yield takeLatest(actions.EDIT_ALERT_RULE, editAlertRule);
  yield takeLatest(actions.DELETE_ALERT_RULE, deleteAlertRule);
  yield takeLatest(actions.LOAD_ALERT_RULE_INFO, loadAlertRuleInfo);
}

export function* loadAlertRules() {
  try {
    yield put(setAlertRuleStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);
    let response: AlertRuleListResponse = yield call(services.getAlertRuleList, organisationId);

    let siteNameList: KeyValuePair[] = [];
    let triggerList: AlertRuleTriggerOptionModel[] = [];
    let userGroupList: KeyValuePair[] = [];

    let alertRulesData: AlertRuleModel[] = mapAlertRuleEntityToModelList(
      response,
      triggerList,
      userGroupList,
      siteNameList
    );

    yield put(setAlertRuleListData(alertRulesData));
    yield put(setAlertRuleStatus(LoadingStatus.SUCCESS));

    if (!siteNameList || siteNameList?.length <= 0) {
      yield call(loadSiteNameList);
      siteNameList = yield select(selectSiteList);

      alertRulesData = mapAlertRuleEntityToModelList(response, triggerList, userGroupList, siteNameList);
      yield put(setAlertRuleListData(alertRulesData));
    }

    if (!triggerList || triggerList?.length <= 0) {
      triggerList = yield select(selectAlertRuleTriggerListData);
      if (!triggerList || triggerList?.length <= 0) {
        let trigger_response: AlertRuleTriggerListResponse = yield call(
          services.getAlertRuleTriggerList,
          organisationId
        );
        triggerList = mapAlertRuleTriggerEntityToModelList(trigger_response);
        yield put(setAlertRuleTriggerListData(triggerList));
      }

      alertRulesData = mapAlertRuleEntityToModelList(response, triggerList, userGroupList, siteNameList);
      yield put(setAlertRuleListData(alertRulesData));
    }

    if (!userGroupList || userGroupList?.length <= 0) {
      let user_group_response: AlertUserGroupListResponse = yield call(
        userGroupServices.getAlertUserGroupList,
        organisationId
      );
      userGroupList = mapAlertUserGroupEntityToKeyValuePair(user_group_response);

      alertRulesData = mapAlertRuleEntityToModelList(response, triggerList, userGroupList, siteNameList);
      yield put(setAlertRuleListData(alertRulesData));
    }
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setAlertRuleError());
  }
}

export function* loadAlertRuleTriggers() {
  try {
    const organisationId: string = yield select(selectOrganisationId);
    let response: AlertRuleTriggerListResponse = yield call(services.getAlertRuleTriggerList, organisationId);
    let alertRulesData: AlertRuleTriggerOptionModel[] = mapAlertRuleTriggerEntityToModelList(response);
    yield put(setAlertRuleTriggerListData(alertRulesData));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setAlertRuleError());
  }
}

export function* loadAlertRuleInfo(action: PayloadAction<string>) {
  try {
    if (!!action.payload) {
      yield put(setAlertRuleStatus(LoadingStatus.LOADING));
      const organisationId: string = yield select(selectOrganisationId);
      let response: AlertRuleEntity = yield call(services.getAlertRuleInfo, action.payload, organisationId);
      let alertRuleInfo: AlertRuleModel = yield call(mapAlertRuleEntityToModel, response);
      yield put(setAlertRuleInfoData(alertRuleInfo));
      yield put(setAlertRuleStatus(LoadingStatus.SUCCESS));
    }
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setAlertRuleError());
    yield put(clearAllFieldValidation());
  }
}

export function* createAlertRule(action: PayloadAction<AlertRuleModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    let alertRuleEntity: AlertRuleEntity = MapAlertRuleModelToEntity(action.payload, organisationId);
    yield call(services.createAlertRule, alertRuleEntity);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.ALERT_RULE_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
  }
}

export function* editAlertRule(action: PayloadAction<AlertRuleModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    let alertRuleEntity: AlertRuleEntity = MapAlertRuleModelToEntity(action.payload, organisationId);
    yield call(services.editAlertRule, alertRuleEntity, action.payload.id);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.ALERT_RULE_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
  }
}

export function* deleteAlertRule(action: PayloadAction<string>) {
  try {
    yield put(setDialogBoxActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    yield call(services.deleteAlertRule, action.payload, organisationId);
    yield put(closeDialogBox());
    yield put(setSnackBarSuccess(Messages.ALERT_RULE_DELETE_SUCCESS));
    yield call(loadAlertRules);
  } catch (error) {
    yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

const mapAlertRuleEntityToModelList = (
  response: AlertRuleListResponse,
  triggerList?: AlertRuleTriggerOptionModel[],
  userGroupList?: KeyValuePair[],
  siteNameList?: KeyValuePair[]
) => {
  if (response && response.items.length > 0) {
    const result: AlertRuleModel[] = response.items.map((item, i) => {
      let displayTriggerList = [] as string[];
      if (item?.triggers) {
        item?.triggers.forEach((trigger) => {
          const foundOption = triggerList?.find((option) => option.property === trigger.property);
          if (foundOption && foundOption.name) {
            displayTriggerList.push(foundOption.name);
          }
        });
      }
      displayTriggerList = displayTriggerList.filter((item, index) => displayTriggerList.indexOf(item) === index);

      let displayUserGroupList = [] as any[];
      if (item?.actions) {
        item?.actions.forEach((action) => {
          const foundOption = userGroupList?.find((option) => option.key === action.userGroupId);
          if (foundOption && foundOption.value) {
            displayUserGroupList.push(foundOption.value);
          }
        });
      }

      let displayNotificationList = [] as string[];
      if (item?.actions) {
        item?.actions?.forEach((it) => {
          const foundUserGroup = userGroupList?.find((option) => option.key === it.userGroupId)?.value;
          if (!foundUserGroup) return;

          displayNotificationList.push(
            foundUserGroup + ' / ' + fieldMappingHelper.getDisplayValue(it.notificationMethod, notificationMethod)
          );
        });
      }

      let displaySiteList = [] as any[];
      if (item?.sites && siteNameList) {
        item?.sites?.forEach((site) => {
          const foundOption = siteNameList?.find((option) => option.key === site.id);
          if (foundOption && foundOption?.value) {
            displaySiteList.push(foundOption.value);
          }
        });
      }

      return {
        id: item?.id,
        name: item?.name,
        organisationId: item?.organisationId,
        enabled: item?.enabled,
        sites: item?.sites?.map((it) => {
          return it?.id;
        }),
        applyToAllSites: item?.applyToAllSites,
        triggers: item?.triggers,
        actions: item?.actions,
        triggerList: displayTriggerList,
        siteList: item?.applyToAllSites ? ['All'] : displaySiteList?.length > 0 ? displaySiteList : [],
        notificationList: Array.from(new Set(displayNotificationList)),
      };
    });
    return result;
  }
  return [] as AlertRuleModel[];
};

const mapAlertRuleTriggerEntityToModelList = (response: AlertRuleTriggerListResponse) => {
  if (response && response.items.length > 0) {
    const result: AlertRuleTriggerOptionModel[] = response.items.map((item, i) => {
      return {
        availableOperators: item?.availableOperators,
        availableValues: item?.availableValues,
        dataType: item?.dataType,
        name: item?.name,
        property: item?.property,
        valueUnitOfMeasure: item?.valueUnitOfMeasure,
      };
    });
    return result;
  }
  return [] as AlertRuleTriggerOptionModel[];
};

const mapAlertUserGroupEntityToKeyValuePair = (response: AlertUserGroupListResponse) => {
  if (response && response.items.length > 0) {
    const result: KeyValuePair[] = response.items.map((item, i) => {
      return {
        key: item.id,
        value: item.name,
      };
    });

    return result;
  }
  return [] as KeyValuePair[];
};

const mapAlertRuleEntityToModel = (response: AlertRuleEntity) => {
  if (response) {
    let alertRuleModel = {
      organisationId: response?.organisationId,
      id: response?.id,
      name: response?.name,
      enabled: response?.enabled,
      sites: response?.sites?.map((it) => {
        return it?.id;
      }),
      applyToAllSites: response?.applyToAllSites,
      triggers: response?.triggers,
      actions: response?.actions,
    } as AlertRuleModel;
    return alertRuleModel;
  }
  return {} as AlertRuleModel;
};

const MapAlertRuleModelToEntity = (model: AlertRuleModel, organisationId: string): AlertRuleEntity => {
  if (model) {
    let siteListEntity = model?.applyToAllSites
      ? []
      : model?.sites?.map((it) => {
          return { id: it };
        });
    let alertRuleEntity = {
      organisationId: organisationId,
      id: model?.id,
      name: model?.name,
      enabled: model?.enabled ? true : false,
      applyToAllSites: model?.applyToAllSites,
      sites: model?.applyToAllSites ? undefined : siteListEntity,
      triggers: model?.triggers?.map((it) => {
        return {
          property: it?.property,
          operator: it?.operator,
          value: it?.dataType === 'number' ? parseFloat(it?.value) : it?.value,
        };
      }),
      actions: model?.actions,
    } as AlertRuleEntity;
    return alertRuleEntity;
  }
  return {} as AlertRuleEntity;
};
