import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import {
  selectForecourtControllerContent,
  selectForecourtControllerStatus,
} from '../../store/forecourt-controllers/selectors';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import * as headerActions from '../../store/header/actions';
import * as dialogActions from '../../store/dialog-box/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import * as actions from '../../store/forecourt-controllers/actions';
import ForecourtControllers from './forecourt-controllers';

const mapStateToProps = (state: RootState) => {
  return {
    forecourtControllerStatus: selectForecourtControllerStatus(state),
    forecourtControllerContent: selectForecourtControllerContent(state),
    authStatus: selectAuthStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadForecourtControllers: () => dispatch({ type: actions.LOAD_FORECOURTCTRLS }),
    deleteForecourtController: (data: string) => dispatch({ type: actions.DELETE_FORECOURTCTRL, payload: data }),
    cancelForecourtControllerReload: () => dispatch({ type: actions.CANCEL_FORECOURTCTRL_RELOAD }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
  };
};

const ForecourtControllerList = connect(mapStateToProps, mapDispatchToProps)(ForecourtControllers);

export default ForecourtControllerList;
