import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { HoseDetail, PumpInfoModel, PumpModel, PumpRecordModel } from '../../../models/pumpModel';
import { ModuleName } from '../../../constants/module-constants';
import { useNavigate, useParams } from 'react-router-dom';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { ValidationError } from '../../../models/baseModels/validationModel';
import { LoadingStatus } from '../../../constants/loading-constants';
import { TextFieldType } from '../../../constants/textfield-constants';
import { FormActionType } from '../../../constants/form-constants';
import {
  DEFAULT_DROP_DOWN_VALUE,
  baudRate,
  communicationProtocol,
  displayType,
  pumpSettingsFlags,
} from '../../../constants/dropdown-constants';
import { findSmallestNumberNotInArray } from '../../../utilities/general-helper';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import Form from '../../../components/form/form.container';
import DropDown from '../../../components/dropdown/dropdown.container';
import CustomSwitch from '../../../components/switch/custom-switch.containter';
import CustomTextField from '../../../components/text-field/text-field.container';
import CustomTooltip from '../../../components/tooltip/custom-tooltip';
import PumpHoses from './hoses';
import '../styles/pump-lists.scss';

interface PumpPageProps {
  pumpInfo: PumpInfoModel;
  pumpContent: PumpRecordModel[];
  pumpStatus: string;
  hasValidationError: boolean;
  tankNameList: KeyValuePair[];
  pageTitle?: string;
  action?: string;
  backDropActionStatus: string;
  selectedSiteId: string;
  selectedOrganisationId: string;
  fieldValidations: ValidationError[];
  userAccess: (moduleName: string) => AuthorisationModel;
  loadPumps: (data: PumpModel) => void;
  loadPumpInfo: (data: PumpModel) => void;
  editPumpItem: (data: PumpInfoModel) => void;
  createPumpItem: (data: PumpInfoModel) => void;
  loadTankNameList: () => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  removeAllValidation: () => void;
  removeValidation: (module: string) => void;
}

const Pump: React.FC<PumpPageProps> = (props: PumpPageProps) => {
  const {
    pumpInfo,
    pumpContent,
    pumpStatus,
    hasValidationError,
    tankNameList,
    pageTitle,
    action,
    backDropActionStatus,
    selectedSiteId,
    selectedOrganisationId,
    fieldValidations,
    userAccess,
    loadPumps,
    loadPumpInfo,
    editPumpItem,
    createPumpItem,
    loadTankNameList,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    removeAllValidation,
    removeValidation,
  } = props;

  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [pumpInfoValue, setPumpInfoValue] = useState({} as PumpInfoModel);
  const [isUpdatingForm, setIsUpdatingForm] = useState(false);
  const [isClickFromViewPage, setIsClickFromViewPage] = useState(false);
  const [existingPumpNumberList, setExistingPumpNumberList] = useState([] as number[]);
  const [secondaryButtonMoreOptions, setSecondaryButtonMoreOptions] = useState([] as KeyValuePair[]);
  const [primaryButtonMoreOptions, setPrimaryButtonMoreOptions] = useState([] as KeyValuePair[]);
  const [minAuthAmountOffset, setMinAuthAmountOffset] = useState(-10);

  const navigate = useNavigate();
  const { orgId, siteId, pumpId } = useParams();
  const isLoadPumpInfoRequested = useRef(false);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  //ACCESS
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.PUMP).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.PUMP).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.PUMP).hasUpdateAccess);
  }, [userAccess]);

  useMemo(() => {
    setLoading(pumpStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(pumpStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(pumpStatus === LoadingStatus.ERROR);
  }, [hasReadAccess, pumpStatus]);

  useEffect(() => {
    setIsUpdatingForm(action === FormActionType.CREATE || action === FormActionType.EDIT);
  }, [action]);

  useEffect(() => {
    if (
      !isLoadPumpInfoRequested.current &&
      hasReadAccess &&
      (action === FormActionType.VIEW || (action === FormActionType.EDIT && !isClickFromViewPage))
    ) {
      loadPumpInfo({
        siteId: siteId,
        organisationId: orgId,
        id: pumpId,
      } as PumpModel);
      isLoadPumpInfoRequested.current = true;
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      loadTankNameList();
      setPumpInfoValue({
        number: findSmallestNumberNotInArray(existingPumpNumberList, 1, 99),
        siteId: siteId,
        organisationId: orgId,
        enabled: true,
        displayType: 'sixDigit',
        flowTimeOut: 120,
        baudRate: 'default',
        hoses: [
          {
            tankId: '',
            number: undefined,
          },
        ],
      } as PumpInfoModel);
      removeAllValidation();
    }
  }, [
    action,
    hasCreateAccess,
    hasReadAccess,
    hasUpdateAccess,
    orgId,
    siteId,
    pumpId,
    existingPumpNumberList,
    isClickFromViewPage,
    loadPumpInfo,
    loadTankNameList,
    removeAllValidation,
    findSmallestNumberNotInArray,
  ]);

  useEffect(() => {
    switch (action) {
      case FormActionType.CREATE:
        setSuccess(hasCreateAccess);
        break;
      case FormActionType.EDIT:
        setSuccess(hasUpdateAccess);
        break;
      case FormActionType.VIEW:
        setSuccess(hasReadAccess);
        break;
    }
  }, [action, hasCreateAccess, hasReadAccess, hasUpdateAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showSiteHeader: true,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: false,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, pageTitle, error]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: action === FormActionType.VIEW,
    } as PageSettingStateModel);
  }, [setPageConfiguration, action]);

  useEffect(() => {
    setIsPageDirty(false);
    if (action === FormActionType.EDIT && hasReadAccess) {
      setIsSaveButtonEnabled(hasUpdateAccess);
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      setIsSaveButtonEnabled(hasCreateAccess);
    }
  }, [hasCreateAccess, hasUpdateAccess, hasReadAccess, action, setIsPageDirty]);

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS || backDropActionStatus === LoadingStatus.WARNING) {
      navigate(`/organisations/${orgId}/sites/${siteId}/pumps`);
    }
  }, [backDropActionStatus, orgId, siteId, navigate]);

  useEffect(() => {
    if (!pumpContent) {
      loadPumps({
        siteId: selectedSiteId,
        organisationId: selectedOrganisationId,
      } as PumpModel);
      return;
    }
    if (pumpContent?.length !== existingPumpNumberList?.length && action === FormActionType.CREATE && hasCreateAccess) {
      setExistingPumpNumberList(
        pumpContent.map((it) => {
          return it.number;
        })
      );
    }
  }, [pumpContent]);

  useEffect(() => {
    if (
      (pumpInfoValue?.defaultOnlineAuthorisationAmount && pumpInfoValue?.defaultOnlineAuthorisationAmount >= 0) ||
      (pumpInfoValue?.defaultOfflineAuthorisationAmount && pumpInfoValue?.defaultOfflineAuthorisationAmount >= 0)
    ) {
      const minValue = Math.min(
        pumpInfoValue?.defaultOnlineAuthorisationAmount && pumpInfoValue?.defaultOnlineAuthorisationAmount > 0
          ? pumpInfoValue?.defaultOnlineAuthorisationAmount
          : 10,
        pumpInfoValue?.defaultOfflineAuthorisationAmount && pumpInfoValue?.defaultOfflineAuthorisationAmount > 0
          ? pumpInfoValue?.defaultOfflineAuthorisationAmount
          : 10
      );
      const smallestNumber = Number(Math.ceil(-minValue) + 1);
      setMinAuthAmountOffset(smallestNumber);
    } else setMinAuthAmountOffset(-10);
  }, [pumpInfoValue?.defaultOnlineAuthorisationAmount, pumpInfoValue?.defaultOfflineAuthorisationAmount]);

  useMemo(() => {
    if (!!pumpInfo) {
      setPumpInfoValue(pumpInfo);
    }
  }, [pumpInfo]);

  const onSaveClickHandler = () => {
    setValidateCounterFlag((prev) => ++prev);
    if (!hasValidationError) {
      switch (action) {
        case FormActionType.EDIT:
          editPumpItem(pumpInfoValue);
          break;
        case FormActionType.CREATE:
          createPumpItem(pumpInfoValue);
          break;
      }
      setValidateCounterFlag(0);
    }
  };

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setIsPageDirty(isSaveButtonEnabled);
      setPumpInfoValue((prevstate) => {
        return {
          ...prevstate,
          [newvalue.key]: newvalue.value,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const onEditHandler = () => {
    setIsClickFromViewPage(true);
    navigate(`/organisations/${orgId}/sites/${siteId}/pumps/${pumpId}/details/edit`);
  };

  const onCancel = () => {
    setValidateCounterFlag(0);
  };

  const dropDownListItems = (ref: string) => {
    if (ref === 'baudRate') {
      return baudRate;
    }
    if (ref === 'communicationProtocol') {
      return communicationProtocol;
    }

    if (ref === 'displayType') {
      return displayType;
    }
    if (ref === 'pumpSettingsFlags') {
      return pumpSettingsFlags;
    }
  };

  const tooltipTitle: string = `This value can be used to adjust the authorisation amount if the pump is overrunning the amount.
  \nExample: 
Say the user entered $50 as their max authorisation amount.
If the offset value is $2, then the bank authorisation amount will be $52 but the pump will still be authorised for $50.  
And if the value is -$2, then the bank authorisation amount will be $50 but the pump will be authorised for $48.
  \nWe recommend using negative values.`;

  const HosesConfig = useMemo(() => {
    const handleNewHosesValue = (newvalue: HoseDetail[]) => {
      setIsPageDirty(isSaveButtonEnabled);
      setPumpInfoValue((prevstate) => {
        return {
          ...prevstate,
          hoses: [...newvalue],
        };
      });
    };

    return (
      <PumpHoses
        validateCounter={validateCounterFlag}
        hoses={pumpInfoValue.hoses}
        onBindingValue={handleNewHosesValue}
        removeValidation={removeValidation}
        tankNameList={tankNameList}
        readOnly={!isUpdatingForm}
      ></PumpHoses>
    );
  }, [
    validateCounterFlag,
    pumpInfoValue.hoses,
    removeValidation,
    tankNameList,
    isUpdatingForm,
    setIsPageDirty,
    isSaveButtonEnabled,
  ]);
  const hasValidHose = pumpInfoValue?.hoses?.some((hose) => {
    const hasNumber = Boolean(hose?.number);
    const hasTankId = Boolean(hose?.tankId);

    return hasNumber && hasTankId && tankNameList?.findIndex((it) => it.key === hose?.tankId) > -1;
  });

  const tabWizardList = [
    {
      tabType: 'link',
      tabLink: `/organisations/${orgId}/sites/${siteId}/pumps`,
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>Pumps</span> <NavigateNextIcon className='next-icon' />
        </div>
      ),
    },
    {
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>Basic</span>
          <NavigateNextIcon className='next-icon' />
        </div>
      ),
      tabError: fieldValidations?.some(
        (it) =>
          (it.name === 'number' && it.hasError === true) ||
          (it.name === 'channel' && it.hasError === true) ||
          (it.name === 'communicationProtocol' && it.hasError === true) ||
          (it.name === 'authorisationAmountOffset' && it.hasError === true)
      ),
      tabFormCompleted: !fieldValidations?.some(
        (it) =>
          (it.name === 'number' && it.hasError === true) ||
          (it.name === 'channel' && it.hasError === true) ||
          (it.name === 'communicationProtocol' && it.hasError === true) ||
          (it.name === 'authorisationAmountOffset' && it.hasError === true)
      ),
      tabPanel: (
        <div className='wizard-tab-panel-details'>
          <CustomTextField
            key={'number'}
            label={'Pump Number'}
            placeholder={`Enter Pump Number`}
            onBindingValue={onTextChangeHandler}
            name={'number'}
            value={pumpInfoValue?.number}
            type={TextFieldType.INTEGER}
            validateCounter={validateCounterFlag}
            isMandatory={true}
            maxRange={99}
            minRange={1}
            readOnly={!isUpdatingForm}
          ></CustomTextField>

          <CustomTextField
            key={'channel'}
            label={'Channel'}
            placeholder={`Enter Channel`}
            onBindingValue={onTextChangeHandler}
            type={TextFieldType.INTEGER}
            name={'channel'}
            value={pumpInfoValue?.channel}
            validateCounter={validateCounterFlag}
            maxRange={3}
            minRange={0}
            isMandatory={true}
            readOnly={!isUpdatingForm}
          ></CustomTextField>

          <DropDown
            key={'communicationProtocol'}
            name={'communicationProtocol'}
            value={
              !!pumpInfoValue?.communicationProtocol ? pumpInfoValue?.communicationProtocol : DEFAULT_DROP_DOWN_VALUE
            }
            onBindingValue={onTextChangeHandler}
            label={'Communication Protocol'}
            keyValuePair={dropDownListItems('communicationProtocol')}
            validateCounter={validateCounterFlag}
            isMandatory={true}
            readOnly={!isUpdatingForm}
          />

          <CustomTextField
            key={'pumpAddress'}
            onBindingValue={onTextChangeHandler}
            label={'Pump Address'}
            placeholder={'Enter Pump Address'}
            name={'pumpAddress'}
            value={pumpInfoValue?.pumpAddress}
            type={TextFieldType.INTEGER}
            validateCounter={validateCounterFlag}
            maxRange={99}
            minRange={1}
            readOnly={!isUpdatingForm}
          />
          <CustomSwitch
            value={pumpInfoValue?.enabled || false}
            name={'enabled'}
            onBindingValue={onTextChangeHandler}
            label={'Enabled'}
            readOnly={!isUpdatingForm}
          ></CustomSwitch>

          <DropDown
            key={'baudRate'}
            name={'baudRate'}
            value={!!pumpInfoValue?.baudRate ? pumpInfoValue?.baudRate : DEFAULT_DROP_DOWN_VALUE}
            onBindingValue={onTextChangeHandler}
            label={'Baud Rate'}
            keyValuePair={dropDownListItems('baudRate')}
            readOnly={!isUpdatingForm}
            hideDefaultSelect={true}
          />

          <DropDown
            key={'displayType'}
            name={'displayType'}
            value={pumpInfoValue?.displayType ? pumpInfoValue?.displayType : DEFAULT_DROP_DOWN_VALUE}
            onBindingValue={onTextChangeHandler}
            label={'Display Type'}
            keyValuePair={dropDownListItems('displayType')}
            readOnly={!isUpdatingForm}
          />

          <CustomTextField
            key={'flowTimeOut'}
            label={'No Flow Timeout'}
            placeholder={`Enter No Flow Timeout`}
            type={TextFieldType.INTEGER}
            name={'flowTimeOut'}
            value={pumpInfoValue?.flowTimeOut}
            onBindingValue={onTextChangeHandler}
            validateCounter={validateCounterFlag}
            maxRange={120}
            minRange={0}
            readOnly={!isUpdatingForm}
          ></CustomTextField>

          <CustomTextField
            key={'authorisationAmountOffset'}
            label={'Authorisation Amount Offset'}
            placeholder={`Enter Authorisation Amount Offset`}
            type={TextFieldType.DECIMAL}
            name={'authorisationAmountOffset'}
            value={pumpInfoValue?.authorisationAmountOffset}
            onBindingValue={onTextChangeHandler}
            validateCounter={validateCounterFlag}
            maxRange={10}
            minRange={minAuthAmountOffset}
            maxCharLength={pumpInfoValue?.authorisationAmountOffset < 0 ? 6 : 5}
            readOnly={!isUpdatingForm}
            tooltipTitle={tooltipTitle}
          ></CustomTextField>

          {
            <p className='default-auth-amount-label-container'>
              Default Authorisation Amount
              <CustomTooltip
                title={
                  'The amount to authorise transactions for on this pump. This will only be used if configured in a given BIN Range.'
                }
              />
            </p>
          }

          <CustomTextField
            key={'defaultOnlineAuthorisationAmount'}
            name={'defaultOnlineAuthorisationAmount'}
            inlineLabel={'Online'}
            placeholder={`Enter Online Amount`}
            type={TextFieldType.INTEGER}
            value={pumpInfoValue?.defaultOnlineAuthorisationAmount}
            onBindingValue={onTextChangeHandler}
            validateCounter={validateCounterFlag}
            maxRange={
              pumpInfoValue?.displayType === 'fiveDigit'
                ? 999
                : pumpInfoValue?.displayType === 'sixDigit'
                  ? 9999
                  : pumpInfoValue?.displayType === 'sevenDigit'
                    ? 99999
                    : 99999
            }
            minRange={1}
            readOnly={!isUpdatingForm}
          ></CustomTextField>

          <CustomTextField
            key={'defaultOfflineAuthorisationAmount'}
            name={'defaultOfflineAuthorisationAmount'}
            inlineLabel={'Offline'}
            placeholder={`Enter Offline Amount`}
            type={TextFieldType.INTEGER}
            value={pumpInfoValue?.defaultOfflineAuthorisationAmount}
            onBindingValue={onTextChangeHandler}
            validateCounter={validateCounterFlag}
            maxRange={
              pumpInfoValue?.displayType === 'fiveDigit'
                ? 999
                : pumpInfoValue?.displayType === 'sixDigit'
                  ? 9999
                  : pumpInfoValue?.displayType === 'sevenDigit'
                    ? 99999
                    : 99999
            }
            minRange={1}
            readOnly={!isUpdatingForm}
          ></CustomTextField>
        </div>
      ),
    },
    {
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>Hoses</span>
        </div>
      ),
      tabError:
        fieldValidations?.some(
          (it) =>
            (it.name === 'tankId0' && it.hasError === true) ||
            (it.name === 'number0' && it.hasError === true) ||
            (it.name === 'tankId1' && it.hasError === true) ||
            (it.name === 'number1' && it.hasError === true) ||
            (it.name === 'tankId2' && it.hasError === true) ||
            (it.name === 'number2' && it.hasError === true) ||
            (it.name === 'tankId3' && it.hasError === true) ||
            (it.name === 'number3' && it.hasError === true) ||
            (it.name === 'tankId4' && it.hasError === true) ||
            (it.name === 'number4' && it.hasError === true) ||
            (it.name === 'tankId5' && it.hasError === true) ||
            (it.name === 'number5' && it.hasError === true)
        ) || (action === FormActionType.VIEW || action === FormActionType.EDIT ? !hasValidHose : undefined),
      tabFormCompleted:
        !fieldValidations?.some(
          (it) =>
            (it.name === 'tankId0' && it.hasError === true) ||
            (it.name === 'number0' && it.hasError === true) ||
            (it.name === 'tankId1' && it.hasError === true) ||
            (it.name === 'number1' && it.hasError === true) ||
            (it.name === 'tankId2' && it.hasError === true) ||
            (it.name === 'number2' && it.hasError === true) ||
            (it.name === 'tankId3' && it.hasError === true) ||
            (it.name === 'number3' && it.hasError === true) ||
            (it.name === 'tankId4' && it.hasError === true) ||
            (it.name === 'number4' && it.hasError === true) ||
            (it.name === 'tankId5' && it.hasError === true) ||
            (it.name === 'number5' && it.hasError === true)
        ) &&
        pumpInfoValue?.hoses &&
        pumpInfoValue?.hoses?.length > 0 &&
        hasValidHose,
      tabPanel: HosesConfig,
    },
  ];

  const currentTabWizardChangeHandler = (data: number) => {
    if (data <= 1) {
      setSecondaryButtonMoreOptions([]);
      setPrimaryButtonMoreOptions([
        {
          key: 'save',
          value: 'Save',
          additionalValue: onSaveClickHandler,
        } as KeyValuePair,
      ]);
    } else if (data >= 2) {
      setSecondaryButtonMoreOptions([
        {
          key: 'cancel',
          value: 'Cancel',
          additionalValue: () => navigate(`/organisations/${orgId}/bin-ranges`),
        } as KeyValuePair,
      ]);
      setPrimaryButtonMoreOptions([]);
    }
  };

  const wizardListChangeHandler = () => {
    if (fieldValidations && fieldValidations?.length > 0) setValidateCounterFlag((prev) => ++prev);
  };

  return (
    <React.Fragment>
      <Form
        displayLoadingIndicator={(action === FormActionType.VIEW || action === FormActionType.EDIT) && loading}
        displayErrorDetails={error}
        displayNoAccessMessage={
          (action === FormActionType.VIEW && !hasReadAccess) ||
          (action === FormActionType.CREATE && !hasCreateAccess) ||
          (action === FormActionType.EDIT && !hasUpdateAccess)
        }
        displayForm={success}
        isSaveButtonEnabled={isSaveButtonEnabled}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        onEditClick={onEditHandler}
        formDataloading={loading}
        hasUpdateAccess={hasUpdateAccess}
        listURL={`/organisations/${orgId}/sites/${siteId}/pumps`}
        isClickFromViewPage={isClickFromViewPage}
        tabWizardList={tabWizardList}
        onCurrentTabWizardChange={currentTabWizardChangeHandler}
        wizardListChange={wizardListChangeHandler}
        primaryButtonMoreOptions={primaryButtonMoreOptions}
        secondaryButtonMoreOptions={secondaryButtonMoreOptions}
      >
        <></>
      </Form>
    </React.Fragment>
  );
};

export default Pump;
