import './styles/transactions.scss';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import * as dateTimeHelper from '../../utilities/datetime-helper';

export const TransactionHeader = () => {
  return (
    <TableRow>
      <TableCell className='icon-header'></TableCell>
      <TableCell className='site-header'>Site</TableCell>
      <TableCell align='left'>{`Time ${dateTimeHelper.getBrowserTimezone()}`}</TableCell>
      <TableCell align='left'>Card No. (PAN)</TableCell>
      <TableCell align='left'>Type</TableCell>
      <TableCell align='right'>Amount</TableCell>
      <TableCell align='left' className='response-header'>
        Response
      </TableCell>
      <TableCell align='left'>Reference</TableCell>
      <TableCell align='left'>State</TableCell>
    </TableRow>
  );
};
