import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { LoadingStatus } from '../../constants/loading-constants';
import { Messages } from '../../constants/messages';
import { MeasuringCapability } from '../../constants/dropdown-constants';
import { TankRecordEntity, TankResponse } from '../../entities/tank';
import { ProductsInfoEntity, ProductsResponse } from '../../entities/product';
import { TankModel } from '../../models/tankModel';
import { ProductInfoModel } from '../../models/productModel';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { TerminalModel, TerminalRecordModel } from '../../models/terminalModel';
import { TerminalOperationActionPostModel } from '../../models/terminalOperationModel';
import { selectOrganisationId } from '../auth/selectors';
import {
  setTankStatus,
  setTankData,
  setTankError,
  setTankInfo,
  setTankNameList,
  setTankNumberList,
  setTankDetailStatus,
  setTankDetailError,
} from './reducers';
import { selectSelectedSiteId } from '../sites/selectors';
import { setSnackBarError, setSnackBarSuccess } from '../snackbar/reducers';
import { getApiErrorMessage } from '../../utilities/errorhandler';
import { closeModal, setModalError, setModalActionStatus } from '../modals/reducers';
import { setGenericErrorData } from '../generic-error/reducers';
import { getGenericErrorMessage } from '../../utilities/errorhandler';
import { setProductListName } from '../product/reducers';
import { setDialogBoxActionStatus, closeDialogBox } from '../dialog-box/reducers';
import { clearAllFieldValidation } from '../fieldValidation/reducers';
import { showBackdrop, hideBackdrop, setBackDropActionStatus, setBackDropError } from '../backdrop/reducers';
import { setIsPageDirty } from '../page-configuration/reducers';
import { reLoadTerminalData } from '../terminal/sagas';
import { selectTerminalData } from '../terminal/selectors';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import * as actions from './actions';
import * as terminalActions from '../terminal/actions';
import * as services from './services';
import * as productServices from '../product/services';
import * as terminalOperationServices from '../terminal-operations/services';
import * as datetimeHelper from '../../utilities/datetime-helper';
import * as fieldHelper from '../../utilities/field-helper';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_TANKS, loadTanks);
  yield takeLatest(actions.DELETE_TANK, deleteTank);
  yield takeLatest(actions.CREATE_TANK, createTank);
  yield takeLatest(actions.EDIT_TANK, editTank);
  yield takeLatest(actions.LOAD_TANK_INFO, loadTankInfo);
  yield takeLatest(actions.LOAD_TANKNAME_LIST, loadTankNameList);
  yield takeLatest(actions.LOAD_TANKNUMBER_LIST, loadTankNumberList);
  yield takeLatest(actions.CANCEL_TANK_RELOAD, cancelTankReload);
}
let isCancelTankReloadRequested = false;

export function* loadTanks() {
  try {
    yield put(setTankStatus(LoadingStatus.LOADING));
    isCancelTankReloadRequested = false;
    const organisationId: string = yield select(selectOrganisationId);
    const siteId: string = yield select(selectSelectedSiteId);
    let response: TankResponse = yield call(services.getTankStatuses, siteId, organisationId);
    let product: ProductsResponse = {} as ProductsResponse;
    let tankData: TankModel[] = yield call(mapTankEntityToTankModelList, response, product);

    yield put(setTankData(tankData));
    yield put(setTankStatus(LoadingStatus.SUCCESS));

    if (!product?.items || product?.count <= 0) {
      product = yield call(productServices.getProductsData, organisationId);
      let tankData: TankModel[] = yield call(mapTankEntityToTankModelList, response, product);
      yield put(setTankData(tankData));
    }

    if (tankData && tankData?.length > 0) {
      // get terminal list first
      let terminalData: TerminalRecordModel[] = yield select(selectTerminalData);
      if (!terminalData || terminalData?.length <= 0) {
        yield call(reLoadTerminalData, {
          payload: {
            siteId: siteId,
            organisationId: organisationId,
          } as TerminalModel,
          type: terminalActions.RELOAD_TERMINALS,
        });
        terminalData = yield select(selectTerminalData);
      }

      // ask each terminal upload the latest tank status
      const responses: any[] = yield all(
        terminalData.map((record) => {
          return call(function* () {
            try {
              yield call(terminalOperationServices.postOperationAction, record.id, {
                organisationId: organisationId,
                type: 'tanksStatusUpload',
              } as TerminalOperationActionPostModel);
              return { data: 200 };
            } catch (error) {
              return { data: 'error' };
            }
          });
        })
      );

      // if success receive data from operation api, retrieve the updated pump data twice with 5 sec wait
      if (responses.some((result) => result.data === 200)) {
        let reloadCount = 0;
        while (reloadCount < 2) {
          if (isCancelTankReloadRequested) return;

          yield delay(5000);
          yield call(reLoadTankData, siteId, organisationId, product);
        }
      }
    }
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setTankError());
  }
}

function* cancelTankReload() {
  isCancelTankReloadRequested = true;
}

function* reLoadTankData(siteId: string, organisationId: string, product: ProductsResponse): Generator<any, void, any> {
  try {
    let response: TankResponse = yield call(services.getTankStatuses, siteId, organisationId);
    let tankData: TankModel[] = yield call(mapTankEntityToTankModelList, response, product);
    yield put(setTankData(tankData));
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setTankError());
  }
}

export function* loadTankInfo(action: PayloadAction<string>) {
  try {
    if (!!action.payload) {
      yield put(setTankDetailStatus(LoadingStatus.LOADING));
      const organisationId: string = yield select(selectOrganisationId);
      let response: TankRecordEntity = yield call(services.getTankInfo, action.payload, organisationId);
      let productResponse: ProductsResponse = yield call(productServices.getProductsData, organisationId);
      let productList: KeyValuePair[] = yield call(MapProductItemEntityToKeyValuePair, productResponse);
      let productInfoList: ProductInfoModel[] = yield call(mapProductEntityToModel, productResponse);
      let tankInfo: TankModel = yield call(mapTankEntityToTankModel, response, productInfoList, productList);
      yield put(setProductListName(productList));
      yield put(setTankInfo(tankInfo));
      yield put(setTankDetailStatus(LoadingStatus.SUCCESS));
    }
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setTankDetailError());
    yield put(clearAllFieldValidation());
  }
}

export function* createTank(action: PayloadAction<TankModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    const siteId: string = yield select(selectSelectedSiteId);
    let tankEntity: TankRecordEntity = MapTankModelToEntity(action.payload, organisationId, siteId);
    yield call(services.createTank, tankEntity);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.TANK_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
  }
}

export function* editTank(action: PayloadAction<TankModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    const siteId: string = yield select(selectSelectedSiteId);
    let tankEntity: TankRecordEntity = MapTankModelToEntity(action.payload, organisationId, siteId);
    yield call(services.editTank, tankEntity, action.payload.id);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.TANK_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
  }
}

export function* deleteTank(action: PayloadAction<string>) {
  try {
    yield put(setDialogBoxActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    yield call(services.deleteTank, action.payload, organisationId);
    yield put(closeDialogBox());
    yield put(setSnackBarSuccess(Messages.TANK_DELETE_SUCCESS));
    yield call(loadTanks);
  } catch (error) {
    yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* loadTankNameList() {
  try {
    yield put(setModalActionStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);
    let tanks: TankResponse = yield getTankList(organisationId);
    let product: ProductsResponse = yield call(productServices.getProductsData, organisationId);
    let tankNameList: KeyValuePair[] = yield call(mapTankEntityToKeyValuePair, tanks, product);
    yield put(setTankNameList(tankNameList));
    yield put(setModalActionStatus(LoadingStatus.SUCCESS));
  } catch {
    yield put(setTankError());
    yield put(setModalError(true));
    yield put(setSnackBarError(Messages.PRODUCTS_INFO_ERROR));
    yield put(closeModal());
  }
}

export function* loadTankNumberList(action: PayloadAction<string>) {
  try {
    const organisationId: string = yield select(selectOrganisationId);
    let tanks: TankResponse = yield getTankList(organisationId, action.payload);
    let tankNumberList: KeyValuePair[] = yield call(mapTankNumberEntityToKeyValuePair, tanks);
    yield put(setTankNumberList(tankNumberList));
  } catch {
    yield put(setTankError());
  }
}

export function* getTankList(organisationId: string, siteId?: string) {
  try {
    let updatedSiteId = siteId as string;
    if (!siteId) updatedSiteId = yield select(selectSelectedSiteId);
    let tankList: TankResponse = yield call(services.getTankList, updatedSiteId, organisationId);
    return tankList;
  } catch (error) {
    yield put(setTankError());
  }
}

const mapTankEntityToTankModelList = (tanks: TankResponse, products: ProductsResponse) => {
  if (tanks && tanks.items.length > 0) {
    const result: TankModel[] = tanks.items.map((tank, i) => {
      let tankName = tank.number ? tank.number : 'Unspecified';
      return {
        id: tank.id,
        number: fieldMappingHelper.validateNumericValue(tank.number),
        productId: tank.productId,
        productName: getProductName(products.items, tank.productId),
        displayName: `${tankName} ${getProductName(products.items, tank.productId)}`,
        siteId: tank.siteId,
        organisationId: tank.organisationId,
        status: tank.status,
        capacity: tank.capacity,
        product: tank.product,
        water: tank.water,
        ullage: tank.ullage,
        current: tank.current,
        temperature: tank.temperature,
        healthIndicator: fieldHelper.getTankStatusIndicator(tank.status),
        lastUpdatedDateTimeUtc: datetimeHelper.getDayCounter(tank.dateTimeUtc),
        messages: tank?.messages,
      } as TankModel;
    });

    return result;
  }
  return [] as TankModel[];
};

const mapTankEntityToKeyValuePair = (response: TankResponse, products: ProductsResponse) => {
  if (response && response.items.length > 0) {
    const result: KeyValuePair[] = response.items.map((record, i) => {
      return {
        key: record.id,
        value: `${record.number} ${getProductName(products.items, record.productId)}`,
      };
    });
    return result;
  }
};

const mapTankNumberEntityToKeyValuePair = (response: TankResponse) => {
  if (response && response.items.length > 0) {
    const result: KeyValuePair[] = response.items.map((record, i) => {
      return {
        key: record.id,
        value: record.number,
      };
    });
    return result;
  }
};

const mapTankEntityToTankModel = (
  tank: TankRecordEntity,
  productInfoList: ProductInfoModel[],
  productList: KeyValuePair[]
) => {
  let productName = productInfoList.find((p) => p.id === tank.productId)?.name;

  let tankguages = tank.gauges?.map((gauge) => {
    return {
      type: gauge?.type,
      measuringCapabilities: gauge?.measuringCapabilities.split(', ').map((it) => {
        return MeasuringCapability.find((its) => its.key === it)?.key;
      }),
      channel: gauge?.type === 'veederRoot' ? undefined : gauge?.channel,
      calibration: {
        currentUnitOfMeasure: gauge?.calibration?.currentUnitOfMeasure,
        correspondingValueUnitOfMeasure: gauge?.calibration?.correspondingValueUnitOfMeasure,
        points: gauge?.calibration?.points?.map((it) => {
          return {
            current: it?.current?.toString(),
            correspondingValue: it?.correspondingValue,
          };
        }),
      },
      strapping: gauge?.strapping,
      sensorPosition:
        gauge?.calibration?.points[0]?.correspondingValue < gauge?.calibration?.points[1]?.correspondingValue
          ? 'bottom'
          : 'top',
    };
  });

  if (tank.id) {
    return {
      id: tank.id,
      number: fieldMappingHelper.validateNumericValue(tank.number),
      productId: fieldMappingHelper.validateEnumValue(tank.productId, productList),
      productName: productName,
      displayName: `${tank.number} (${productName})`,
      siteId: tank.siteId,
      organisationId: tank.organisationId,
      capacity: tank.capacity,
      gauges: tankguages,
    } as TankModel;
  } else throw new Error('Not a valid Tank API Response');
};

const getProductName = (productList: ProductsInfoEntity[], productId: string) => {
  if (!!productList) {
    let productName = productList.filter((i) => i.id === productId)[0]?.name;
    return productName ? `(${productName})` : '';
  }
  return '';
};

const MapTankModelToEntity = (model: TankModel, organisationId: string, siteId: string): TankRecordEntity => {
  if (model) {
    if (!model?.capacity?.unitOfMeasure) {
      model.capacity.unitOfMeasure = 'litre';
    }
    let tankguages = model.gauges?.map((gauge) => {
      return {
        type: gauge?.type,
        measuringCapabilities: String(
          gauge?.measuringCapabilities.map((it) => {
            return MeasuringCapability.find((its) => its.key === it)?.key;
          })
        ),
        channel: gauge?.type === 'currentLoop' && gauge?.channel ? gauge?.channel : undefined,
        calibration:
          gauge?.type === 'currentLoop' && gauge?.calibration
            ? {
                currentUnitOfMeasure: gauge?.calibration?.currentUnitOfMeasure,
                correspondingValueUnitOfMeasure: gauge?.calibration?.correspondingValueUnitOfMeasure,
                points: gauge?.calibration?.points?.map((it) => {
                  return {
                    current: fieldMappingHelper.convertStringToNumber(it?.current),
                    correspondingValue: it?.correspondingValue,
                  };
                }),
              }
            : undefined,
        strapping: gauge?.type === 'currentLoop' && gauge?.strapping ? gauge?.strapping : undefined,
      };
    });
    let tankEntity = {
      number: fieldMappingHelper.sanitizeNumericValue(model.number),
      productId: fieldMappingHelper.sanitizeStringDropDownValue(model.productId),
      organisationId: organisationId,
      siteId: siteId,
      capacity: model.capacity,
      gauges: tankguages && tankguages?.length > 0 ? tankguages : undefined,
    } as TankRecordEntity;
    return tankEntity;
  }
  return {} as TankRecordEntity;
};

const MapProductItemEntityToKeyValuePair = (response: ProductsResponse) => {
  if (response && response.items.length > 0) {
    const result: KeyValuePair[] = response.items.map((item) => {
      return {
        key: item.id,
        value: item.name,
      };
    });

    return result;
  }
};

const mapProductEntityToModel = (response: ProductsResponse) => {
  if (response && response.items.length > 0) {
    const reuslt: ProductInfoModel[] = response.items.map((item, i) => {
      return {
        id: item.id,
        organisationId: item.organisationId,
        name: item.name,
        shortName: item.shortName,
        internalCode: item.code.internal,
        externalCodes: item.code.external,
      } as ProductInfoModel;
    });

    return reuslt;
  }

  return [] as ProductInfoModel[];
};
