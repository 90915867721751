import React, { useState, useEffect, useRef, useMemo } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import CustomButton from '../../components/button/custom-button';
import { TransactionType, transactionDisplayType } from '../../constants/transaction-constants';
import {
  cardType,
  transactionAcquirerName,
  transactionAcquirerState,
  transactionState,
} from '../../constants/dropdown-constants';
import { TransactionModel } from '../../models/transactionModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { ModalType } from '../../constants/modal-constants';
import { ButtonStyle } from '../../constants/button-constants';
import { checkIsTextEllipsis } from '../../utilities/general-helper';
import * as dateTimeHelper from '../../utilities/datetime-helper';
import * as generalHelper from '../../utilities/general-helper';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import './styles/transactions.scss';

interface KeyValue {
  key: string;
  value: string | number;
}

interface TransactionRowProps {
  row: TransactionModel;
  rowNumber: number;
  openModal: (data: ModalStateModel) => void;
}

export const TransactionRow = (props: TransactionRowProps) => {
  const { row, rowNumber, openModal } = props;
  const [keyValueList, updateKeyValueList] = useState([] as KeyValue[]);
  const [cardInfoKeyValueList, updateCardInfoKeyValueList] = useState([] as KeyValue[]);
  const [cardDetailKeyValueList, updateCardDetailKeyValueList] = useState([] as KeyValue[]);
  const [isTextEllipsis, setIsTextEllipsis] = useState<boolean>(false);
  const [cardSectionGridUsed, setCardSectionGridUsed] = useState(0);
  const SiteNameSection = useRef<HTMLDivElement | null>(null);

  const updateTooltipDisplay = () => {
    setIsTextEllipsis(checkIsTextEllipsis(SiteNameSection?.current));
  };

  useEffect(() => {
    updateTooltipDisplay();
    let cardSectionCount = 0;
    const { binRange, cardType, odometer, engineHours } = row;
    const count = [binRange, cardType, odometer, engineHours].filter(Boolean).length;
    if (count === 1) {
      cardSectionCount = 3;
    } else if (count === 2) {
      cardSectionCount = 4;
    } else if (count === 3) {
      cardSectionCount = 6;
    } else if (count === 4) {
      cardSectionCount = 7;
    }
    setCardSectionGridUsed(cardSectionCount);
  }, [row]);

  useEffect(() => {
    window.addEventListener('resize', updateTooltipDisplay);
    return () => window.removeEventListener('resize', updateTooltipDisplay);
  }, []);

  useEffect(() => {
    if (
      row.terminalNumber &&
      !keyValueList
        .map((it) => {
          return it.key;
        })
        .includes('Terminal Number')
    ) {
      updateKeyValueList((keyValueList) => [...keyValueList, { key: 'Terminal Number', value: row.terminalNumber }]);
    }

    if (
      (row.acquirer?.name || row.acquirer?.connectionState) &&
      !keyValueList
        .map((it) => {
          return it.key;
        })
        .includes('Acquirer')
    ) {
      updateKeyValueList((keyValueList) => [
        ...keyValueList,
        {
          key: 'Acquirer',
          value: `${fieldMappingHelper.getDisplayValue(
            row.acquirer.name,
            transactionAcquirerName
          )} / ${fieldMappingHelper.getDisplayValue(row.acquirer.connectionState, transactionAcquirerState)}`,
        },
      ]);
    }

    if (
      row.responseCode &&
      !keyValueList
        .map((it) => {
          return it.key;
        })
        .includes('ReCo')
    ) {
      updateKeyValueList((keyValueList) => [
        ...keyValueList,
        {
          key: 'ReCo',
          value: row.responseCode,
        },
      ]);
    }

    if (
      row.receipt &&
      !keyValueList
        .map((it) => {
          return it.key;
        })
        .includes('Receipt')
    ) {
      updateKeyValueList((keyValueList) => [...keyValueList, { key: 'Receipt', value: row.receipt }]);
    }

    if (
      row.binRange &&
      !cardInfoKeyValueList
        .map((it) => {
          return it.key;
        })
        .includes('BIN Range')
    ) {
      updateCardInfoKeyValueList((keyValueList) => [...keyValueList, { key: 'BIN Range', value: row.binRange }]);
    }

    if (
      row.cardType &&
      !cardInfoKeyValueList
        .map((it) => {
          return it.key;
        })
        .includes('Card Type')
    ) {
      updateCardInfoKeyValueList((keyValueList) => [
        ...keyValueList,
        { key: 'Card Type', value: `${fieldMappingHelper.getDisplayValue(row.cardType, cardType)}` },
      ]);
    }

    if (row.tags && row.tags?.length > 0) {
      row.tags?.map((it) => {
        if (!cardInfoKeyValueList.map((it) => it.key).includes(it.key))
          updateCardInfoKeyValueList((keyValueList) => [...keyValueList, { key: it.key, value: it.value }]);
      });
    }

    if (
      row.odometer &&
      !cardDetailKeyValueList
        .map((it) => {
          return it.key;
        })
        .includes('Odometer')
    ) {
      updateCardDetailKeyValueList((keyValueList) => [...keyValueList, { key: 'Odometer', value: row.odometer }]);
    }

    if (
      row.engineHours &&
      !cardDetailKeyValueList
        .map((it) => {
          return it.key;
        })
        .includes('Engine Hours')
    ) {
      updateCardDetailKeyValueList((keyValueList) => [
        ...keyValueList,
        { key: 'Engine Hours', value: row.engineHours },
      ]);
    }

    if (
      row.orderNumber &&
      !cardDetailKeyValueList
        .map((it) => {
          return it.key;
        })
        .includes('Order Number')
    ) {
      updateCardDetailKeyValueList((keyValueList) => [
        ...keyValueList,
        { key: 'Order Number', value: row.orderNumber },
      ]);
    }

    if (
      row.vehicleNumber &&
      !cardDetailKeyValueList
        .map((it) => {
          return it.key;
        })
        .includes('Vehicle Number')
    ) {
      updateCardDetailKeyValueList((keyValueList) => [
        ...keyValueList,
        { key: 'Vehicle Number', value: row.vehicleNumber },
      ]);
    }
  }, [keyValueList, row]);

  const [open, setOpen] = useState(false);
  const collapseRef = useRef(open);

  const onViewReceiptHandler = useMemo(() => {
    return () => {
      openModal({
        type: ModalType.RECEIPT,
        dataId: row.receipt,
      } as ModalStateModel);
      collapseRef.current = true;
    };
  }, [openModal, row.receipt]);

  const Row = () => {
    return (
      <React.Fragment>
        <TableRow
          className={rowNumber % 2 === 0 ? 'even-row' : 'odd-row'}
          sx={{ '& > *': { borderBottom: 'unset' } }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {keyValueList.length > 0 && (
            <TableCell
              align='left'
              component='th'
              scope='row'
              className={`collapse-button-container  ${rowNumber % 2 === 0 ? 'even-row' : 'odd-row'}`}
            >
              <IconButton aria-label='expand row' size='small'>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          )}
          <Tooltip title={row.siteName} disableHoverListener={!isTextEllipsis}>
            <TableCell
              className={`site-column-container ${rowNumber % 2 === 0 ? 'even-row' : 'odd-row'}`}
              component='th'
              scope='row'
            >
              <div ref={SiteNameSection} className='site-name-section'>
                {row.siteName}
              </div>
            </TableCell>
          </Tooltip>
          <TableCell align='left'>
            {dateTimeHelper.formatDateTimebyTimeZone(
              dateTimeHelper.getBrowserLocalTimezone(),
              row.dateTimeUtc,
              'DD/MM/YY hh:mm A'
            )}
          </TableCell>
          <TableCell align='left'>{row.cardPan}</TableCell>
          <TableCell align='left'>{fieldMappingHelper.getDisplayValue(row.type, transactionDisplayType)}</TableCell>
          <TableCell className='amount-cell' align='right'>
            {row.amountValue < 0 ? '-' : ''}
            {generalHelper.getCurrencySign(row.amountCurrencyCode)}
            {generalHelper.numberHandlerWithDP(Math.abs(row.amountValue), 2)}
          </TableCell>
          <TableCell align='left' className={'response-column-container'}>
            {row.responseText}
          </TableCell>
          <TableCell align='left'>{row.reference}</TableCell>
          <TableCell align='left'>{fieldMappingHelper.getDisplayValue(row.state, transactionState)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={`nested-table desktop-view ${rowNumber % 2 === 0 ? 'even-row' : 'odd-row'}`}
            colSpan={12}
          >
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box>
                {row.type.toLocaleLowerCase() === TransactionType.COMPLETION && (
                  <>
                    <Grid container spacing={4}>
                      <Grid item xs={9}>
                        <Grid item xs={12}>
                          {row?.pumps?.length > 0 && <PumpInfoTableSection />}
                        </Grid>
                        <Grid item xs={4}>
                          <KeyValueSection />
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <>{cardInfoKeyValueList?.length > 0 && <CardInfoKeyValueSection />}</>
                      </Grid>
                      <Grid item xs={3}>
                        <>{cardDetailKeyValueList?.length > 0 && <CardDetailKeyValueSection />}</>
                      </Grid>
                    </Grid>
                  </>
                )}
                {row.type.toLocaleLowerCase() !== TransactionType.COMPLETION && (
                  <>
                    <Grid container spacing={4}>
                      {row?.pumps?.length > 0 && (
                        <Grid
                          item
                          xs={
                            row.type.toLocaleLowerCase() === TransactionType.COMPLETION
                              ? 9
                              : row.type.toLocaleLowerCase() === TransactionType.AUTHORISATION
                                ? 3
                                : 2
                          }
                        >
                          <PumpInfoTableSection />
                        </Grid>
                      )}
                      {cardInfoKeyValueList?.length > 0 && (
                        <Grid item xs={3}>
                          <CardInfoKeyValueSection />
                        </Grid>
                      )}
                      {cardDetailKeyValueList?.length > 0 && (
                        <Grid item xs={3}>
                          <CardDetailKeyValueSection />
                        </Grid>
                      )}
                      {(row?.binRange || row?.cardType || row?.odometer || row.engineHours) &&
                        row.type.toLocaleLowerCase() !== TransactionType.COMPLETION && (
                          <Grid item xs={3}>
                            <KeyValueSection />
                          </Grid>
                        )}
                    </Grid>
                    <Grid container spacing={4}>
                      {(row?.binRange || row?.cardType || row?.odometer || row.engineHours) &&
                        row.type.toLocaleLowerCase() === TransactionType.COMPLETION && (
                          <Grid item xs={3}>
                            <KeyValueSection />
                          </Grid>
                        )}
                    </Grid>
                  </>
                )}
              </Box>
            </Collapse>
          </TableCell>

          <TableCell className={`nested-table table-view ${rowNumber % 2 === 0 ? 'even-row' : 'odd-row'}`} colSpan={12}>
            <Collapse in={open} unmountOnExit>
              <Box>
                {row?.pumps?.length > 0 && (
                  <Grid container>
                    <Grid
                      item
                      xs={
                        row.type.toLocaleLowerCase() === TransactionType.COMPLETION
                          ? 9
                          : row.type.toLocaleLowerCase() === TransactionType.AUTHORISATION
                            ? 3
                            : 2
                      }
                    >
                      <PumpInfoTableSection />
                    </Grid>
                  </Grid>
                )}
                {(row?.binRange || row?.cardType || row?.tags) && (
                  <Grid container>
                    <Grid item xs={cardSectionGridUsed}>
                      <CardInfoKeyValueSection />
                    </Grid>
                  </Grid>
                )}
                {(row?.odometer || row.engineHours) && (
                  <Grid container>
                    <Grid item xs={cardSectionGridUsed}>
                      <CardDetailKeyValueSection />
                    </Grid>
                  </Grid>
                )}
                {keyValueList?.length > 0 && (
                  <Grid container>
                    <Grid item xs={4}>
                      <KeyValueSection />
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  const PumpInfoTableSection = () => {
    return (
      <Table className='pump-info-table'>
        <TableHead>
          {row.type.toLocaleLowerCase() === TransactionType.COMPLETION ? (
            <TableRow>
              <TableCell>Pump</TableCell>
              <TableCell>Hose</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Tank</TableCell>
              <TableCell align='right'>Amount</TableCell>
              <TableCell align='right'>Quantity</TableCell>
              <TableCell align='right'>Unit Price</TableCell>
            </TableRow>
          ) : row.type.toLocaleLowerCase() === TransactionType.AUTHORISATION ? (
            <TableRow>
              <TableCell>Pump</TableCell>
              <TableCell align='right'>Amount</TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell>Pump</TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {row.type.toLocaleLowerCase() === TransactionType.COMPLETION
            ? row.pumps
                ?.slice()
                ?.sort((a, b) => a.number - b.number)
                ?.map((pump) => {
                  return pump.products?.map((product) => (
                    <TableRow
                      className={row.pumps && row.pumps?.length > 1 ? 'multiple-pump-row' : ''}
                      key={product.id}
                    >
                      <TableCell component='th' scope='row'>
                        {pump.number}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {product.hoseNumber}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {product.name}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {product.tank.number}
                      </TableCell>
                      <TableCell component='th' scope='row' align='right'>
                        {row.amountValue < 0 ? '-' : ''}
                        {generalHelper.getCurrencySign(row.amountCurrencyCode)}
                        {generalHelper.numberHandlerWithDP(Math.abs(product.amount), 2)}
                      </TableCell>
                      <TableCell component='th' scope='row' align='right'>
                        {generalHelper.numberHandlerWithDP(product.quantity, 2)}{' '}
                        {generalHelper.getMeasureSign(product.unitOfMeasure)}
                      </TableCell>
                      <TableCell component='th' scope='row' align='right'>
                        {generalHelper.amountHandlerWithCurrency(product.unitPrice, row.amountCurrencyCode, 3)}
                      </TableCell>
                    </TableRow>
                  ));
                })
            : row.type.toLocaleLowerCase() === TransactionType.AUTHORISATION
              ? row.pumps
                  ?.slice()
                  ?.sort((a, b) => a.number - b.number)
                  ?.map((pump) => (
                    <TableRow key={pump.id}>
                      <TableCell component='th' scope='row'>
                        {pump.number}
                      </TableCell>
                      <TableCell component='th' scope='row' align='right'>
                        {row.amountValue < 0 ? '-' : ''}
                        {pump?.amount ? generalHelper.getCurrencySign(row.amountCurrencyCode) : ''}
                        {pump?.amount ? generalHelper.numberHandlerWithDP(Math.abs(pump?.amount), 2) : ''}
                      </TableCell>
                    </TableRow>
                  ))
              : row.pumps.map((pump) => (
                  <TableRow key={pump.id}>
                    <TableCell component='th' scope='row'>
                      {pump.number}
                    </TableCell>
                  </TableRow>
                ))}
        </TableBody>
      </Table>
    );
  };

  const KeyValueSection = () => {
    return (
      <Card className='key-value-table' sx={{ minWidth: 275 }} variant='outlined'>
        <CardContent>
          {keyValueList?.map((it) => {
            return (
              <List className='key-value-list' key={it.key}>
                <Typography align='left' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                  {it.key === 'Correlation Id' ? '' : it.key}
                </Typography>

                {it.key === 'Receipt' ? (
                  <CustomButton
                    className='view-receipt-btn'
                    buttonStyle={ButtonStyle.REVIEW}
                    onClick={onViewReceiptHandler}
                  >
                    {'View Receipt'}
                  </CustomButton>
                ) : (
                  <Typography align='right' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                    {it.value}
                  </Typography>
                )}
              </List>
            );
          })}
        </CardContent>
      </Card>
    );
  };

  const CardInfoKeyValueSection = () => {
    return (
      <Card className='key-value-table' sx={{ minWidth: 275 }} variant='outlined'>
        <CardContent>
          {cardInfoKeyValueList?.map((it) => {
            return (
              <List className='key-value-list' key={it.key}>
                <Typography align='left' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                  {it.key}
                </Typography>

                {it.key === 'Receipt' ? (
                  <CustomButton
                    className='view-receipt-btn'
                    buttonStyle={ButtonStyle.REVIEW}
                    onClick={onViewReceiptHandler}
                  >
                    {'View Receipt'}
                  </CustomButton>
                ) : (
                  <Typography align='right' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                    {it.value}
                  </Typography>
                )}
              </List>
            );
          })}
        </CardContent>
      </Card>
    );
  };

  const CardDetailKeyValueSection = () => {
    return (
      <Card className='key-value-table' sx={{ minWidth: 275 }} variant='outlined'>
        <CardContent>
          {cardDetailKeyValueList?.map((it) => {
            return (
              <List className='key-value-list' key={it.key}>
                <Typography align='left' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                  {it.key}
                </Typography>

                {it.key === 'Receipt' ? (
                  <CustomButton
                    className='view-receipt-btn'
                    buttonStyle={ButtonStyle.REVIEW}
                    onClick={onViewReceiptHandler}
                  >
                    {'View Receipt'}
                  </CustomButton>
                ) : (
                  <Typography align='right' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                    {it.value}
                  </Typography>
                )}
              </List>
            );
          })}
        </CardContent>
      </Card>
    );
  };

  return <Row />;
};
