import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Divider } from '@mui/material';
import classNames from 'classnames';
import { LoadingStatus } from '../../constants/loading-constants';
import LoadingDisplay from '../loading-spinner/loading-display';
import './dialog-box.scss';
import CustomButton from '../button/custom-button';
import { ButtonStyle } from '../../constants/button-constants';
import CustomTextField from '../text-field/text-field.container';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import { InfoIcon, WarningIcon } from '../icons';

interface DialogBoxProps {
  dialogBoxActionState: string;
  isVisible: boolean;
  header?: string;
  context: string;
  closeTextButton: string;
  confirmTextButton: string;
  className?: string;
  deletionVerificationName?: string;
  deletionVerificationValue?: string;
  deletionSideEffects?: string[];
  infoDialog?: boolean;
  warningDialog?: boolean;
  onClose: () => void;
  confirmDialog: () => void;
}

const DialogBox: React.FC<DialogBoxProps> = (props: DialogBoxProps) => {
  const {
    dialogBoxActionState,
    isVisible,
    header,
    context,
    closeTextButton,
    confirmTextButton,
    className,
    deletionVerificationName,
    deletionVerificationValue,
    deletionSideEffects,
    infoDialog,
    warningDialog,
    onClose,
    confirmDialog,
  } = props;

  const isSubmitted = dialogBoxActionState === LoadingStatus.SUBMITTED;

  const [dialogContext, setDialogContext] = useState('');
  const [deleteVerification, setDeleteVerification] = useState('');
  const [deletionSideEffectText, setDeletionSideEffects] = useState('');

  useEffect(() => {
    if (!isSubmitted) {
      setDialogContext(context);
    } else {
      setDialogContext('');
    }
  }, [context, isSubmitted]);

  useEffect(() => {
    if (deletionSideEffects) {
      const formattedString =
        deletionSideEffects.length > 1
          ? `${deletionSideEffects.slice(0, -1).join(', ')}, and ${deletionSideEffects[deletionSideEffects.length - 1]}`
          : deletionSideEffects[0];
      setDeletionSideEffects(formattedString);
    }
  }, [deletionSideEffects]);

  const onTextChangeHandler = (newvalue: KeyValuePair) => {
    setDeleteVerification(String(newvalue.value));
  };

  const onCloseHandler = (event: any, reason: string) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      onClose();
    }
  };

  const onButtonCloseHandler = () => {
    onClose();
    setDeleteVerification('');
  };

  const onClickHandler = () => {
    if (deletionVerificationValue) {
      if (deletionVerificationValue === deleteVerification) {
        confirmDialog();
        setDeleteVerification('');
      } else return;
    } else {
      confirmDialog();
    }
  };

  return (
    <Dialog
      className='dialog-container'
      open={isVisible}
      onClose={onCloseHandler}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      {header && (
        <React.Fragment>
          <DialogTitle id='alert-dialog-title' className='dialogbg'>
            {header}
          </DialogTitle>
          <Divider />
        </React.Fragment>
      )}

      {!deletionVerificationName && (
        <DialogContent className={classNames('dialog-body', 'loading-dialog')}>
          {infoDialog && <InfoIcon className='info-icon' />}
          {warningDialog && <WarningIcon className='warning-icon' />}
          <DialogContentText
            id='alert-dialog-description'
            className={classNames(
              'dialog-context',
              'dialog-text',
              infoDialog || warningDialog ? 'info-warning-text' : '',
              className
            )}
          >
            {dialogContext}
          </DialogContentText>
          {isSubmitted && <LoadingDisplay />}
        </DialogContent>
      )}

      {deletionVerificationName && (
        <DialogContent className={classNames('dialog-body', 'confirm-delete-dialog')}>
          <>
            <DialogContentText
              id='alert-dialog-description'
              className={classNames('dialog-context', 'delete-confirm-text', className)}
            >
              Are you sure you want to delete <strong>{deletionVerificationValue}</strong>?
            </DialogContentText>

            <Alert className='delete-warning' variant='outlined' severity='warning'>
              <AlertTitle>Warning</AlertTitle>
              {`Deleting ${deletionVerificationName} will also delete everything associated with it, including but not limited to `}
              <strong>{deletionSideEffectText}</strong>
              {`. This action cannot be undone.`}
            </Alert>

            <div className='delete-verification-label'>{`Enter the ${deletionVerificationName} name to confirm deletion.`}</div>

            <CustomTextField
              className='delete-verification-field'
              key='deleteVerification'
              onBindingValue={onTextChangeHandler}
              placeholder={`Enter ${deletionVerificationName} name`}
              name={`${deletionVerificationName}`}
              value={deleteVerification}
              type='input'
            />
          </>
          {isSubmitted && <LoadingDisplay />}
        </DialogContent>
      )}

      <DialogActions className='dialog-actions'>
        <CustomButton
          className='dialog-button'
          buttonStyle={ButtonStyle.SECONDARY}
          onClick={onButtonCloseHandler}
          isDisabled={isSubmitted}
        >
          {closeTextButton}
        </CustomButton>
        <CustomButton
          className='dialog-button'
          buttonStyle={ButtonStyle.PRIMARY}
          onClick={onClickHandler}
          isDisabled={
            deletionVerificationValue ? isSubmitted || !(deleteVerification === deletionVerificationValue) : isSubmitted
          }
        >
          {confirmTextButton}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
