import { initialState } from './tankInitState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import { TankStatusHistoryModel } from '../../models/tankHistory';

const tankSlice = createSlice({
  name: 'tankState',
  initialState,
  reducers: {
    setTankHistoryStatus: (state, action: PayloadAction<string>) => {
      state.tankHistoryStatus = action.payload;
      state.hasError = false;
    },
    setTankHistoryData: (state, action: PayloadAction<TankStatusHistoryModel[]>) => {
      state.tankHisotoryData = action.payload;
      state.hasError = false;
    },
    setTankHistoryError: (state) => {
      state.hasError = true;
      state.tankHisotoryData = [];
      state.tankHistoryStatus = LoadingStatus.ERROR;
    },
  },
});

export const { setTankHistoryStatus, setTankHistoryData, setTankHistoryError } = tankSlice.actions;
export default tankSlice.reducer;
