import { initialState } from './priceSignInitialState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import { PriceSignModel, PriceSignRecordModel } from '../../models/priceSignModel';

const PriceSignState = createSlice({
  name: 'priceSigntate',
  initialState,
  reducers: {
    setPriceSignStatus: (state, action: PayloadAction<string>) => {
      state.hasError = false;
      state.priceSignStatus = action.payload;
    },
    setPriceSignDetailStatus: (state, action: PayloadAction<string>) => {
      state.hasError = false;
      state.priceSignDetailStatus = action.payload;
    },
    setPriceSignContent: (state, action: PayloadAction<PriceSignRecordModel[]>) => {
      state.hasError = false;
      state.priceSignContent = action.payload;
    },
    setPriceSignItem: (state, action: PayloadAction<PriceSignModel>) => {
      state.hasError = false;
      state.priceSignItem = action.payload;
    },
    setPriceSignError: (state) => {
      state.hasError = true;
      state.priceSignContent = [];
      state.priceSignStatus = LoadingStatus.ERROR;
    },
    setPriceSignDetailError: (state) => {
      state.hasError = true;
      state.priceSignItem = {} as PriceSignModel;
      state.priceSignDetailStatus = LoadingStatus.ERROR;
    },
  },
});

export const {
  setPriceSignStatus,
  setPriceSignDetailStatus,
  setPriceSignContent,
  setPriceSignItem,
  setPriceSignError,
  setPriceSignDetailError,
} = PriceSignState.actions;

export default PriceSignState.reducer;
