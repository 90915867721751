import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { LoadingStatus } from '../../constants/loading-constants';
import { selectOrganisationId } from '../auth/selectors';
import { setGenericErrorData } from '../generic-error/reducers';
import { getGenericErrorMessage } from '../../utilities/errorhandler';
import { getMeasureSign } from '../../utilities/general-helper';
import { clearAllFieldValidation } from '../fieldValidation/reducers';
import { TankDeliveryResponse } from '../../entities/tankDelivery';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { TankDeliveryModel } from '../../models/tankDeliveryModel';
import { setTankDeliveryData, setTankDeliveryError, setTankDeliveryStatus } from './reducers';
import * as actions from './actions';
import * as services from './services';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_TANK_DELIVERIES, loadTankDeliveries);
}

export function* loadTankDeliveries(action: PayloadAction<string>) {
  try {
    if (!!action.payload) {
      yield put(setTankDeliveryStatus(LoadingStatus.LOADING));
      const organisationId: string = yield select(selectOrganisationId);
      let response: TankDeliveryResponse = yield call(services.getTankDeliveries, action.payload, organisationId);
      let data: TankDeliveryModel[] = yield call(mapTankDeliveryEntityToTankDeliveryModel, response);
      yield put(setTankDeliveryData(data));
      yield put(setTankDeliveryStatus(LoadingStatus.SUCCESS));
    }
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setTankDeliveryError());
    yield put(clearAllFieldValidation());
  }
}

const mapTankDeliveryEntityToTankDeliveryModel = (response: TankDeliveryResponse) => {
  if (response && response.items.length > 0) {
    const result: TankDeliveryModel[] = response.items.map((tank, i) => {
      return {
        id: tank.id,
        organisationId: tank.organisationId,
        siteId: tank.siteId,
        tankId: tank.tankId,
        start: tank.start,
        end: tank.end,

        productVolumeDifference:
          tank.end?.product?.volume?.value - tank.start?.product?.volume?.value > 0
            ? `+ ${
                Math.round((tank.end?.product?.volume?.value - tank.start?.product?.volume?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.product?.volume?.unitOfMeasure)}`
            : `${
                Math.round((tank.end?.product?.volume?.value - tank.start?.product?.volume?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.product?.volume?.unitOfMeasure)}`,

        productTCVolumeDifference:
          tank.end?.product?.volume?.temperatureCompensatedValue -
            tank.start?.product?.volume?.temperatureCompensatedValue >
          0
            ? `+ ${
                Math.round(
                  (tank.end?.product?.volume?.temperatureCompensatedValue -
                    tank.start?.product?.volume?.temperatureCompensatedValue) *
                    100
                ) / 100
              } ${getMeasureSign(tank.start?.product?.volume?.unitOfMeasure)}`
            : `${
                Math.round(
                  (tank.end?.product?.volume?.temperatureCompensatedValue -
                    tank.start?.product?.volume?.temperatureCompensatedValue) *
                    100
                ) / 100
              } ${getMeasureSign(tank.start?.product?.volume?.unitOfMeasure)}`,

        productHeightDifference:
          tank.end?.product?.height?.value - tank.start?.product?.height?.value > 0
            ? `+ ${
                Math.round((tank.end?.product?.height?.value - tank.start?.product?.height?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.product?.height?.unitOfMeasure)}`
            : `${
                Math.round((tank.end?.product?.height?.value - tank.start?.product?.height?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.product?.height?.unitOfMeasure)}`,

        waterVolumeDifference:
          tank.end?.water?.volume?.value - tank.start?.water?.volume?.value > 0
            ? `+ ${
                Math.round((tank.end?.water?.volume?.value - tank.start?.water?.volume?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.water?.volume?.unitOfMeasure)}`
            : `${
                Math.round((tank.end?.water?.volume?.value - tank.start?.water?.volume?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.water?.volume?.unitOfMeasure)}`,

        waterTCVolumeDifference:
          tank.end?.water?.volume?.temperatureCompensatedValue -
            tank.start?.water?.volume?.temperatureCompensatedValue >
          0
            ? `+ ${
                Math.round(
                  (tank.end?.water?.volume?.temperatureCompensatedValue -
                    tank.start?.water?.volume?.temperatureCompensatedValue) *
                    100
                ) / 100
              } ${getMeasureSign(tank.start?.water?.volume?.unitOfMeasure)}`
            : `${
                Math.round(
                  (tank.end?.water?.volume?.temperatureCompensatedValue -
                    tank.start?.water?.volume?.temperatureCompensatedValue) *
                    100
                ) / 100
              } ${getMeasureSign(tank.start?.water?.volume?.unitOfMeasure)}`,

        waterHeightDifference:
          tank.end?.water?.height?.value - tank.start?.water?.height?.value > 0
            ? `+ ${
                Math.round((tank.end?.water?.height?.value - tank.start?.water?.height?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.water?.height?.unitOfMeasure)}`
            : `${
                Math.round((tank.end?.water?.height?.value - tank.start?.water?.height?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.water?.height?.unitOfMeasure)}`,

        temperatureDifference:
          tank.end?.temperature?.value - tank.start?.temperature?.value > 0
            ? `+ ${
                Math.round((tank.end?.temperature?.value - tank.start?.temperature?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.temperature?.unitOfMeasure)}`
            : `${
                Math.round((tank.end?.temperature?.value - tank.start?.temperature?.value) * 100) / 100
              } ${getMeasureSign(tank.start?.temperature?.unitOfMeasure)}`,
      } as TankDeliveryModel;
    });

    return result;
  }
  return [] as TankDeliveryModel[];
};
