import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './reportInitState';
import { LoadingStatus } from '../../constants/loading-constants';
import { ReportModel, TransactionReportColumn } from '../../models/reportModel';
import { TransactionFilterModel } from '../../models/transactionModel';

const reportSlice = createSlice({
  name: 'reportState',
  initialState,
  reducers: {
    setReportState: (state, action: PayloadAction<string>) => {
      state.reportStatus = action.payload;
      state.hasError = false;
    },
    setExportColumnsStatus: (state, action: PayloadAction<string>) => {
      state.exportColumnsStatus = action.payload;
      state.hasError = false;
    },
    setReportData: (state, action: PayloadAction<ReportModel[]>) => {
      state.reportData = action.payload;
      state.hasError = false;
    },
    setExportColumnsData: (state, action: PayloadAction<string[]>) => {
      state.exportColumnsData = action.payload;
      state.hasError = false;
    },
    setReportError: (state) => {
      state.hasError = true;
      state.reportData = [];
      state.reportStatus = LoadingStatus.ERROR;
    },
    setExportColumnsError: (state) => {
      state.hasError = true;
      state.reportData = [];
      state.exportColumnsStatus = LoadingStatus.ERROR;
    },
    setExportFilter: (state, action: PayloadAction<TransactionFilterModel>) => {
      state.exportFilter = action.payload;
      state.hasError = false;
    },
    setSelectedColumns: (state, action: PayloadAction<TransactionReportColumn[]>) => {
      state.exportSelectedColumns = action.payload;
    },
  },
});

export const {
  setReportState,
  setExportColumnsStatus,
  setReportData,
  setExportColumnsData,
  setReportError,
  setExportColumnsError,
  setExportFilter,
  setSelectedColumns,
} = reportSlice.actions;
export default reportSlice.reducer;
