import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import { selectPriceSignContent, selectPriceSignStatus } from '../../store/price-signs/selectors';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import * as headerActions from '../../store/header/actions';
import * as actions from '../../store/price-signs/actions';
import * as dialogActions from '../../store/dialog-box/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import PriceSigns from './price-signs';

const mapStateToProps = (state: RootState) => {
  return {
    priceSignStatus: selectPriceSignStatus(state),
    priceSignContent: selectPriceSignContent(state),
    authStatus: selectAuthStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadPriceSigns: () => dispatch({ type: actions.LOAD_PRICESIGNS }),
    deletePriceSign: (data: string) => dispatch({ type: actions.DELETE_PRICESIGN, payload: data }),
    cancelPriceSignReload: () => dispatch({ type: actions.CANCEL_PRICESIGN_RELOAD }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
  };
};

const PriceSignList = connect(mapStateToProps, mapDispatchToProps)(PriceSigns);

export default PriceSignList;
