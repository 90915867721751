import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './cardOperationsInitState';
import { BulkOperationDetailsModel, BulkOperationsModel } from '../../models/cardOperationModel';

const BinRangeState = createSlice({
  name: 'binRangeState',
  initialState,
  reducers: {
    setStorageLocationUrl: (state, action: PayloadAction<string>) => {
      state.storageLocationUrl = action.payload;
    },

    setFileUploadedToStorage: (state, action: PayloadAction<boolean>) => {
      state.fileUploadedToStorage = action.payload;
    },

    setFileUploadState: (state, action: PayloadAction<string>) => {
      state.fileUploadState = action.payload;
    },

    setBulkOperationState: (state, action: PayloadAction<string>) => {
      state.bulkOperationState = action.payload;
    },

    setBulkOperations: (state, action: PayloadAction<BulkOperationsModel>) => {
      state.bulkOperations = action.payload;
    },

    setOperationDetails: (state, action: PayloadAction<BulkOperationDetailsModel>) => {
      state.operationDetails = action.payload;
    },

    setRunningOperationsDetails: (state, action: PayloadAction<BulkOperationDetailsModel[]>) => {
      state.runningOperationsDetails = action.payload;
    },

    setOperationsDetailsList: (state, action: PayloadAction<BulkOperationDetailsModel[]>) => {
      state.operationsDetailsList = action.payload;
    },

    setOperationsDetailsListState: (state, action: PayloadAction<string>) => {
      state.operationsDetailsListState = action.payload;
    },
  },
});

export const {
  setStorageLocationUrl,
  setFileUploadedToStorage,
  setFileUploadState,
  setBulkOperationState,
  setBulkOperations,
  setOperationDetails,
  setRunningOperationsDetails,
  setOperationsDetailsList,
  setOperationsDetailsListState,
} = BinRangeState.actions;

export default BinRangeState.reducer;
