import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import { initialState } from './cardInitState';
import { CardFilterModel, CardModel } from '../../models/cardModel';

const BinRangeState = createSlice({
  name: 'binRangeState',
  initialState,
  reducers: {
    setCardStatus: (state, action: PayloadAction<string>) => {
      state.hasError = false;
      state.cardStatus = action.payload;
    },
    setCardList: (state, action: PayloadAction<CardModel[]>) => {
      state.hasError = false;
      state.cardList = action.payload;
    },
    setCardFuzzySearch: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.cardFuzzySearch = action.payload;
    },
    setCardError: (state) => {
      state.hasError = true;
      state.cardStatus = LoadingStatus.ERROR;
    },
    setIsScrollRequest: (state, action: PayloadAction<boolean>) => {
      state.hasError = true;
      state.isScrollRequest = action.payload;
    },
    setCardData: (state, action: PayloadAction<CardModel>) => {
      state.hasError = true;
      state.cardData = action.payload;
    },
    setCardFilter: (state, action: PayloadAction<CardFilterModel>) => {
      state.cardFilter = action.payload;
      state.hasError = false;
    },
  },
});

export const {
  setCardStatus,
  setCardList,
  setCardFuzzySearch,
  setCardError,
  setIsScrollRequest,
  setCardData,
  setCardFilter,
} = BinRangeState.actions;

export default BinRangeState.reducer;
