import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import classNames from 'classnames';
import { LoadingStatus } from '../../constants/loading-constants';
import { NavPaneStates } from '../../constants/window-constants';
import { HealthStatusIndicatorDisplayType } from '../../constants/status';
import { ModuleName } from '../../constants/module-constants';
import { Messages } from '../../constants/messages';
import { SiteItemModel } from '../../models/siteModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import EmptyList from '../../components/empty-list/empty-list';
import FooterBar from '../../components/footer/footer';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import SearchCreate from '../../components/search-create/search-create';
import DialogBoxComponent from '../../components/dialog-box/dialog-box.container';
import MessageDisplay from '../../components/message-display/message-display';
import DrawerControl from '../../components/drawer/drawer';
import Card from '../../components/cards/cards';
import Item from '../../components/box-items/box-item';
import CollapsibleList from '../../components/collapsible-list/collapsible-list';
import SiteItem from './site-item';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';

interface SitesProps {
  sitesContent: SiteItemModel[];
  sitesStatus: string;
  selectedSiteId: string;
  navPaneState: string;
  authStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  loadSites: () => void;
  deleteSiteItem: (id: string) => void;
  setSelectedSiteId: (id: string) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  openDialogBox: () => void;
  closeDialogBox: () => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
}

const Sites: React.FC<SitesProps> = (props: SitesProps) => {
  const {
    selectedSiteId,
    sitesContent,
    sitesStatus,
    navPaneState,
    authStatus,
    userAccess,
    loadSites,
    deleteSiteItem,
    setSelectedSiteId,
    setHeaderConfiguration,
    openDialogBox,
    closeDialogBox,
    setPageConfiguration,
    setIsPageDirty,
  } = props;

  const navigate = useNavigate();
  const { orgId } = useParams();
  const isLoadSitesRequested = useRef(false);

  const [siteDataToDisplay, setSiteDataToDisplay] = useState(sitesContent);
  const [emptyListMessage, setEmptyListMessage] = useState('No sites found');
  const [deleteContext, setDeleteContent] = useState('');
  const [deletionVerificationValue, setDeletionVerificationValue] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedSite, setSelectedSite] = useState({} as SiteItemModel);

  const searchInputRef = useRef<HTMLInputElement>(null!);

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);

  const [hasTerminalReadAccess, setHasTerminalReadAccess] = useState(false);
  const [hasPumpReadAccess, setHasPumpReadAccess] = useState(false);
  const [hasTankReadAccess, setHasTankReadAccess] = useState(false);
  const [hasPriceSignReadAccess, setHasPriceSignReadAccess] = useState(false);
  const [hasForecourtControllerReadAccess, setHasForecourtControllerReadAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.SITE).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.SITE).hasCreateAccess);
    setHasDeleteAccess(userAccess(ModuleName.SITE).hasDeleteAccess);
    setHasUpdateAccess(userAccess(ModuleName.SITE).hasUpdateAccess);
    setHasTerminalReadAccess(userAccess(ModuleName.TERMINAL).hasReadAccess);
    setHasPumpReadAccess(userAccess(ModuleName.PUMP).hasReadAccess);
    setHasTankReadAccess(userAccess(ModuleName.TANK).hasReadAccess);
    setHasPriceSignReadAccess(userAccess(ModuleName.PRICE_SIGN).hasReadAccess);
    setHasForecourtControllerReadAccess(userAccess(ModuleName.FORECOURT_CONTROLLER).hasReadAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(sitesStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(sitesStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(sitesStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, sitesStatus]);

  useEffect(() => {
    setHeaderConfiguration({
      showOrganisation: true,
      title: 'Sites',
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, authSuccess, hasCreateAccess, error]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  useEffect(() => {
    if (!isLoadSitesRequested.current && authSuccess && hasReadAccess) {
      loadSites();
    }
    isLoadSitesRequested.current = true;
  }, [authSuccess, hasReadAccess, loadSites]);

  useEffect(() => {
    setSiteDataToDisplay(sitesContent);
  }, [sitesContent]);

  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    let enteredSearchValue = searchInputRef.current?.value.trim();

    if (enteredSearchValue?.length > 0) {
      const siteListFiltered: SiteItemModel[] = sitesContent.filter((x) =>
        x.name.toLowerCase().includes(enteredSearchValue.toLowerCase())
      );
      setSiteDataToDisplay(siteListFiltered);
      setEmptyListMessage('No results found');
    } else {
      setSiteDataToDisplay(sitesContent);
      setEmptyListMessage('No sites found');
    }
    window.scrollTo(0, 0);
  };

  /** DIALOG BUTTONS */
  const confirmDialog = () => {
    deleteSiteItem(selectedSiteId);
  };

  const cancelDialog = () => {
    closeDialogBox();
  };

  /** ACTION MENU (Create, Edit, Delete) */
  const onCreateHandler = () => {
    navigate(`/organisations/${orgId}/sites/create`);
  };

  const onDeleteHandler = useCallback(
    (id: string, name: string) => {
      setSelectedSiteId(id);
      setDeleteContent(`Are you sure you want to delete ${name}?`);
      setDeletionVerificationValue(name);
      openDialogBox();
    },
    [openDialogBox, setDeleteContent, setSelectedSiteId]
  );

  const onEditHandler = (id: string) => {
    navigate(`/organisations/${orgId}/sites/${id}/details/edit`);
  };

  const onClickHandler = (id: string) => {
    setSelectedSiteId(id);
    navigate(`/organisations/${orgId}/sites/${id}/details`);
  };

  const onStatusClickHander = (id: string) => {
    if (id === selectedSiteId) {
      setDrawerOpen(!drawerOpen);
    } else setDrawerOpen(true);

    setSelectedSiteId(id);
    setSelectedSite(sitesContent?.find((it) => it.id === id)!);
  };

  const drawerOpenHandler = (open: boolean) => {
    setDrawerOpen(open);
    if (open === false) {
      setSelectedSite({} as SiteItemModel);
    }
  };

  const siteList =
    sitesContent &&
    siteDataToDisplay &&
    siteDataToDisplay.map((site) => (
      <SiteItem
        onClickHandler={onClickHandler}
        onDelete={onDeleteHandler}
        onEdit={onEditHandler}
        key={site.id}
        siteItem={site}
        path={`/organisations/${orgId}/sites/${site.id}/details`}
        hasDeleteAccess={hasDeleteAccess}
        hasUpdateAccess={hasUpdateAccess}
        onStatusClickHander={onStatusClickHander}
        drawerOpen={drawerOpen}
        selectedSiteId={selectedSite?.id}
      ></SiteItem>
    ));

  const getLevelsFromObjects = (statusArray: any[]): string[] => {
    const levels: string[] = [];
    statusArray?.forEach((obj) => {
      if (obj?.messages && obj?.messages?.length > 0) {
        obj?.messages.forEach((message: any) => {
          if (message?.level) {
            levels.push(message?.level);
          }
        });
      }
    });
    return levels;
  };

  const entityHealthStatusIndicator = (data: any) => {
    if (data) {
      let healthStatus: string = '';
      const levels = getLevelsFromObjects(data);
      if (levels?.includes('error')) healthStatus = 'status-unhealthy-icon';
      else if (levels?.includes('warning')) healthStatus = 'status-warning-icon';
      else healthStatus = 'status-healthy-icon';
      return healthStatus;
    }
    return '';
  };

  const siteStatusDetails = (
    <Card className='site-status-details-container'>
      <Box className='status-details-header'>
        <Item className='site-name-header'>{sitesContent?.find((it) => it.id === selectedSiteId)?.name}</Item>
        <Item className='last-update-time'>
          Last Update: {sitesContent?.find((it) => it.id === selectedSiteId)?.lastUpdatedDateTimeUtc}
        </Item>
      </Box>
      <Box className='status-details-container'>
        {selectedSite?.terminalStatus !== undefined &&
          selectedSite?.terminalStatus?.length > 0 &&
          hasTerminalReadAccess && (
            <CollapsibleList
              key='terminal'
              label='Terminals'
              expanded={selectedSite?.terminalStatus !== undefined && selectedSite?.terminalStatus?.length > 0}
              disabledExpand={
                selectedSite?.terminalStatus?.flatMap((item) => item.messages)?.filter((it) => it !== undefined)
                  ?.length <= 0
              }
              hideCollapseArrow={
                selectedSite?.terminalStatus?.flatMap((item) => item.messages)?.filter((it) => it !== undefined)
                  ?.length <= 0
              }
              titleButtonOption={
                <>
                  <Item
                    className={classNames('box-health-icon', entityHealthStatusIndicator(selectedSite?.terminalStatus))}
                  ></Item>
                  <span className='box-child'>
                    &nbsp;
                    {fieldMappingHelper.getDisplayValue(
                      entityHealthStatusIndicator(selectedSite?.terminalStatus),
                      HealthStatusIndicatorDisplayType
                    )}
                  </span>
                </>
              }
              children={
                selectedSite?.terminalStatus &&
                selectedSite?.terminalStatus?.length > 0 && (
                  <>
                    {selectedSite?.terminalStatus?.map(
                      (terminal, i) =>
                        terminal?.messages &&
                        terminal?.messages?.length > 0 && (
                          <Link
                            to={`/organisations/${orgId}/sites/${selectedSite.id}/terminals`}
                            className='go-to-detail-page-link'
                          >
                            <Box
                              className={classNames('status-item-container', {
                                'last-status-item-container': i === (selectedSite?.terminalStatus?.length ?? 0) - 1,
                              })}
                            >
                              <Item className='status-header'>Terminal {terminal.number}</Item>
                              {terminal?.messages
                                ?.slice()
                                ?.sort((a, b) => {
                                  const levelOrder = ['error', 'warning'];
                                  return levelOrder.indexOf(a.level) - levelOrder.indexOf(b.level);
                                })
                                ?.map((it) => (
                                  <Item className='status-message'>
                                    {it?.level === 'warning' ? (
                                      <ErrorOutlineIcon className='row-view-warning-icon' />
                                    ) : it?.level === 'error' ? (
                                      <ErrorOutlineIcon className='row-view-error-icon' />
                                    ) : (
                                      ''
                                    )}
                                    <span>{it?.text}</span>
                                  </Item>
                                ))}
                            </Box>
                          </Link>
                        )
                    )}
                  </>
                )
              }
            ></CollapsibleList>
          )}

        {selectedSite?.pumpStatus !== undefined && selectedSite?.pumpStatus?.length > 0 && hasPumpReadAccess && (
          <CollapsibleList
            key='pump'
            label='Pumps'
            expanded={selectedSite?.pumpStatus !== undefined && selectedSite?.pumpStatus?.length > 0}
            disabledExpand={
              selectedSite?.pumpStatus?.flatMap((item) => item.messages)?.filter((it) => it !== undefined)?.length <= 0
            }
            hideCollapseArrow={
              selectedSite?.pumpStatus?.flatMap((item) => item.messages)?.filter((it) => it !== undefined)?.length <= 0
            }
            titleButtonOption={
              <>
                <Item
                  className={classNames('box-health-icon', entityHealthStatusIndicator(selectedSite?.pumpStatus))}
                ></Item>
                <span className='box-child'>
                  &nbsp;
                  {fieldMappingHelper.getDisplayValue(
                    entityHealthStatusIndicator(selectedSite?.pumpStatus),
                    HealthStatusIndicatorDisplayType
                  )}
                </span>
              </>
            }
            children={
              selectedSite?.pumpStatus &&
              selectedSite?.pumpStatus?.length > 0 && (
                <>
                  {selectedSite?.pumpStatus?.map(
                    (pump, i) =>
                      pump?.messages &&
                      pump?.messages?.length > 0 && (
                        <Link
                          to={`/organisations/${orgId}/sites/${selectedSite.id}/pumps`}
                          className='go-to-detail-page-link'
                        >
                          <Box
                            className={classNames('status-item-container', {
                              'last-status-item-container': i === (selectedSite?.pumpStatus?.length ?? 0) - 1,
                            })}
                          >
                            <Item className='status-header'>Pump {pump.number}</Item>
                            {pump?.messages
                              ?.slice()
                              ?.sort((a, b) => {
                                const levelOrder = ['error', 'warning'];
                                return levelOrder.indexOf(a.level) - levelOrder.indexOf(b.level);
                              })
                              ?.map((it) => (
                                <Item className='status-message'>
                                  {it?.level === 'warning' ? (
                                    <ErrorOutlineIcon className='row-view-warning-icon' />
                                  ) : it?.level === 'error' ? (
                                    <ErrorOutlineIcon className='row-view-error-icon' />
                                  ) : (
                                    ''
                                  )}
                                  <span>{it?.text}</span>
                                </Item>
                              ))}
                          </Box>
                        </Link>
                      )
                  )}
                </>
              )
            }
          ></CollapsibleList>
        )}

        {selectedSite?.tankStatus !== undefined && selectedSite?.tankStatus?.length > 0 && hasTankReadAccess && (
          <CollapsibleList
            key='tank'
            label='Tanks'
            expanded={selectedSite?.tankStatus !== undefined && selectedSite?.tankStatus?.length > 0}
            disabledExpand={
              selectedSite?.tankStatus?.flatMap((item) => item.messages)?.filter((it) => it !== undefined)?.length <= 0
            }
            hideCollapseArrow={
              selectedSite?.tankStatus?.flatMap((item) => item.messages)?.filter((it) => it !== undefined)?.length <= 0
            }
            titleButtonOption={
              <>
                <Item
                  className={classNames('box-health-icon', entityHealthStatusIndicator(selectedSite?.tankStatus))}
                ></Item>
                <span className='box-child'>
                  &nbsp;
                  {fieldMappingHelper.getDisplayValue(
                    entityHealthStatusIndicator(selectedSite?.tankStatus),
                    HealthStatusIndicatorDisplayType
                  )}
                </span>
              </>
            }
            children={
              selectedSite?.tankStatus &&
              selectedSite?.tankStatus?.length > 0 && (
                <>
                  {selectedSite?.tankStatus?.map(
                    (tank, i) =>
                      tank?.messages &&
                      tank?.messages?.length > 0 && (
                        <Link
                          to={`/organisations/${orgId}/sites/${selectedSite.id}/tanks`}
                          className='go-to-detail-page-link'
                        >
                          <Box
                            className={classNames('status-item-container', {
                              'last-status-item-container': i === (selectedSite?.tankStatus?.length ?? 0) - 1,
                            })}
                          >
                            <Item className='status-header'>Tank {tank.number}</Item>
                            {tank?.messages
                              ?.slice()
                              ?.sort((a, b) => {
                                const levelOrder = ['error', 'warning'];
                                return levelOrder.indexOf(a.level) - levelOrder.indexOf(b.level);
                              })
                              ?.map((it) => (
                                <Item className='status-message'>
                                  {it?.level === 'warning' ? (
                                    <ErrorOutlineIcon className='row-view-warning-icon' />
                                  ) : it?.level === 'error' ? (
                                    <ErrorOutlineIcon className='row-view-error-icon' />
                                  ) : (
                                    ''
                                  )}
                                  <span>{it?.text}</span>
                                </Item>
                              ))}
                          </Box>
                        </Link>
                      )
                  )}
                </>
              )
            }
          ></CollapsibleList>
        )}

        {selectedSite?.priceSignStatus !== undefined &&
          selectedSite?.priceSignStatus?.length > 0 &&
          hasPriceSignReadAccess && (
            <CollapsibleList
              key='priceSign'
              label='Price Signs'
              expanded={selectedSite?.priceSignStatus !== undefined && selectedSite?.priceSignStatus?.length > 0}
              disabledExpand={
                selectedSite?.priceSignStatus?.flatMap((item) => item.messages)?.filter((it) => it !== undefined)
                  ?.length <= 0
              }
              hideCollapseArrow={
                selectedSite?.priceSignStatus?.flatMap((item) => item.messages)?.filter((it) => it !== undefined)
                  ?.length <= 0
              }
              titleButtonOption={
                <>
                  <Item
                    className={classNames(
                      'box-health-icon',
                      entityHealthStatusIndicator(selectedSite?.priceSignStatus)
                    )}
                  ></Item>
                  <span className='box-child'>
                    &nbsp;
                    {fieldMappingHelper.getDisplayValue(
                      entityHealthStatusIndicator(selectedSite?.priceSignStatus),
                      HealthStatusIndicatorDisplayType
                    )}
                  </span>
                </>
              }
              children={
                selectedSite?.priceSignStatus &&
                selectedSite?.priceSignStatus?.length > 0 && (
                  <>
                    {selectedSite?.priceSignStatus?.map(
                      (priceSign, i) =>
                        priceSign?.messages &&
                        priceSign?.messages?.length > 0 && (
                          <Link
                            to={`/organisations/${orgId}/sites/${selectedSite.id}/price-signs`}
                            className='go-to-detail-page-link'
                          >
                            <Box
                              className={classNames('status-item-container', {
                                'last-status-item-container': i === (selectedSite?.priceSignStatus?.length ?? 0) - 1,
                              })}
                            >
                              <Item className='status-header'>Price Sign {priceSign.number}</Item>
                              {priceSign?.messages
                                ?.slice()
                                ?.sort((a, b) => {
                                  const levelOrder = ['error', 'warning'];
                                  return levelOrder.indexOf(a.level) - levelOrder.indexOf(b.level);
                                })
                                ?.map((it) => (
                                  <Item className='status-message'>
                                    {it?.level === 'warning' ? (
                                      <ErrorOutlineIcon className='row-view-warning-icon' />
                                    ) : it?.level === 'error' ? (
                                      <ErrorOutlineIcon className='row-view-error-icon' />
                                    ) : (
                                      ''
                                    )}
                                    <span>{it?.text}</span>
                                  </Item>
                                ))}
                            </Box>
                          </Link>
                        )
                    )}
                  </>
                )
              }
            ></CollapsibleList>
          )}

        {selectedSite?.forecourtControllerStatus !== undefined &&
          selectedSite?.forecourtControllerStatus?.length > 0 &&
          hasForecourtControllerReadAccess && (
            <CollapsibleList
              key='forecourtController'
              label='Forecourt Controllers'
              expanded={
                selectedSite?.forecourtControllerStatus !== undefined &&
                selectedSite?.forecourtControllerStatus?.length > 0
              }
              disabledExpand={
                selectedSite?.forecourtControllerStatus
                  ?.flatMap((item) => item.messages)
                  ?.filter((it) => it !== undefined)?.length <= 0
              }
              hideCollapseArrow={
                selectedSite?.forecourtControllerStatus
                  ?.flatMap((item) => item.messages)
                  ?.filter((it) => it !== undefined)?.length <= 0
              }
              titleButtonOption={
                <>
                  <Item
                    className={classNames(
                      'box-health-icon',
                      entityHealthStatusIndicator(selectedSite?.forecourtControllerStatus)
                    )}
                  ></Item>
                  <span className='box-child'>
                    &nbsp;
                    {fieldMappingHelper.getDisplayValue(
                      entityHealthStatusIndicator(selectedSite?.forecourtControllerStatus),
                      HealthStatusIndicatorDisplayType
                    )}
                  </span>
                </>
              }
              children={
                selectedSite?.forecourtControllerStatus &&
                selectedSite?.forecourtControllerStatus?.length > 0 && (
                  <>
                    {selectedSite?.forecourtControllerStatus?.map(
                      (forecourtController, i) =>
                        forecourtController?.messages &&
                        forecourtController?.messages?.length > 0 && (
                          <Link
                            to={`/organisations/${orgId}/sites/${selectedSite.id}/forecourt-controllers`}
                            className='go-to-detail-page-link'
                          >
                            <Box
                              className={classNames('status-item-container', {
                                'last-status-item-container':
                                  i === (selectedSite?.forecourtControllerStatus?.length ?? 0) - 1,
                              })}
                            >
                              <Item className='status-header'>Forecourt Controller {forecourtController.number}</Item>
                              {forecourtController?.messages
                                ?.slice()
                                ?.sort((a, b) => {
                                  const levelOrder = ['error', 'warning'];
                                  return levelOrder.indexOf(a.level) - levelOrder.indexOf(b.level);
                                })
                                ?.map((it) => (
                                  <Item className='status-message'>
                                    {it?.level === 'warning' ? (
                                      <ErrorOutlineIcon className='row-view-warning-icon' />
                                    ) : it?.level === 'error' ? (
                                      <ErrorOutlineIcon className='row-view-error-icon' />
                                    ) : (
                                      ''
                                    )}
                                    <span>{it?.text}</span>
                                  </Item>
                                ))}
                            </Box>
                          </Link>
                        )
                    )}
                  </>
                )
              }
            ></CollapsibleList>
          )}
      </Box>
    </Card>
  );

  return (
    <>
      {
        <DialogBoxComponent
          context={deleteContext}
          closeTextButton='Cancel'
          confirmTextButton='Confirm'
          confirmDialog={confirmDialog}
          onClose={cancelDialog}
          header='Delete Site'
          deletionVerificationName='site'
          deletionVerificationValue={deletionVerificationValue}
          deletionSideEffects={['Terminals', 'Pumps', 'Tanks', 'Receipt Templates', 'Transactions']}
        />
      }
      {(!authCheckCompleted || loading) && <LoadingDisplay />}
      {error && <ErrorDisplayControl />}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}
      {success && (
        <div className={classNames('sites')}>
          <div
            className={classNames('search-container', {
              'list-expanded-width': navPaneState === NavPaneStates.EXPANDED,
              'list-collapsed-width':
                navPaneState === NavPaneStates.COLLAPSED || navPaneState === NavPaneStates.COLLAPSED_AND_FLOATING,
              'list-hamburger-width':
                navPaneState === NavPaneStates.HAMBURGER || navPaneState === NavPaneStates.HAMBURGER_AND_FLOATING,
            })}
          >
            <SearchCreate
              placeholder={'Search Keyword'}
              onKeyUpHandler={onKeyUpHandler}
              onCreateHandler={onCreateHandler}
              searchRef={searchInputRef}
              showCreateButton={hasCreateAccess}
            />

            {sitesContent && siteDataToDisplay?.length > 0 && (
              <div className={classNames('site-list-header', { 'site-list-header-with-drawer-open': drawerOpen })}>
                <div className='site-name'>Site Name</div>
                <div></div>
                <div className='site-health'>Status</div>
                {!drawerOpen && <div className='site-heart-beat'>Last Status Update</div>}
              </div>
            )}
          </div>

          {sitesContent && siteDataToDisplay?.length > 0 && (
            <div className={classNames('site-list-container')}>
              <div className='site-list'>{siteList}</div>
            </div>
          )}

          {!!sitesContent && !!siteDataToDisplay && siteDataToDisplay?.length < 1 && (
            <div className='empty-div-container-extra-spacing'>
              <EmptyList message={emptyListMessage}></EmptyList>
            </div>
          )}

          <DrawerControl open={drawerOpen} drawerOpenHandler={drawerOpenHandler} children={siteStatusDetails} />
        </div>
      )}
      {
        <div className='for-mobile'>
          <FooterBar
            className={classNames(
              !!sitesContent && !!siteDataToDisplay && siteDataToDisplay?.length < 1
                ? 'footer-no-content'
                : 'footer-with-content'
            )}
          />
        </div>
      }
    </>
  );
};

export default Sites;
