import { initialState } from './tankInitState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import { TankDeliveryModel } from '../../models/tankDeliveryModel';

const tankSlice = createSlice({
  name: 'tankState',
  initialState,
  reducers: {
    setTankDeliveryStatus: (state, action: PayloadAction<string>) => {
      state.tankDeliveryStatus = action.payload;
      state.hasError = false;
    },
    setTankDeliveryData: (state, action: PayloadAction<TankDeliveryModel[]>) => {
      state.tankDeliveryData = action.payload;
      state.hasError = false;
    },
    setTankDeliveryError: (state) => {
      state.hasError = true;
      state.tankDeliveryData = [];
      state.tankDeliveryStatus = LoadingStatus.ERROR;
    },
  },
});

export const { setTankDeliveryStatus, setTankDeliveryData, setTankDeliveryError } = tankSlice.actions;
export default tankSlice.reducer;
