import classNames from 'classnames';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import Item from '../../components/box-items/box-item';
import Card from '../../components/cards/cards';
import CollapsibleCard from '../../components/collapsible-card/collapsible-card';
import EditableTable from '../../components/editable-table/editable-table.container';
import { TankDetailModel } from '../../models/tankModel';
import { TankDeliveryModel } from '../../models/tankDeliveryModel';
import { getMeasureSign } from '../../utilities/general-helper';
import {
  compareIfDatetimeFromTheSameDate,
  getBrowserLocalTimezone,
  formatDateTimebyTimeZone,
} from '../../utilities/datetime-helper';
import './styles/tank-delivery-item.scss';
interface TankDeliveryItemProps {
  tankDeliveryItem: TankDeliveryModel;
}

const TankDeliveryItem: React.FC<TankDeliveryItemProps> = (props: TankDeliveryItemProps) => {
  const { tankDeliveryItem } = props;
  const timezone = getBrowserLocalTimezone();
  const [fieldsArr, setFieldsArr] = useState([
    {
      label: 'Details',
      name: 'details',
    },
    {
      label: 'Start',
      name: 'start',
    },
    {
      label: 'End',
      name: 'end',
    },
    {
      label: 'Difference',
      name: 'difference',
    },
  ]);

  const fixedItemDetails: TankDetailModel[] = [
    {
      name: 'Delivery Time',
      value: compareIfDatetimeFromTheSameDate(tankDeliveryItem.start.dateTimeUtc, tankDeliveryItem.end.dateTimeUtc)
        ? `${formatDateTimebyTimeZone(
            timezone,
            tankDeliveryItem.start.dateTimeUtc,
            'DD/MM/YYYY hh:mm A'
          )} - ${formatDateTimebyTimeZone(timezone, tankDeliveryItem.end.dateTimeUtc, 'hh:mm A')}`
        : `${formatDateTimebyTimeZone(
            timezone,
            tankDeliveryItem.start.dateTimeUtc,
            'DD/MM/YYYY hh:mm A'
          )} - ${formatDateTimebyTimeZone(timezone, tankDeliveryItem.end.dateTimeUtc, 'DD/MM/YYYY hh:mm A')}`,
    },
    {
      name: 'Delivery Volume',
      value:
        tankDeliveryItem.productVolumeDifference === 'NaN '
          ? 'Not Avaliable'
          : tankDeliveryItem.productVolumeDifference,
    },
  ];

  useEffect(() => {
    window.addEventListener('resize', updateTableDiaplayData);
    return () => window.removeEventListener('resize', updateTableDiaplayData);
  }, []);

  const updateTableDiaplayData = () => {
    if (window.innerWidth <= 576) {
      setFieldsArr([
        {
          label: 'Details',
          name: 'details',
        },
        {
          label: 'Difference',
          name: 'difference',
        },
      ]);
    } else {
      setFieldsArr([
        {
          label: 'Details',
          name: 'details',
        },
        {
          label: 'Start',
          name: 'start',
        },
        {
          label: 'End',
          name: 'end',
        },
        {
          label: 'Difference',
          name: 'difference',
        },
      ]);
    }
  };

  const defaultData = [];

  if (tankDeliveryItem?.end?.product?.volume?.value)
    defaultData.push({
      details: 'Product Volume',
      start: `${tankDeliveryItem?.start?.product?.volume?.value} ${getMeasureSign(
        tankDeliveryItem?.start?.product?.volume?.unitOfMeasure
      )}`,
      end: `${tankDeliveryItem?.end?.product?.volume?.value} ${getMeasureSign(
        tankDeliveryItem?.end?.product?.volume?.unitOfMeasure
      )}`,
      difference: tankDeliveryItem?.productVolumeDifference,
    });

  if (tankDeliveryItem?.end?.product?.volume?.temperatureCompensatedValue)
    defaultData.push({
      details: 'Product TC Volume',
      start: `${tankDeliveryItem?.start?.product?.volume?.temperatureCompensatedValue} ${getMeasureSign(
        tankDeliveryItem?.start?.product?.volume?.unitOfMeasure
      )}`,
      end: `${tankDeliveryItem?.end?.product?.volume?.temperatureCompensatedValue} ${getMeasureSign(
        tankDeliveryItem?.end?.product?.volume?.unitOfMeasure
      )}`,
      difference: tankDeliveryItem?.productTCVolumeDifference,
    });

  if (tankDeliveryItem?.end?.product?.height?.value)
    defaultData.push({
      details: 'Product Height',
      start: `${tankDeliveryItem?.start?.product?.height?.value} ${getMeasureSign(
        tankDeliveryItem?.start?.product?.height?.unitOfMeasure
      )}`,
      end: `${tankDeliveryItem?.end?.product?.height?.value} ${getMeasureSign(
        tankDeliveryItem?.end?.product?.height?.unitOfMeasure
      )}`,
      difference: tankDeliveryItem?.productHeightDifference,
    });

  if (tankDeliveryItem?.end?.water?.volume?.value)
    defaultData.push({
      details: 'Water Volume',
      start: `${tankDeliveryItem?.start?.water?.volume?.value} ${getMeasureSign(
        tankDeliveryItem?.start?.water?.volume?.unitOfMeasure
      )}`,
      end: `${tankDeliveryItem?.end?.water?.volume?.value} ${getMeasureSign(
        tankDeliveryItem?.end?.water?.volume?.unitOfMeasure
      )}`,
      difference: tankDeliveryItem?.waterVolumeDifference,
    });

  if (tankDeliveryItem?.end?.water?.volume?.temperatureCompensatedValue)
    defaultData.push({
      details: 'Water TC Volume',
      start: `${tankDeliveryItem?.start?.water?.volume?.temperatureCompensatedValue} ${getMeasureSign(
        tankDeliveryItem?.start?.water?.volume?.unitOfMeasure
      )}`,
      end: `${tankDeliveryItem?.end?.water?.volume?.temperatureCompensatedValue} ${getMeasureSign(
        tankDeliveryItem?.end?.water?.volume?.unitOfMeasure
      )}`,
      difference: tankDeliveryItem?.waterTCVolumeDifference,
    });

  if (tankDeliveryItem?.end?.water?.height?.value)
    defaultData.push({
      details: 'Water Height',
      start: `${tankDeliveryItem?.start?.water?.height?.value} ${getMeasureSign(
        tankDeliveryItem?.start?.water?.height?.unitOfMeasure
      )}`,
      end: `${tankDeliveryItem?.end?.water?.height?.value} ${getMeasureSign(
        tankDeliveryItem?.end?.water?.height?.unitOfMeasure
      )}`,
      difference: tankDeliveryItem?.waterHeightDifference,
    });

  if (tankDeliveryItem?.end?.temperature?.value)
    defaultData.push({
      details: 'Temperature',
      start: `${tankDeliveryItem?.start?.temperature?.value} ${getMeasureSign(
        tankDeliveryItem?.start?.temperature?.unitOfMeasure
      )}`,
      end: `${tankDeliveryItem?.end?.temperature?.value} ${getMeasureSign(
        tankDeliveryItem?.end?.temperature?.unitOfMeasure
      )}`,
      difference: tankDeliveryItem?.temperatureDifference,
    });

  const componentDetails = (
    <EditableTable
      key={tankDeliveryItem.id}
      tableInfo={tankDeliveryItem.id}
      name='read-only-table'
      initWithoutHead
      defaultData={defaultData}
      fieldsArr={fieldsArr}
      readOnly={true}
    />
  );

  return (
    <Card className={classNames('box-card-parent', 'tank-delivery-item')}>
      <Box>
        <Item className={classNames('box-row-columns', 'tank-item__primary-details')}>
          <Item className={classNames('item-primary-header', 'item-primary-header-mobile')}>
            <div>
              <Item className={classNames('list-column-header', 'item-primary-header-name')}>
                {fixedItemDetails[0].name}
              </Item>
              <Item className={classNames('list-column-content')}>{fixedItemDetails[0].value}</Item>
            </div>
          </Item>
          <Item className={classNames('item-primary-header', 'item-primary-header-mobile')}>
            <div>
              <Item className={classNames('list-column-header', 'item-primary-header-name')}>
                {fixedItemDetails[1].name}
              </Item>
              <Item className={classNames('list-column-content')}>{fixedItemDetails[1].value}</Item>
            </div>
          </Item>
        </Item>
      </Box>

      {defaultData && defaultData?.length > 0 && (
        <CollapsibleCard
          id={tankDeliveryItem.id}
          fixeditemDetails={fixedItemDetails}
          componentDetails={componentDetails}
        ></CollapsibleCard>
      )}
    </Card>
  );
};

export default TankDeliveryItem;
