import classNames from 'classnames';
import { useEffect } from 'react';
import { Link, useMatches, useResolvedPath } from 'react-router-dom';

interface CustomNavLinkProps {
  path: string;
  moduleName: string;
  children: JSX.Element;
  setSelectedMenu: (moduleName: string) => void;
}

const CustomNavLink: React.FC<CustomNavLinkProps> = (props: CustomNavLinkProps) => {
  const { path, moduleName, children, setSelectedMenu } = props;
  let resolved = useResolvedPath(path);
  let match = useMatches();

  let isMatch = false;
  if (!!match) {
    isMatch =
      match.filter((m) => m.pathname.toLocaleLowerCase() === resolved.pathname.toLocaleLowerCase()).length > 0 ||
      match.filter((m) => m.pathname.toLocaleLowerCase() === resolved.pathname.toLocaleLowerCase() + '/create').length >
        0 ||
      (match
        .filter(
          (m) =>
            m.pathname.toLocaleLowerCase().endsWith('details') ||
            m.pathname.toLocaleLowerCase().endsWith('edit') ||
            m.pathname.toLocaleLowerCase().endsWith('firmwares') ||
            m.pathname.toLocaleLowerCase().endsWith('operations') ||
            m.pathname.toLocaleLowerCase().endsWith('create') ||
            m.pathname.toLocaleLowerCase().endsWith('export') ||
            m.pathname.toLocaleLowerCase().endsWith('recent') ||
            m.pathname.toLocaleLowerCase().endsWith('status-histories') ||
            m.pathname.toLocaleLowerCase().endsWith('deliveries') ||
            m.pathname.toLocaleLowerCase().endsWith('alert-rules')||
            m.pathname.toLocaleLowerCase().endsWith('user-groups')
        )
        .filter((e) => e.pathname.toLocaleLowerCase().startsWith(resolved.pathname.toLocaleLowerCase())).length > 0 &&
        resolved.pathname.toLocaleLowerCase().split('/').length > 3);
  }

  useEffect(() => {
    if (isMatch) {
      setSelectedMenu(moduleName);
    }
  }, [isMatch, moduleName, setSelectedMenu]);

  return (
    <Link to={path} className={classNames({ 'menu-item-selected': isMatch })}>
      {children}
    </Link>
  );
};
export default CustomNavLink;
