import { TerminalInfoEntity } from '../../entities/terminal';
import { TerminalModel } from '../../models/terminalModel';
import api, { setAuthorize } from '../../utilities/api';

const TERMINAL_API_PREFIX = 'terminal-management';

export const getAllTerminalData = async (data: TerminalModel) => {
  await setAuthorize();
  const res = await api.get(
    `/${TERMINAL_API_PREFIX}/terminals/status?organisationId=${data.organisationId}&siteId=${data.siteId}`
  );
  return res.data;
};

export const deleteTerminalData = async (data: TerminalModel) => {
  await setAuthorize();
  await api.delete(`/${TERMINAL_API_PREFIX}/terminals/${data.id}?organisationId=${data.organisationId}`);
};

export const createTerminal = async (data: TerminalInfoEntity) => {
  await setAuthorize();
  const res = await api.post(`/${TERMINAL_API_PREFIX}/terminals`, data);
  return res.data;
};

export const editTerminalData = async (data: TerminalInfoEntity, id: string) => {
  await setAuthorize();
  await api.put(`/${TERMINAL_API_PREFIX}/terminals/${id}`, data);
};

export const getTerminalInfo = async (data: TerminalModel) => {
  await setAuthorize();
  let result = await api.get(`/${TERMINAL_API_PREFIX}/terminals/${data.id}?organisationId=${data.organisationId}`);
  return result.data;
};

export const reenrolTerminal = async (id: string, data: { registrationId: string; organisationId: string }) => {
  await setAuthorize();
  const res = await api.put(`/${TERMINAL_API_PREFIX}/terminals/${id}/enrolment/`, data);
  return res.data;
};

export const getTerminalList = async (siteId: string, organisationId: string) => {
  await setAuthorize();
  let response = await api.get(`/${TERMINAL_API_PREFIX}/terminals?organisationId=${organisationId}&siteId=${siteId}`);
  return response.data;
};
