import { takeLatest, put, call, select, all, delay } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  setForecourtControllerStatus,
  setForecourtControllerContent,
  setForecourtControllerError,
  setForecourtControllerItem,
  setForecourtControllerDetailStatus,
  setForecourtControllerDetailError,
} from './reducers';
import * as actions from './actions';
import * as services from './services';
import * as terminalOperationServices from '../terminal-operations/services';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import * as dateTimeHelper from '../../utilities/datetime-helper';
import * as terminalActions from '../terminal/actions';
import { getApiErrorMessage, getGenericErrorMessage } from '../../utilities/errorhandler';
import { Messages } from '../../constants/messages';
import { LoadingStatus } from './../../constants/loading-constants';
import { ForecourtControllerEntity, ForecourtControllerListStatusResponse } from '../../entities/forecourtController';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { ForecourtControllerModel, ForecourtControllerRecordModel } from '../../models/forecourtControllerModel';
import { selectOrganisationId } from '../auth/selectors';
import { setSnackBarError, setSnackBarSuccess } from '../snackbar/reducers';
import { setGenericErrorData } from '../generic-error/reducers';
import { setDialogBoxActionStatus, closeDialogBox } from '../dialog-box/reducers';
import { showBackdrop, hideBackdrop, setBackDropActionStatus, setBackDropError } from '../backdrop/reducers';
import { setIsPageDirty } from '../page-configuration/reducers';
import { selectSelectedSiteId } from '../sites/selectors';
import { reLoadTerminalData } from '../terminal/sagas';
import { TerminalOperationActionPostModel } from '../../models/terminalOperationModel';
import { TerminalModel, TerminalRecordModel } from '../../models/terminalModel';
import { selectTerminalData } from '../terminal/selectors';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_FORECOURTCTRLS, loadForecourtControllers);
  yield takeLatest(actions.LOAD_FORECOURTCTRL_INFO, loadForecourtControllerInfo);
  yield takeLatest(actions.CREATE_FORECOURTCTRL, createForecourtController);
  yield takeLatest(actions.EDIT_FORECOURTCTRL, editForecourtController);
  yield takeLatest(actions.DELETE_FORECOURTCTRL, deleteForecourtController);
  yield takeLatest(actions.CANCEL_FORECOURTCTRL_RELOAD, cancelForecourtControllerReload);
}
let isCancelForecourtControllerReloadRequested = false;

export function* loadForecourtControllers() {
  const organisationId: string = yield select(selectOrganisationId);
  const siteId: string = yield select(selectSelectedSiteId);
  try {
    yield put(setForecourtControllerStatus(LoadingStatus.LOADING));
    isCancelForecourtControllerReloadRequested = false;
    let forecourt_controller_response: ForecourtControllerListStatusResponse = yield call(
      services.getAllForecourtControllerStatuses,
      organisationId,
      siteId
    );
    let forecourtControllerData: ForecourtControllerRecordModel[] = yield call(
      MapForecourtControllersEntityToModel,
      forecourt_controller_response
    );

    yield put(setForecourtControllerContent(forecourtControllerData));
    yield put(setForecourtControllerStatus(LoadingStatus.SUCCESS));

    if (forecourtControllerData && forecourtControllerData?.length > 0) {
      // get terminal list first
      let terminalData: TerminalRecordModel[] = yield select(selectTerminalData);
      if (!terminalData || terminalData?.length <= 0) {
        yield call(reLoadTerminalData, {
          payload: {
            siteId: siteId,
            organisationId: organisationId,
          } as TerminalModel,
          type: terminalActions.RELOAD_TERMINALS,
        });
        terminalData = yield select(selectTerminalData);
      }

      // ask each terminal upload the latest tank status
      const responses: any[] = yield all(
        terminalData.map((record) => {
          return call(function* () {
            try {
              yield call(terminalOperationServices.postOperationAction, record.id, {
                organisationId: organisationId,
                type: 'forecourtControllersStatusUpload',
              } as TerminalOperationActionPostModel);
              return { data: 200 };
            } catch (error) {
              return { data: 'error' };
            }
          });
        })
      );

      if (responses.some((result) => result.data === 200)) {
        let reloadCount = 0;
        while (reloadCount < 2) {
          if (isCancelForecourtControllerReloadRequested) return;

          yield delay(5000);
          yield reLoadForecourtControllerData(organisationId, siteId);
        }
      }
    }
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setForecourtControllerError());
    yield put(setForecourtControllerStatus(LoadingStatus.ERROR));
  }
}

function* cancelForecourtControllerReload() {
  isCancelForecourtControllerReloadRequested = true;
}

function* reLoadForecourtControllerData(organisationId: string, siteId: string): Generator<any, void, any> {
  try {
    let forecourt_controller_response: ForecourtControllerListStatusResponse = yield call(
      services.getAllForecourtControllerStatuses,
      organisationId,
      siteId
    );
    let forecourtControllerData: ForecourtControllerRecordModel[] = yield call(
      MapForecourtControllersEntityToModel,
      forecourt_controller_response
    );
    yield put(setForecourtControllerContent(forecourtControllerData));
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setForecourtControllerError());
  }
}

export function* loadForecourtControllerInfo(action: PayloadAction<string>) {
  const organisationId: string = yield select(selectOrganisationId);
  try {
    yield put(setForecourtControllerDetailStatus(LoadingStatus.LOADING));
    let forecourt_controller_response: ForecourtControllerEntity = yield call(
      services.getForecourtControllerById,
      action.payload,
      organisationId
    );
    let forecourtControllerData: ForecourtControllerModel = yield call(
      MapForecourtControllerEntityToModel,
      forecourt_controller_response
    );
    yield put(setForecourtControllerItem(forecourtControllerData));
    yield put(setForecourtControllerDetailStatus(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setForecourtControllerDetailError());
    yield put(setForecourtControllerDetailStatus(LoadingStatus.ERROR));
  }
}

export function* createForecourtController(action: PayloadAction<ForecourtControllerModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    let forecourtControllerEntity = MapForecourtControllerModelToEntity(action.payload);
    yield call(services.createForecourtController, forecourtControllerEntity);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.FORECOURTCONTROLLER_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    return;
  }
}

export function* editForecourtController(action: PayloadAction<ForecourtControllerModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    let forecourtControllerEntity = MapForecourtControllerModelToEntity(action.payload);
    yield call(services.editForecourtController, forecourtControllerEntity, action.payload.id);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.FORECOURTCONTROLLER_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
  }
}

export function* deleteForecourtController(action: PayloadAction<string>) {
  try {
    yield put(setDialogBoxActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    yield call(services.deleteForecourtController, action.payload, organisationId);
    yield put(closeDialogBox());
    yield put(setSnackBarSuccess(Messages.FORECOURTCONTROLLER_DELETE_SUCESS));
    yield loadForecourtControllers();
  } catch (error) {
    yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

const MapForecourtControllersEntityToModel = (response: ForecourtControllerListStatusResponse) => {
  if (response && response.items.length > 0) {
    const result: ForecourtControllerRecordModel[] = response.items.map((item, i) => {
      return {
        id: item.id,
        organisationId: item.organisationId,
        siteId: item.siteId,
        number: item.number,
        status: item.status,
        lastUpdatedDateTimeUtc: dateTimeHelper.getDayCounter(item.dateTimeUtc),
        messages: item.messages,
      };
    });
    return result;
  }
  return [] as ForecourtControllerRecordModel[];
};

const MapForecourtControllerEntityToModel = (response: ForecourtControllerEntity) => {
  if (response.id) {
    return {
      id: response.id,
      organisationId: response.organisationId,
      siteId: response.siteId,
      number: response.number,
      type: response.type,
      serialCommunication: response.serialCommunication,
      networkCommunication: response.networkCommunication,
      serialCommunicationPort: response?.serialCommunication?.port,
      networkCommunicationIpAddress: response?.networkCommunication?.ipAddress,
      networkCommunicationPort: response?.networkCommunication?.port,
      eptId: response.eptId,
      backOfficeRecordFormat: response.backOfficeRecordFormat,
      communicationType: response?.networkCommunication?.port
        ? 'network'
        : response?.serialCommunication?.port
          ? 'serial'
          : '',
    } as ForecourtControllerModel;
  } else throw new Error('Not a valid Forecourt Controller API response');
};

const MapForecourtControllerModelToEntity = (model: ForecourtControllerModel) => {
  if (model) {
    let forecourtControllerEntity = {
      organisationId: model.organisationId,
      siteId: model.siteId,
      number: fieldMappingHelper.sanitizeNumericValue(model.number),
      type: model.type,
      networkCommunication:
        model.communicationType === 'network'
          ? { ipAddress: model.networkCommunicationIpAddress, port: Number(model.networkCommunicationPort) }
          : undefined,
      serialCommunication: model.communicationType === 'serial' ? { port: model.serialCommunicationPort } : undefined,
      eptId: model.eptId,
      backOfficeRecordFormat: model.backOfficeRecordFormat,
    } as ForecourtControllerEntity;
    return forecourtControllerEntity;
  }
  return {} as ForecourtControllerEntity;
};
