import { PumpInfoEntity } from '../../entities/pump';
import { PumpModel } from '../../models/pumpModel';
import api, { setAuthorize } from '../../utilities/api';

const PUMP_API_PREFIX = 'pump-management';

export const getAllPumpData = async (pump: PumpModel) => {
  await setAuthorize();
  let result = await api.get(
    `/${PUMP_API_PREFIX}/pumps/status?organisationId=${pump.organisationId}&siteId=${pump.siteId}`
  );
  return result.data;
};

export const getPumpInfo = async (data: PumpModel) => {
  await setAuthorize();
  let result = await api.get(`/${PUMP_API_PREFIX}/pumps/${data.id}?organisationId=${data.organisationId}`);
  return result.data;
};

export const createPumpData = async (data: PumpInfoEntity) => {
  await setAuthorize();
  await api.post(`/${PUMP_API_PREFIX}/pumps`, data);
};

export const editPumpData = async (data: PumpInfoEntity, id: string) => {
  await setAuthorize();
  await api.put(`/${PUMP_API_PREFIX}/pumps/${id}`, data);
};

export const deletePumpData = async (data: PumpModel) => {
  await setAuthorize();
  await api.delete(`/${PUMP_API_PREFIX}/pumps/${data.id}?organisationId=${data.organisationId}`);
};

export const getPumpList = async (siteId: string, organisationId: string) => {
  await setAuthorize();
  let response = await api.get(`/${PUMP_API_PREFIX}/pumps?organisationId=${organisationId}&siteId=${siteId}`);
  return response.data;
};
