import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DialogBoxComponent from '../../components/dialog-box/dialog-box.container';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import EmptyList from '../../components/empty-list/empty-list';
import MessageDisplay from '../../components/message-display/message-display';
import PriceSignItem from './price-sign-item';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { PriceSignRecordModel } from '../../models/priceSignModel';
import { ModuleName } from '../../constants/module-constants';
import { Messages } from '../../constants/messages';
import { LoadingStatus } from '../../constants/loading-constants';
import { usePageLeaveListener } from '../../hooks/usePageLeaveListener';
import './styles/price-signs.scss';

interface PriceSignsProps {
  priceSignContent: PriceSignRecordModel[];
  priceSignStatus: string;
  authStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  loadPriceSigns: () => void;
  deletePriceSign: (data: string) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  openDialogBox: () => void;
  closeDialogBox: () => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  cancelPriceSignReload: () => void;
}

const PriceSigns: React.FC<PriceSignsProps> = (props: PriceSignsProps) => {
  const {
    priceSignContent,
    priceSignStatus,
    authStatus,
    userAccess,
    loadPriceSigns,
    deletePriceSign,
    setHeaderConfiguration,
    openDialogBox,
    closeDialogBox,
    setPageConfiguration,
    setIsPageDirty,
    cancelPriceSignReload,
  } = props;

  const navigate = useNavigate();
  const { orgId, siteId } = useParams();
  const isLoadPriceSignsRequested = useRef(false);

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.PRICE_SIGN).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.PRICE_SIGN).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.PRICE_SIGN).hasUpdateAccess);
    setHasDeleteAccess(userAccess(ModuleName.PRICE_SIGN).hasDeleteAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(priceSignStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(priceSignStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(priceSignStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, priceSignStatus]);

  const emptyListMessage = 'No price signs found';
  const [deleteContext, setDeleteContent] = useState('');
  const [selectedId, setSelectedId] = useState('');

  usePageLeaveListener(cancelPriceSignReload);

  useEffect(() => {
    if (!isLoadPriceSignsRequested.current && authSuccess && hasReadAccess) {
      loadPriceSigns();
    }
    isLoadPriceSignsRequested.current = true;
  }, [authSuccess, hasReadAccess, loadPriceSigns]);

  useEffect(() => {
    setHeaderConfiguration({
      title: 'Price Signs',
      showCreateButton: authSuccess && !loading ? hasCreateAccess : false,
      showSiteHeader: true,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: false,
      error: error,
      pageURL: 'price-signs',
    } as HeaderStateModel);
  }, [setHeaderConfiguration, authSuccess, hasCreateAccess, error, loading]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  /** DIALOG BUTTONS */
  const confirmDialog = () => {
    deletePriceSign(selectedId);
  };

  const cancelDialog = () => {
    closeDialogBox();
  };

  /** ACTION MENU (Edit, Delete) */
  const onDeleteHandler = useCallback(
    (priceSignId: string, name: number) => {
      setSelectedId(priceSignId);
      setDeleteContent(`Are you sure you want to delete ${name}?`);
      openDialogBox();
    },
    [openDialogBox]
  );

  const onEditHandler = (priceSignId: string) => {
    navigate(`/organisations/${orgId}/sites/${siteId}/price-signs/${priceSignId}/details/edit`);
  };

  const onClick = (priceSignId: string) => {
    navigate(`/organisations/${orgId}/sites/${siteId}/price-signs/${priceSignId}/details`);
  };

  return (
    <>
      {
        <DialogBoxComponent
          context={deleteContext}
          closeTextButton='No'
          confirmTextButton='Yes'
          confirmDialog={confirmDialog}
          onClose={cancelDialog}
          header='Delete Price Sign'
        />
      }

      {(!authCheckCompleted || loading) && <LoadingDisplay />}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}

      {error && <ErrorDisplayControl />}
      {success && (
        <div className='price-sign-list'>
          {priceSignContent &&
            priceSignContent.map((item, i) => {
              return (
                <PriceSignItem
                  id={item.id}
                  key={i}
                  priceSignItem={item}
                  onDelete={onDeleteHandler}
                  onEdit={onEditHandler}
                  onClick={onClick}
                  hasDeleteAccess={hasDeleteAccess}
                  hasUpdateAccess={hasUpdateAccess}
                />
              );
            })}
          {<>{priceSignContent?.length === 0 && <EmptyList message={emptyListMessage}></EmptyList>}</>}
        </div>
      )}
    </>
  );
};

export default PriceSigns;
