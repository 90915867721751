import KeyValuePair from '../models/baseModels/keyValuePairModel';

//message
export const INVALID_DATE_MESSAGE_VALUE = 'Invalid date';
export const INVALID_TIME_MESSAGE_VALUE = 'Invalid time';
export const ACTIVATION_TIME_TOO_OLD = 'Activation time is too old';
//Reason
export const INVALID_DATE_REASON_VALUE = 'invalidDate';
export const DISABLE_FUTURE_REASON_VALUE = 'disableFuture';
export const DISABLE_DATE_VALUE = 'shouldDisableDate';

//DateTime Format
export const DATETIME_FORMAT = Object.freeze({
  DATE_ONLY: 'DD/MM/YYYY',
  DATETIMEFORMAT: 'DD/MM/YYYY hh:mm:ss',
  DATETIMEFORMAT_AM_PM_MARKER: 'DD/MM/YYYY hh:mm A',
  DATETIMEFORMAT_ISO: 'YYYY-MM-DDTHH:mm:ss.sssZ',
  MONTH_AND_YEAR_ONLY: 'MM/YYYY',
});

export const DATETIME_CONTROL_VALIDATION_MESSAGE = 'Please select both date and time';

export const DATETIME_CONTROL_FUTURE_VALIDATION_MESSAGE = 'Date and time cannot be in the future';

export const dateTimeRangePickerShortCutOption: KeyValuePair[] = [
  { key: 'today', value: 'Today' },
  { key: 'thisWeek', value: 'This Week' },
  { key: 'lastWeek', value: 'Last Week' },
  { key: 'thisMonth', value: 'This Month' },
  { key: 'lastMonth', value: 'Last Month' },
  { key: 'custom', value: 'Custom' },
];
