import { initialState } from './forecourtCtrlInitialState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import { ForecourtControllerRecordModel, ForecourtControllerModel } from '../../models/forecourtControllerModel';

const ForecourtControllerState = createSlice({
  name: 'forecourtControllerState',
  initialState,
  reducers: {
    setForecourtControllerStatus: (state, action: PayloadAction<string>) => {
      state.hasError = false;
      state.forecourtControllerStatus = action.payload;
    },
    setForecourtControllerDetailStatus: (state, action: PayloadAction<string>) => {
      state.hasError = false;
      state.forecourtControllerDetailStatus = action.payload;
    },
    setForecourtControllerContent: (state, action: PayloadAction<ForecourtControllerRecordModel[]>) => {
      state.hasError = false;
      state.forecourtControllerContent = action.payload;
    },
    setForecourtControllerItem: (state, action: PayloadAction<ForecourtControllerModel>) => {
      state.hasError = false;
      state.forecourtControllerItem = action.payload;
    },
    setForecourtControllerError: (state) => {
      state.hasError = true;
      state.forecourtControllerContent = [];
      state.forecourtControllerStatus = LoadingStatus.ERROR;
    },
    setForecourtControllerDetailError: (state) => {
      state.hasError = true;
      state.forecourtControllerItem = {} as ForecourtControllerModel;
      state.forecourtControllerDetailStatus = LoadingStatus.ERROR;
    },
  },
});

export const {
  setForecourtControllerStatus,
  setForecourtControllerDetailStatus,
  setForecourtControllerContent,
  setForecourtControllerItem,
  setForecourtControllerError,
  setForecourtControllerDetailError,
} = ForecourtControllerState.actions;

export default ForecourtControllerState.reducer;
