import { initialState } from './tankInitState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import { TankModel } from '../../models/tankModel';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

const tankSlice = createSlice({
  name: 'tankState',
  initialState,
  reducers: {
    setTankStatus: (state, action: PayloadAction<string>) => {
      state.tankStatus = action.payload;
      state.hasError = false;
    },
    setTankDetailStatus: (state, action: PayloadAction<string>) => {
      state.tankDetailStatus = action.payload;
      state.hasError = false;
    },
    setTankData: (state, action: PayloadAction<TankModel[]>) => {
      state.tankData = action.payload;
      state.hasError = false;
    },
    setTankInfo: (state, action: PayloadAction<TankModel>) => {
      state.hasError = false;
      state.tankInfo = action.payload;
    },
    setTankError: (state) => {
      state.hasError = true;
      state.tankData = [];
      state.tankStatus = LoadingStatus.ERROR;
    },
    setTankDetailError: (state) => {
      state.hasError = true;
      state.tankInfo = {} as TankModel;
      state.tankDetailStatus = LoadingStatus.ERROR;
    },
    setTankNameList: (state, action: PayloadAction<KeyValuePair[]>) => {
      state.hasError = false;
      state.tankNameList = action.payload;
    },
    setTankNumberList: (state, action: PayloadAction<KeyValuePair[]>) => {
      state.hasError = false;
      state.tankNumberList = action.payload;
    },
  },
});

export const {
  setTankStatus,
  setTankDetailStatus,
  setTankData,
  setTankInfo,
  setTankError,
  setTankDetailError,
  setTankNameList,
  setTankNumberList,
} = tankSlice.actions;
export default tankSlice.reducer;
