import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { LoadingStatus } from '../../../constants/loading-constants';
import { ModuleName } from '../../../constants/module-constants';
import { TextFieldType } from '../../../constants/textfield-constants';
import { FormActionType } from '../../../constants/form-constants';
import { Messages } from '../../../constants/messages';
import { DEFAULT_DROP_DOWN_VALUE, productUnitOfMeasure } from '../../../constants/dropdown-constants';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { ExternalProductCode, ProductInfoModel } from '../../../models/productModel';
import { ValidationError } from '../../../models/baseModels/validationModel';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import Form from '../../../components/form/form.container';
import CustomTextField from '../../../components/text-field/text-field.container';
import DropDown from '../../../components/dropdown/dropdown.container';
import ProductCodesComponent from './productCodes';
import '../styles/product.scss';

interface ProductPageProps {
  productItem: ProductInfoModel;
  productStatus: string;
  hasValidationError: boolean;
  pageTitle?: string;
  action?: string;
  backDropActionStatus: string;
  siteListName: KeyValuePair[];
  fieldValidations: ValidationError[];
  customValidationMessage?: string;
  siteStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  editProduct: (item: ProductInfoModel) => void;
  createProduct: (item: ProductInfoModel) => void;
  loadSelectedProduct: (id?: string) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  removeAllValidation: () => void;
  removeValidation: (name: string) => void;
  loadSiteNameList: () => void;
  setCustomValidatationMessage: (value: string) => void;
}

const Product: React.FC<ProductPageProps> = (props: ProductPageProps) => {
  const {
    productItem,
    productStatus,
    hasValidationError,
    pageTitle,
    action,
    backDropActionStatus,
    siteListName,
    fieldValidations,
    customValidationMessage,
    siteStatus,
    userAccess,
    editProduct,
    createProduct,
    loadSelectedProduct,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    removeAllValidation,
    removeValidation,
    loadSiteNameList,
    setCustomValidatationMessage,
  } = props;

  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [productValue, setProductValue] = useState({} as ProductInfoModel);
  const [secondaryButtonMoreOptions, setSecondaryButtonMoreOptions] = useState([] as KeyValuePair[]);
  const [primaryButtonMoreOptions, setPrimaryButtonMoreOptions] = useState([] as KeyValuePair[]);

  const navigate = useNavigate();
  const location = useLocation();
  const { orgId, productId } = useParams();
  const isLoadSelectedProductRequested = useRef(false);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  //ACCESS
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);
  const [hasSiteReadAccess, setHasSiteReadAccess] = useState(false);
  const [hasSiteCreateAccess, setHasSiteCreateAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.PRODUCT).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.PRODUCT).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.PRODUCT).hasUpdateAccess);
    setHasSiteReadAccess(userAccess(ModuleName.SITE).hasReadAccess);
    setHasSiteCreateAccess(userAccess(ModuleName.SITE).hasCreateAccess);
  }, [userAccess]);

  const [isUpdatingForm, setIsUpdatingForm] = useState(false);
  const [isClickFromViewPage, setIsClickFromViewPage] = useState(false);

  useMemo(() => {
    setLoading(productStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(productStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(productStatus === LoadingStatus.ERROR);
  }, [hasReadAccess, productStatus]);

  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  useEffect(() => {
    setIsUpdatingForm(action === FormActionType.CREATE || action === FormActionType.EDIT);
  }, [location]);

  useEffect(() => {
    setIsPageDirty(false);
    if (
      !isLoadSelectedProductRequested.current &&
      hasReadAccess &&
      (action === FormActionType.VIEW || (action === FormActionType.EDIT && !isClickFromViewPage))
    ) {
      loadSelectedProduct(productId);
      isLoadSelectedProductRequested.current = true;
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      removeAllValidation();
    }
  }, [
    productId,
    hasReadAccess,
    hasCreateAccess,
    hasUpdateAccess,
    action,
    isClickFromViewPage,
    setIsPageDirty,
    loadSelectedProduct,
    removeAllValidation,
  ]);

  useEffect(() => {
    switch (action) {
      case FormActionType.CREATE:
        setSuccess(hasCreateAccess);
        break;
      case FormActionType.EDIT:
        setSuccess(hasUpdateAccess);
        break;
      case FormActionType.VIEW:
        setSuccess(hasReadAccess);
        break;
    }
  }, [action, hasCreateAccess, hasReadAccess, hasUpdateAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, pageTitle, error]);

  useEffect(() => {
    setIsPageDirty(false);
    if (action === FormActionType.EDIT && hasReadAccess) {
      setIsSaveButtonEnabled(hasUpdateAccess);
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      setProductValue({
        unitOfMeasure: 'litre',
      } as ProductInfoModel);
      setIsSaveButtonEnabled(hasCreateAccess);
    }
  }, [setIsPageDirty, hasCreateAccess, hasUpdateAccess, hasReadAccess, action]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: action === FormActionType.VIEW,
    } as PageSettingStateModel);
  }, [action, setPageConfiguration]);

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS || backDropActionStatus === LoadingStatus.WARNING) {
      navigate(`/organisations/${orgId}/products`);
    }
  }, [backDropActionStatus, orgId, navigate]);

  useMemo(() => {
    if (!!productItem) {
      setProductValue(productItem);
    }
  }, [productItem]);

  useEffect(() => {
    if (
      !productValue?.externalCodes
        ?.map((it) => {
          return it.values;
        })
        .flat()
        ?.some((it) => it?.type && it?.type !== '' && !it.value)
    ) {
      setCustomValidatationMessage('');
    }
  }, [productValue]);

  const onEditHandler = () => {
    setIsClickFromViewPage(true);
    navigate(`/organisations/${orgId}/products/${productId}/details/edit`);
  };

  const onSaveClickHandler = () => {
    setValidateCounterFlag((prev) => ++prev);
    if (
      productValue?.externalCodes
        ?.map((it) => {
          return it.values;
        })
        .flat()
        ?.some((it) => it?.type && it?.type !== '' && !it.value)
    ) {
      setCustomValidatationMessage(Messages.PUMP_PRODUCT_CODE_REQUIRED_MSG);
      return;
    }
    if (!hasValidationError) {
      switch (action) {
        case FormActionType.EDIT:
          editProduct(productValue);
          break;
        case FormActionType.CREATE:
          createProduct(productValue);
          break;
      }
      setValidateCounterFlag(0);
    }
  };

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setIsPageDirty(isSaveButtonEnabled);
      setProductValue((prevstate) => {
        return {
          ...prevstate,
          [newvalue.key]: newvalue.value,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const onCancel = () => {
    setValidateCounterFlag(0);
  };

  const ProductCodesConfig = useMemo(() => {
    const handleNewProductCodesValue = (newvalue: ExternalProductCode[]) => {
      setIsPageDirty(isSaveButtonEnabled);
      setProductValue((prevstate) => {
        return {
          ...prevstate,
          externalCodes: [...newvalue],
        };
      });
    };

    return (
      <ProductCodesComponent
        validateCounter={validateCounterFlag}
        productCodes={productValue?.externalCodes}
        siteListName={siteListName}
        onBindingValue={handleNewProductCodesValue}
        readOnly={!isUpdatingForm}
        removeValidation={removeValidation}
        hasSiteReadAccess={hasSiteReadAccess}
        hasSiteCreateAccess={hasSiteCreateAccess}
        loadSiteNameList={loadSiteNameList}
        siteStatus={siteStatus}
      ></ProductCodesComponent>
    );
  }, [
    validateCounterFlag,
    productValue,
    isSaveButtonEnabled,
    isUpdatingForm,
    siteListName,
    setProductValue,
    setIsPageDirty,
    removeValidation,
    setCustomValidatationMessage,
  ]);

  const validExternalProductCodes = useMemo(() => {
    if (productValue?.externalCodes && productValue?.externalCodes?.length > 0) {
      for (const obj of productValue?.externalCodes.map((externalProductCode) => externalProductCode.values)?.flat()) {
        if (!obj.hasOwnProperty('type')) {
          return false;
        }
        if (!obj.hasOwnProperty('value') || !obj.value) {
          return false;
        }
      }
      return true;
    } else return true;
  }, [productValue]);

  const tabWizardList = [
    {
      tabType: 'link',
      tabLink: `/organisations/${orgId}/products`,
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>Products</span> <NavigateNextIcon className='next-icon' />
        </div>
      ),
    },
    {
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>Basic</span>
          <NavigateNextIcon className='next-icon' />
        </div>
      ),
      tabError: fieldValidations?.some(
        (it) =>
          (it.name === 'name' && it.hasError === true) ||
          (it.name === 'unitOfMeasure' && it.hasError === true) ||
          (it.name === 'internalCode' && it.hasError === true)
      ),
      tabFormCompleted: !fieldValidations?.some(
        (it) =>
          (it.name === 'name' && it.hasError === true) ||
          (it.name === 'unitOfMeasure' && it.hasError === true) ||
          (it.name === 'internalCode' && it.hasError === true)
      ),
      tabPanel: (
        <div className='wizard-tab-panel-details'>
          <CustomTextField
            validateCounter={validateCounterFlag}
            isMandatory={true}
            key='name'
            onBindingValue={onTextChangeHandler}
            label='Name'
            placeholder='Enter Product Name'
            name='name'
            value={productValue?.name}
            type='input'
            maxCharLength={30}
            readOnly={!isUpdatingForm}
          />

          <CustomTextField
            validateCounter={validateCounterFlag}
            key='shortName'
            onBindingValue={onTextChangeHandler}
            label='Short Name'
            placeholder='Enter Product Short Name'
            name='shortName'
            value={productValue?.shortName}
            type='input'
            maxCharLength={8}
            tooltipTitle='If set, this name will appear on the terminal display. If not, up to the first 8 characters of Name will be used instead.'
            readOnly={!isUpdatingForm}
          />

          <DropDown
            validateCounter={validateCounterFlag}
            isMandatory={true}
            key='unitOfMeasure'
            name='unitOfMeasure'
            onBindingValue={onTextChangeHandler}
            value={!!productValue?.unitOfMeasure ? productValue?.unitOfMeasure : DEFAULT_DROP_DOWN_VALUE}
            label='Unit Of Measure'
            keyValuePair={productUnitOfMeasure}
            readOnly={!isUpdatingForm}
          />

          <CustomTextField
            validateCounter={validateCounterFlag}
            isMandatory={true}
            key='internalCode'
            onBindingValue={onTextChangeHandler}
            label='Default Product Code'
            placeholder='Enter Default Product Code'
            name='internalCode'
            value={productValue?.internalCode}
            type={TextFieldType.INTEGER}
            maxCharLength={3}
            maxRange={999}
            readOnly={!isUpdatingForm}
          />
        </div>
      ),
    },
    {
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>External Product Codes</span>
        </div>
      ),
      tabError:
        fieldValidations?.some(
          (it) =>
            (it.name === 'sites0' && it.hasError === true) ||
            (it.name === 'sites1' && it.hasError === true) ||
            (it.name === 'sites2' && it.hasError === true) ||
            (it.name === 'sites3' && it.hasError === true) ||
            (it.name === 'sites4' && it.hasError === true) ||
            (it.name === 'value' && it.hasError === true)
        ) ||
        customValidationMessage === Messages.PUMP_PRODUCT_CODE_REQUIRED_MSG ||
        !validExternalProductCodes,
      tabFormCompleted:
        !fieldValidations?.some(
          (it) =>
            (it.name === 'sites0' && it.hasError === true) ||
            (it.name === 'sites1' && it.hasError === true) ||
            (it.name === 'sites2' && it.hasError === true) ||
            (it.name === 'sites3' && it.hasError === true) ||
            (it.name === 'sites4' && it.hasError === true) ||
            (it.name === 'value' && it.hasError === true)
        ) &&
        productValue?.externalCodes &&
        productValue?.externalCodes?.length > 0 &&
        customValidationMessage !== Messages.PUMP_PRODUCT_CODE_REQUIRED_MSG &&
        validExternalProductCodes,
      tabPanel: ProductCodesConfig,
    },
  ];

  const currentTabWizardChangeHandler = (data: number) => {
    if (data <= 1) {
      setSecondaryButtonMoreOptions([]);
      setPrimaryButtonMoreOptions([
        {
          key: 'save',
          value: 'Save',
          additionalValue: onSaveClickHandler,
        } as KeyValuePair,
      ]);
    } else if (data >= 2) {
      setSecondaryButtonMoreOptions([
        {
          key: 'cancel',
          value: 'Cancel',
          additionalValue: () => navigate(`/organisations/${orgId}/bin-ranges`),
        } as KeyValuePair,
      ]);
      setPrimaryButtonMoreOptions([]);
    }
  };

  const wizardListChangeHandler = () => {
    if (fieldValidations && fieldValidations?.length > 0) setValidateCounterFlag((prev) => ++prev);
  };

  return (
    <React.Fragment>
      <Form
        displayLoadingIndicator={(action === FormActionType.VIEW || action === FormActionType.EDIT) && loading}
        displayErrorDetails={error}
        displayNoAccessMessage={
          (action === FormActionType.VIEW && !hasReadAccess) ||
          (action === FormActionType.CREATE && !hasCreateAccess) ||
          (action === FormActionType.EDIT && !hasUpdateAccess)
        }
        displayForm={success}
        isSaveButtonEnabled={isSaveButtonEnabled}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        onEditClick={onEditHandler}
        formDataloading={loading}
        hasUpdateAccess={hasUpdateAccess}
        listURL={`/organisations/${orgId}/products`}
        isClickFromViewPage={isClickFromViewPage}
        tabWizardList={tabWizardList}
        onCurrentTabWizardChange={currentTabWizardChangeHandler}
        wizardListChange={wizardListChangeHandler}
        primaryButtonMoreOptions={primaryButtonMoreOptions}
        secondaryButtonMoreOptions={secondaryButtonMoreOptions}
      >
        <></>
      </Form>
    </React.Fragment>
  );
};

export default Product;
