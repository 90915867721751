import { RouteObject } from 'react-router-dom';
import { FormActionType } from '../constants/form-constants';
import MainPage from '../modules/main/main.container';
import DashboardPage from '../modules/dashboard/dashboard.container';
import TransactionPage from '../modules/transactions/transactions.container';
import TransactionExportPage from '../modules/transaction-export/transaction-export.container';
import TransactionRecentReportPage from '../modules/transaction-export/child-modal/recent-reports.container';
import PageNotFound from '../modules/pageNotFound/pageNotFound.container';
import OrganisationList from '../modules/organisations/organisations.container';
import UserListPage from '../modules/users/users.container';
import MyAccountPage from '../modules/my-account/my-account.container';
import PrivacyPage from '../modules/privacy-page/privacy-page.container';
import OrganisationPage from '../modules/organisations/form/organisation.container';
import ProductList from '../modules/products/product.container';
import ProductPage from '../modules/products/forms/product.container';
import UserPage from '../modules/users/forms/user.container';
import LogOutPage from '../modules/logout/logout.container';
import SitePage from '../modules/sites/forms/site.container';
import SiteList from '../modules/sites/sites.container';
import PermissionGroupPage from '../modules/permission-groups/forms/permission-group.container';
import PermissionGroupList from '../modules/permission-groups/permission-group.container';
import ErrorDisplayControl from '../components/error-display/error-display.container';
import PriceSchedulesList from '../modules/price-schedules/price-schedules.container';
import PriceSchedulePage from '../modules/price-schedules/forms/price-schedule.container';
import TerminalList from '../modules/terminals/terminal.container';
import TerminalPage from '../modules/terminals/forms/terminal.container';
import TankList from '../modules/tanks/tank-list.container';
import TankPage from '../modules/tanks/forms/tank.container';
import TankStatusHistoryPage from '../modules/tank-histories/tank-histories.container';
import TankDeliveryPage from '../modules/tank-deliveries/tank-deliveries.container';
import PumpList from '../modules/pumps/pump.container';
import PumpPage from '../modules/pumps/forms/pump.container';
import TerminalFirmwares from '../modules/terminal-firmwares/firmwares.container';
import TerminalOperations from '../modules/terminal-operations/terminal-operations.container';
import TerminalOperationPage from '../modules/terminal-operations/forms/terminal-operation.container';
import BinRangeList from '../modules/bin-ranges/bin-ranges.container';
import BinRangePage from '../modules/bin-ranges/forms/bin-range.container';
import CardList from '../modules/cards/cards.container';
import CardPage from '../modules/cards/forms/card.container';
import ReceiptTemplateList from '../modules/receipt-templates/receipt-templates.container';
import ReceiptTemplatePage from '../modules/receipt-templates/forms/receipt-template.containter';
import CardOperations from '../modules/card-operations/card-operations.container';
import OperationDetailsPage from '../modules/card-operations/forms/operation-details.container';
import CardOperationPage from '../modules/card-operations/forms/card-operation.container';
import PriceSignList from '../modules/price-signs/price-signs.container';
import PriceSignPage from '../modules/price-signs/forms/price-sign.container';
import ActivityLogList from '../modules/activity-logs/activity-log.container';
import EventList from '../modules/events/event.container';
import AlertList from '../modules/alerts/alerts.container';
import AlertUserGroupList from '../modules/alert-usergroups/alert-usergroups.container';
import AlertUserGroupPage from '../modules/alert-usergroups/forms/alert-usergroup.container';
import AlertRuleList from '../modules/alert-rules/alert-rules.container';
import AlertRulePage from '../modules/alert-rules/forms/alert-rule.container';
import ForeCourtControllerList from '../modules/forecourt-controllers/forecourt-controllers.container';
import ForecourtControllerPage from '../modules/forecourt-controllers/forms/forecourt-controller.container';

const AppRoutes: RouteObject[] = [
  {
    path: '/',
    element: <MainPage />,
    errorElement: <ErrorDisplayControl />,
    caseSensitive: false,
    children: [
      {
        path: '/',
        element: <DashboardPage />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId',
        element: <DashboardPage />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites',
        element: <SiteList />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/create',
        element: <SitePage pageTitle='Create Site' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/details',
        element: <SitePage pageTitle='Site Details' action={FormActionType.VIEW} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/details/edit',
        element: <SitePage pageTitle='Edit Site' action={FormActionType.EDIT} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/terminals',
        element: <TerminalList />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/terminals/create',
        element: <TerminalPage pageTitle='Create Terminal' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/terminals/:terminalId/details',
        element: <TerminalPage pageTitle='Terminal Details' action={FormActionType.VIEW} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/terminals/:terminalId/details/edit',
        element: <TerminalPage pageTitle='Edit Terminal' action={FormActionType.EDIT} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/terminals/:terminalId/firmwares',
        element: <TerminalFirmwares />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/terminals/:terminalId/operations',
        element: <TerminalOperations />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/terminals/:terminalId/operations/create',
        element: <TerminalOperationPage pageTitle='Start a New Operation' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/pumps',
        element: <PumpList />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/pumps/create',
        element: <PumpPage pageTitle='Create Pump' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/pumps/:pumpId/details',
        element: <PumpPage pageTitle='Pump Details' action={FormActionType.VIEW} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/pumps/:pumpId/details/edit',
        element: <PumpPage pageTitle='Edit Pump' action={FormActionType.EDIT} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/tanks',
        element: <TankList />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/tanks/create',
        element: <TankPage pageTitle='Create Tank' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/tanks/:tankId/details',
        element: <TankPage pageTitle='Tank Details' action={FormActionType.VIEW} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/tanks/:tankId/details/edit',
        element: <TankPage pageTitle='Edit Tank' action={FormActionType.EDIT} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/tanks/:tankId/status-histories',
        element: <TankStatusHistoryPage />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/tanks/:tankId/deliveries',
        element: <TankDeliveryPage />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/receipt-templates',
        element: <ReceiptTemplateList />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/receipt-templates/create',
        element: <ReceiptTemplatePage pageTitle='Create Receipt Template' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/receipt-templates/:receiptTemplateId/details',
        element: <ReceiptTemplatePage pageTitle='Receipt Template Details' action={FormActionType.VIEW} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/receipt-templates/:receiptTemplateId/details/edit',
        element: <ReceiptTemplatePage pageTitle='Edit Receipt Template' action={FormActionType.EDIT} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/price-signs',
        element: <PriceSignList />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/price-signs/create',
        element: <PriceSignPage pageTitle='Create Price Sign' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/price-signs/:priceSignId/details',
        element: <PriceSignPage pageTitle='Price Sign Details' action={FormActionType.VIEW} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/price-signs/:priceSignId/details/edit',
        element: <PriceSignPage pageTitle='Edit Price Sign' action={FormActionType.EDIT} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/forecourt-controllers',
        element: <ForeCourtControllerList />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/forecourt-controllers/create',
        element: <ForecourtControllerPage pageTitle='Create Forecourt Controller' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/forecourt-controllers/:forecourtControllerId/details',
        element: <ForecourtControllerPage pageTitle='Forecourt Controller Details' action={FormActionType.VIEW} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/sites/:siteId/forecourt-controllers/:forecourtControllerId/details/edit',
        element: <ForecourtControllerPage pageTitle='Edit Forecourt Controller' action={FormActionType.EDIT} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/products',
        element: <ProductList />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/products/create',
        element: <ProductPage pageTitle='Create Product' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/products/:productId/details',
        element: <ProductPage pageTitle='Product Details' action={FormActionType.VIEW} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/products/:productId/details/edit',
        element: <ProductPage pageTitle='Edit Product' action={FormActionType.EDIT} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/price-schedules',
        element: <PriceSchedulesList />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/price-schedules/create',
        element: <PriceSchedulePage pageTitle='Create Price Schedule' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/price-schedules/:priceScheduleId/details',
        element: <PriceSchedulePage pageTitle='Price Schedule Details' action={FormActionType.VIEW} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/price-schedules/:priceScheduleId/details/edit',
        element: <PriceSchedulePage pageTitle='Edit Price Schedule' action={FormActionType.EDIT} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/transactions',
        element: <TransactionPage />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/transactions/export',
        element: <TransactionExportPage />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/transactions/recent',
        element: <TransactionRecentReportPage />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations',
        element: <OrganisationList />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/create',
        element: <OrganisationPage pageTitle='Create Organisation' action='CREATE' />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/edit',
        element: <OrganisationPage pageTitle='Edit Organisation' action='EDIT' />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/users',
        element: <UserListPage />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/users/create',
        element: <UserPage pageTitle='Create User' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/users/invite-guest',
        element: <UserPage pageTitle='Invite Guest' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/users/:userId/details',
        element: <UserPage pageTitle='User Details' action={FormActionType.VIEW} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/users/:userId/details/edit',
        element: <UserPage pageTitle='Edit User' action={FormActionType.EDIT} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/permission-groups',
        element: <PermissionGroupList />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/permission-groups/create',
        element: <PermissionGroupPage pageTitle='Create Permission Group' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/permission-groups/:groupId/create',
        element: <PermissionGroupPage pageTitle='Create Permission Group' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/permission-groups/:groupId/details',
        element: <PermissionGroupPage pageTitle='Permission Group Details' action={FormActionType.VIEW} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/permission-groups/:groupId/details/edit',
        element: <PermissionGroupPage pageTitle='Edit Permission Group' action={FormActionType.EDIT} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/bin-ranges',
        element: <BinRangeList />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/bin-ranges/create',
        element: <BinRangePage pageTitle='Create BIN Range' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/bin-ranges/:binRangeId/details/edit',
        element: <BinRangePage pageTitle='Edit BIN Range' action={FormActionType.EDIT} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/bin-ranges/:binRangeId/details',
        element: <BinRangePage pageTitle='BIN Range Details' action={FormActionType.VIEW} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/cards',
        element: <CardList />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/cards/create',
        element: <CardPage pageTitle='Create Card' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/cards/:cardId/details/edit',
        element: <CardPage pageTitle='Edit Card' action={FormActionType.EDIT} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/cards/:cardId/details',
        element: <CardPage pageTitle='Card Details' action={FormActionType.VIEW} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/cards/card-operations',
        element: <CardOperations />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/cards/card-operations/:operationId/details',
        element: <OperationDetailsPage />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/cards/card-operations/create',
        element: <CardOperationPage pageTitle='New Operation' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/activity-logs',
        element: <ActivityLogList />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/events',
        element: <EventList />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/alerts',
        element: <AlertList />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/alerts/user-groups',
        element: <AlertUserGroupList />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/alerts/user-groups/create',
        element: <AlertUserGroupPage pageTitle='Create User Group' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/alerts/user-groups/:userGroupId/details',
        element: <AlertUserGroupPage pageTitle='User Group Details' action={FormActionType.VIEW} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/alerts/user-groups/:userGroupId/details/edit',
        element: <AlertUserGroupPage pageTitle='Edit User Group' action={FormActionType.EDIT} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/alerts/alert-rules',
        element: <AlertRuleList />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/alerts/alert-rules/create',
        element: <AlertRulePage pageTitle='Create Alert Rule' action={FormActionType.CREATE} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/alerts/alert-rules/:alertRuleId/details',
        element: <AlertRulePage pageTitle='Alert Rule Details' action={FormActionType.VIEW} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'organisations/:orgId/alerts/alert-rules/:alertRuleId/details/edit',
        element: <AlertRulePage pageTitle='Edit Alert Rule' action={FormActionType.EDIT} />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'myaccount',
        element: <MyAccountPage />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'privacy',
        element: <PrivacyPage />,
        errorElement: <ErrorDisplayControl />,
      },
      {
        path: 'logout',
        element: <LogOutPage />,
        errorElement: <ErrorDisplayControl />,
      },

      //NOTE: Always put this on the bottom part
      { path: '*', element: <PageNotFound /> },
      //TODO: add routes once page component is available, for now let it redirect to Page not found.
      // { path:"sites/receipts", element:<div/> },
      // { path:"sites/events", element:<div/> },
      // { path:"sites/site-details", element:<div/> },
      // { path:"sites/alarms", element:<div/> },
      // { path:"contacts", element:<div />},
      // { path:"cards", element:<div />},
      // { path:"technicians", element:<div/> },
      // { path:"contact", element:<div/> },
    ],
  },
] as RouteObject[];

export default AppRoutes;
