import React, { useMemo, useState, useEffect } from 'react';
import 'chartjs-adapter-date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import { parseISO, getTime } from 'date-fns';
import { CircularProgress } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import classNames from 'classnames';
import TankHistoryFilterForm from './child-modal/filter-container';
import MessageDisplay from '../../components/message-display/message-display';
import FooterBar from '../../components/footer/footer';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import BackButton from '../../components/back-button/back-button';
import LineChart from '../../components/line-chart/line-chart';
import CustomButton from '../../components/button/custom-button';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import { LoadingStatus } from '../../constants/loading-constants';
import { ModuleName } from '../../constants/module-constants';
import { Messages } from '../../constants/messages';
import { ModalType } from '../../constants/modal-constants';
import { ButtonStyle } from '../../constants/button-constants';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { TankStatusHistoryFilterModel, TankStatusHistoryModel } from '../../models/tankHistory';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { TankModel } from '../../models/tankModel';
import { convertDateStringtoUTCString, convertDateToString } from '../../utilities/datetime-helper';
import { getMeasureSign } from '../../utilities/general-helper';
import * as dateTimeHelper from '../../utilities/datetime-helper';
import './styles/tank-histories.scss';

interface TankHistoryComponentProps {
  tankHistoryData: TankStatusHistoryModel[];
  tankHistoryStatus: string;
  tankInfo: TankModel;
  authStatus: string;
  loadTankStatusHistories: (data: TankStatusHistoryFilterModel) => void;
  loadTankInfo: (id: string) => void;
  userAccess: (moduleName: string) => AuthorisationModel;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  openModal: (data: ModalStateModel) => void;
}

const TankHistoryComponent: React.FC<TankHistoryComponentProps> = (props: TankHistoryComponentProps) => {
  const {
    tankHistoryData,
    tankHistoryStatus,
    tankInfo,
    authStatus,
    loadTankStatusHistories,
    loadTankInfo,
    userAccess,
    setHeaderConfiguration,
    setPageConfiguration,
    openModal,
  } = props;

  const [historyChartDisplayData, setHistoryChartDisplayData] = useState([] as (TankStatusHistoryModel | undefined)[]);

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  const navigate = useNavigate();
  const { orgId, siteId, tankId } = useParams();

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.TANK).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.TANK).hasCreateAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [lineChartData, setLineChartData] = useState({} as any);
  const [displayFilterData, setDisplayFilterData] = useState('Volume');
  const [displayTimeFilter, setDisplayTimeFilter] = useState('Last 7 Days');
  const [tankHistoryFilter, setTankHistoryFilter] = useState({} as TankStatusHistoryFilterModel);
  const [customDateFilterSelected, setCustomDateFilterSelected] = useState(false);

  const currentDate = new Date();
  const todayStart = new Date(currentDate);
  todayStart.setHours(0, 0, 0, 0);
  const todayEnd = new Date(currentDate);
  todayEnd.setHours(23, 59, 59, 999);
  const [minDateTimeXaxis, setMinDateTimeXaxis] = useState(new Date(todayStart).getTime());
  const [maxDateTimeXaxis, setMaxDateTimeXaxis] = useState(new Date(todayEnd).getTime());

  const [displayCurrentYAxis, setDisplayCurrentYAxis] = useState(true);
  const [displayTemperatureYAxis, setDisplayTemperatureYAxis] = useState(true);

  const decimation = {
    enabled: false,
    algorithm: 'min-max',
  };
  const grayAreaPlugin = {
    id: 'grayArea',
    beforeDatasetsDraw: (chart: any, args: any, options: any) => {
      const {
        ctx,
        chartArea: { top, bottom, left, right },
        scales: { x, y },
      } = chart;

      ctx.save();
      ctx.fillStyle = 'rgba(200, 200, 200, 0.5)';

      let started = false;
      let startX, startY, endX, endY;

      for (let i = 0; i < chart.data.labels.length; i++) {
        const value = chart.data.datasets[0].data[i];

        if (value === null && !started) {
          startX = x.getPixelForValue(i - 1);
          startY = y.getPixelForValue(chart.data.datasets[0].data[i - 1]);
          started = true;
        } else if (value !== null && started) {
          endX = x.getPixelForValue(i);
          endY = y.getPixelForValue(value);

          ctx.beginPath();
          ctx.moveTo(startX, top);
          ctx.lineTo(startX, startY);
          ctx.lineTo(endX, endY);
          ctx.lineTo(endX, top);
          ctx.closePath();
          ctx.fill();

          started = false;
        }
      }

      ctx.restore();
    },
  };
  const [timeScaleForXaxis, setTimeScaleForXaxis] = useState({
    parser: 'HH:mm:ss',
    unit: 'hour',
    displayFormats: {
      hour: 'HH:mm',
    } as any,
  });

  const tooltipLabel = (tooltipItem: any, data: any) => {
    if (tooltipItem?.dataset?.label?.endsWith('Volume')) {
      return `${tooltipItem?.dataset?.label}: ${tooltipItem?.formattedValue} ${getMeasureSign(
        tankHistoryData?.[0]?.product?.volume?.unitOfMeasure
      )}`;
    } else if (tooltipItem?.dataset?.label?.endsWith('Temperature')) {
      return `${tooltipItem?.dataset?.label}: ${tooltipItem?.formattedValue} ${getMeasureSign(
        tankHistoryData?.[0]?.temperature?.unitOfMeasure
      )}`;
    } else if (tooltipItem?.dataset?.label?.endsWith('Current')) {
      return `${tooltipItem?.dataset?.label}: ${tooltipItem?.formattedValue} ${getMeasureSign(
        tankHistoryData?.[0]?.current?.unitOfMeasure
      )}`;
    } else if (tooltipItem?.dataset?.label?.endsWith('Product Height')) {
      return `${tooltipItem?.dataset?.label}: ${tooltipItem?.formattedValue} ${getMeasureSign(
        tankHistoryData?.[0]?.product?.height?.unitOfMeasure
      )}`;
    }
  };

  const tooltipTitle = (tooltipItem: any, data: any) => {
    if (tooltipItem && tooltipItem?.length > 0) {
      return `${tooltipItem[0]?.label} ${dateTimeHelper.getBrowserTimezone()}`;
    }
  };

  const [options, setOptions] = useState({
    // Turn off animations and data parsing for performance
    animation: false,
    parsing: false,
    spanGaps: false,
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
      includeInvisible: false,
    },
    plugins: {
      decimation: decimation,
      legend: {
        position: 'bottom',
        labels: {
          color: '#fff',
          padding: 20,
          filter: function (item: any) {
            return !item?.text?.includes('No Data');
          },
        },
      },
      tooltip: {
        enabled: true,
        position: 'nearest',
        callbacks: {
          label: tooltipLabel,
          title: tooltipTitle,
        },
        filter: function (item: any) {
          return !item?.text?.includes('No Data');
        },
      },
      grayAreaPlugin,
    },
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        min: 0,
        max: tankInfo?.capacity?.value,
        ticks: {
          color: '#fff',
          maxTicksLimit: 6,
          callback: function (value: any) {
            return `${formatStringWith1DPlaces(value)} ${getMeasureSign(
              tankHistoryData?.[0]?.product?.volume?.unitOfMeasure
            )}`;
          },
        },
        border: {
          color: '#fff',
        },
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        min: 0,
        max: tankInfo?.capacity?.value,
        ticks: {
          color: '#fff',
          maxTicksLimit: 6,
          callback: function (value: any) {
            return `${formatStringWith1DPlaces(value)} ${getMeasureSign(
              tankHistoryData?.[0]?.product?.height?.unitOfMeasure
            )}`;
          },
        },
        border: {
          color: '#fff',
        },
      },
      y2: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          drawOnChartArea: false,
        },
        min: 0,
        max: 50,
        ticks: {
          color: '#fff',
          maxTicksLimit: 6,
          callback: function (value: any) {
            return `${formatStringWith1DPlaces(value)} ${getMeasureSign(
              tankHistoryData?.[0]?.temperature?.unitOfMeasure
            )}`;
          },
        },
        border: {
          color: '#fff',
        },
      },
      y3: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          drawOnChartArea: false,
        },
        min: 0,
        max: 30,
        ticks: {
          color: '#fff',
          maxTicksLimit: 6,
          callback: function (value: any) {
            return `${formatStringWith1DPlaces(value)} ${getMeasureSign(tankHistoryData?.[0]?.current?.unitOfMeasure)}`;
          },
        },
        border: {
          color: '#fff',
        },
      },
      x: {
        display: true,
        type: 'time',
        min: minDateTimeXaxis,
        max: maxDateTimeXaxis,
        time: timeScaleForXaxis,
        ticks: {
          source: 'auto',
          maxRotation: 0,
          autoSkip: true,
          maxTicksLimit: 5,
          color: '#fff',
        },
        border: {
          color: '#fff',
        },
      },
    },
  });

  const displayFilterList = ['Volume', 'Temperature Compensated Volume', 'Height'];
  const displayTimeList = [
    'Today',
    'Yesterday',
    'Last 7 Days',
    'Last 30 Days',
    'Last 3 Months',
    'Last 6 Months',
    'Custom',
  ];

  let dataFilterItems: FloatingMenuItem[] = [];
  displayFilterList?.map((it) => {
    if (it !== displayFilterData) {
      dataFilterItems.push({
        label: it,
        handler: () => {
          setDisplayFilterData(it);
        },
      });
      return;
    } else return;
  });

  let timeFilterItems: FloatingMenuItem[] = [];
  displayTimeList?.map((it) => {
    if (it !== displayTimeFilter || it === 'Custom') {
      timeFilterItems.push({
        label: it,
        handler: () => {
          setDisplayTimeFilter(it);
          if (it === 'Custom') {
            setCustomDateFilterSelected(false);
            openModal({
              type: ModalType.SEARCH,
              dataId: '',
            } as ModalStateModel);
          }
        },
      });
      return;
    } else return;
  });

  useMemo(() => {
    setLoading(tankHistoryStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(tankHistoryStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(tankHistoryStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, tankHistoryStatus]);

  useEffect(() => {
    if (tankId) {
      loadTankInfo(tankId);
      setTankHistoryFilter({
        tankId: tankId,
      } as TankStatusHistoryFilterModel);
    }
  }, [tankId]);

  useEffect(() => {
    setHeaderConfiguration({
      title:
        tankInfo && tankId === tankInfo?.id
          ? `Tank ${tankInfo?.number} (${tankInfo?.productName}) Status History`
          : 'Tank Status History',
      showCreateButton: false,
      showSiteHeader: true,
      showInfoButton: false,
      showAccountOption: true,
      error: error,
      pageURL: 'status-histories',
    } as HeaderStateModel);
  }, [authSuccess, hasCreateAccess, tankHistoryData, error, loading, tankInfo, tankId, setHeaderConfiguration]);

  useEffect(() => {
    const compareDatetime = (a: any, b: any) => {
      const dateA = new Date(a.lastUpdatedDateTime).getTime();
      const dateB = new Date(b.lastUpdatedDateTime).getTime();
      return dateA - dateB;
    };
    if (tankInfo) {
      setHistoryChartDisplayData(
        tankHistoryData
          ?.map((it) => {
            if (it && it.product) {
              if (displayFilterData === 'Volume' && it.product.volume?.value) return it;
              else if (
                displayFilterData === 'Temperature Compensated Volume' &&
                it.product.volume?.temperatureCompensatedValue
              )
                return it;
              else if (displayFilterData === 'Height' && it.product.height?.value) return it;
            }
            return undefined;
          })
          .filter(Boolean) // Remove undefined values
          .sort(compareDatetime)
      );
    }
  }, [tankInfo, tankHistoryData, displayFilterData]);

  useEffect(() => {
    setDisplayCurrentYAxis(historyChartDisplayData?.filter((it: any) => it?.current?.value)?.length > 0);
    setDisplayTemperatureYAxis(historyChartDisplayData?.filter((it: any) => it?.temperature)?.length > 0);
  }, [historyChartDisplayData]);

  const saveTankHistoryFilterHandler = (newFilter: TankStatusHistoryFilterModel) => {
    setCustomDateFilterSelected(true);
    setTankHistoryFilter(newFilter);
  };

  useEffect(() => {
    if (tankId && tankHistoryFilter?.tankId) {
      if (
        displayTimeFilter === 'Custom' &&
        tankHistoryFilter?.startDateTime &&
        tankHistoryFilter?.endDateTime &&
        !customDateFilterSelected
      ) {
        return;
      } else if (
        displayTimeFilter === 'Custom' &&
        tankHistoryFilter?.startDateTime &&
        tankHistoryFilter?.endDateTime &&
        customDateFilterSelected
      ) {
        const date1 = new Date(tankHistoryFilter?.endDateTime);
        const date2 = new Date(tankHistoryFilter?.startDateTime);
        const timeDifference = Math.abs(date1.getTime() - date2.getTime());
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

        setMinDateTimeXaxis(new Date(tankHistoryFilter?.startDateTime).getTime());
        setMaxDateTimeXaxis(new Date(tankHistoryFilter?.endDateTime).getTime());
        if (daysDifference <= 7)
          setTimeScaleForXaxis({
            parser: 'HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM dd HH:mm',
            } as any,
          });
        else if (daysDifference > 7 && daysDifference <= 90) {
          setTimeScaleForXaxis({
            parser: 'HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM dd',
            } as any,
          });
        } else if (daysDifference > 90) {
          setTimeScaleForXaxis({
            parser: 'HH:mm:ss',
            unit: 'day',
            displayFormats: {
              day: 'MMM dd',
            } as any,
          });
        }
        loadTankStatusHistories(tankHistoryFilter);
        return;
      }
      loadTankStatusHistories(tankHistoryFilter);
    }
  }, [tankId, tankHistoryFilter, customDateFilterSelected]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  function createDiagonalPattern(color = '#586169') {
    let shape = document.createElement('canvas');
    shape.width = 10;
    shape.height = 10;
    let c = shape.getContext('2d');
    if (c) {
      c.strokeStyle = color;
      c.lineWidth = 1;
      c.beginPath();
      c.moveTo(0, 0);
      c.lineTo(10, 10);
      c.stroke();
      return c.createPattern(shape, 'repeat');
    }
  }
  useEffect(() => {
    if (displayFilterData === 'Volume') {
      const productPointData = [];
      for (let i = 0; i < historyChartDisplayData?.length; i++) {
        if (historyChartDisplayData[i]?.lastUpdatedDateTime)
          productPointData.push({
            //@ts-ignore
            x: getTime(parseISO(historyChartDisplayData[i]?.lastUpdatedDateTime)),
            y: historyChartDisplayData[i]?.product?.volume?.value,
          });
      }

      const waterPointData = [];
      for (let i = 0; i < historyChartDisplayData?.length; i++) {
        if (historyChartDisplayData[i]?.lastUpdatedDateTime)
          waterPointData.push({
            //@ts-ignore
            x: getTime(parseISO(historyChartDisplayData[i]?.lastUpdatedDateTime)),
            y: historyChartDisplayData[i]?.water?.volume?.value,
          });
      }

      let temperaturePointDate = [];
      for (let i = 0; i < historyChartDisplayData?.length; i++) {
        if (historyChartDisplayData[i]?.lastUpdatedDateTime)
          temperaturePointDate.push({
            //@ts-ignore
            x: getTime(parseISO(historyChartDisplayData[i]?.lastUpdatedDateTime)),
            y: historyChartDisplayData[i]?.temperature?.value,
          });
      }
      temperaturePointDate = temperaturePointDate?.filter((it) => it.x && it.y);

      let currentPointData = [];
      for (let i = 0; i < historyChartDisplayData?.length; i++) {
        if (historyChartDisplayData[i]?.lastUpdatedDateTime)
          currentPointData.push({
            //@ts-ignore
            x: getTime(parseISO(historyChartDisplayData[i]?.lastUpdatedDateTime)),
            y: historyChartDisplayData[i]?.current?.value,
          });
      }
      currentPointData = currentPointData?.filter((it) => it.x && it.y);
      let lineChartData: any[] = [];

      if (!historyChartDisplayData || historyChartDisplayData?.length === 0) {
        // Adding No Data Section
        lineChartData.push({
          label: 'No Data',
          data: [
            { x: new Date(tankHistoryFilter?.startDateTime).getTime(), y: tankInfo?.capacity?.value ?? 1 },
            { x: new Date(tankHistoryFilter?.endDateTime).getTime(), y: tankInfo?.capacity?.value ?? 1 },
          ],
          backgroundColor: createDiagonalPattern('#586169'),
          borderColor: 'transparent',
          fill: 'origin',
          pointRadius: 0,
        });
      }

      if (temperaturePointDate && temperaturePointDate?.length > 0) {
        lineChartData.push({
          label: 'Temperature',
          data: temperaturePointDate,
          borderColor: '#ffffff',
          backgroundColor: '#ffffff',
          yAxisID: 'y2',
          lineTension: 0.1,
          pointStyle: 'circle',
          pointRadius: 1,
          pointHoverRadius: 2,
        });
      }
      if (currentPointData && currentPointData?.length > 0) {
        lineChartData.push({
          label: 'Current',
          data: currentPointData,
          borderColor: '#ffd700',
          backgroundColor: '#ffd700',
          yAxisID: 'y3',
          lineTension: 0.1,
          pointStyle: 'circle',
          pointRadius: 1,
          pointHoverRadius: 2,
        });
      }
      lineChartData.push(
        {
          label: 'Water Volume',
          data: waterPointData,
          borderColor: '#4C9BEE',
          backgroundColor: '#4C9BEE',
          yAxisID: 'y',
          lineTension: 0.1,
          fill: true,
          pointRadius: 1,
          pointHoverRadius: 2,
        },
        {
          label: 'Product Volume',
          data: productPointData,
          borderColor: '#F28337',
          backgroundColor: '#F28337',
          yAxisID: 'y',
          lineTension: 0.1,
          fill: {
            target: 'origin',
            above: 'rgba(242,131,55, 0.8)',
          },
          pointRadius: 1,
          pointHoverRadius: 2,
        }
      );
      setLineChartData({
        datasets: lineChartData,
      });
    } else if (displayFilterData === 'Temperature Compensated Volume') {
      const productPointData = [];
      for (let i = 0; i < historyChartDisplayData?.length; i++) {
        if (historyChartDisplayData[i]?.lastUpdatedDateTime)
          productPointData.push({
            //@ts-ignore
            x: getTime(parseISO(historyChartDisplayData[i]?.lastUpdatedDateTime)),
            y: historyChartDisplayData[i]?.product?.volume?.temperatureCompensatedValue,
          });
      }

      const waterPointData = [];
      for (let i = 0; i < historyChartDisplayData?.length; i++) {
        if (historyChartDisplayData[i]?.lastUpdatedDateTime)
          waterPointData.push({
            //@ts-ignore
            x: getTime(parseISO(historyChartDisplayData[i]?.lastUpdatedDateTime)),
            y: historyChartDisplayData[i]?.water?.volume?.temperatureCompensatedValue,
          });
      }

      let temperaturePointDate = [];
      for (let i = 0; i < historyChartDisplayData?.length; i++) {
        if (historyChartDisplayData[i]?.lastUpdatedDateTime)
          temperaturePointDate.push({
            //@ts-ignore
            x: getTime(parseISO(historyChartDisplayData[i]?.lastUpdatedDateTime)),
            y: historyChartDisplayData[i]?.temperature?.value,
          });
      }
      temperaturePointDate = temperaturePointDate?.filter((it) => it.x && it.y);

      let currentPointData = [];
      for (let i = 0; i < historyChartDisplayData?.length; i++) {
        if (historyChartDisplayData[i]?.lastUpdatedDateTime)
          currentPointData.push({
            //@ts-ignore
            x: getTime(parseISO(historyChartDisplayData[i]?.lastUpdatedDateTime)),
            y: historyChartDisplayData[i]?.current?.value,
          });
      }
      currentPointData = currentPointData?.filter((it) => it.x && it.y);

      let lineChartData: any[] = [];

      if (!historyChartDisplayData || historyChartDisplayData?.length === 0) {
        // Adding No Data Section
        lineChartData.push({
          label: 'No Data',
          data: [
            { x: new Date(tankHistoryFilter?.startDateTime).getTime(), y: tankInfo?.capacity?.value ?? 1 },
            { x: new Date(tankHistoryFilter?.endDateTime).getTime(), y: tankInfo?.capacity?.value ?? 1 },
          ],
          backgroundColor: createDiagonalPattern('#586169'),
          borderColor: 'transparent',
          fill: 'origin',
          pointRadius: 0,
        });
      }

      if (temperaturePointDate && temperaturePointDate?.length > 0) {
        lineChartData.push({
          label: 'Temperature',
          data: temperaturePointDate,
          borderColor: '#ffffff',
          backgroundColor: '#ffffff',
          yAxisID: 'y2',
          lineTension: 0.1,
          pointStyle: 'circle',
          pointRadius: 1,
          pointHoverRadius: 2,
        });
      }
      if (currentPointData && currentPointData?.length > 0) {
        lineChartData.push({
          label: 'Current',
          data: currentPointData,
          borderColor: '#ffd700',
          backgroundColor: '#ffd700',
          yAxisID: 'y3',
          lineTension: 0.1,
          pointStyle: 'circle',
          pointRadius: 1,
          pointHoverRadius: 2,
        });
      }
      lineChartData.push(
        {
          label: 'Water TC Volume',
          data: waterPointData,
          borderColor: '#4C9BEE',
          backgroundColor: '#4C9BEE',
          yAxisID: 'y',
          lineTension: 0.1,
          fill: true,
          pointRadius: 1,
          pointHoverRadius: 2,
        },
        {
          label: 'Product TC Volume',
          data: productPointData,
          borderColor: '#F28337',
          backgroundColor: '#F28337',
          yAxisID: 'y',
          lineTension: 0.1,
          fill: {
            target: 'origin',
            above: 'rgba(242,131,55, 0.8)',
          },
          pointRadius: 1,
          pointHoverRadius: 2,
        }
      );
      setLineChartData({
        datasets: currentPointData,
      });
    } else if (displayFilterData === 'Height') {
      const productPointData = [];
      for (let i = 0; i < historyChartDisplayData?.length; i++) {
        if (historyChartDisplayData[i]?.lastUpdatedDateTime)
          productPointData.push({
            //@ts-ignore
            x: getTime(parseISO(historyChartDisplayData[i]?.lastUpdatedDateTime)),
            y: historyChartDisplayData[i]?.product?.height?.value,
          });
      }

      const waterPointData = [];
      for (let i = 0; i < historyChartDisplayData?.length; i++) {
        if (historyChartDisplayData[i]?.lastUpdatedDateTime)
          waterPointData.push({
            //@ts-ignore
            x: getTime(parseISO(historyChartDisplayData[i]?.lastUpdatedDateTime)),
            y: historyChartDisplayData[i]?.water?.height?.value,
          });
      }

      let temperaturePointDate = [];
      for (let i = 0; i < historyChartDisplayData?.length; i++) {
        if (historyChartDisplayData[i]?.lastUpdatedDateTime)
          temperaturePointDate.push({
            //@ts-ignore
            x: getTime(parseISO(historyChartDisplayData[i]?.lastUpdatedDateTime)),
            y: historyChartDisplayData[i]?.temperature?.value,
          });
      }
      temperaturePointDate = temperaturePointDate?.filter((it) => it.x && it.y);

      let currentPointData = [];
      for (let i = 0; i < historyChartDisplayData?.length; i++) {
        if (historyChartDisplayData[i]?.lastUpdatedDateTime)
          currentPointData.push({
            //@ts-ignore
            x: getTime(parseISO(historyChartDisplayData[i]?.lastUpdatedDateTime)),
            y: historyChartDisplayData[i]?.current?.value,
          });
      }
      currentPointData = currentPointData?.filter((it) => it.x && it.y);

      let lineChartData: any[] = [];

      if (!historyChartDisplayData || historyChartDisplayData?.length === 0) {
        // Adding No Data Section
        lineChartData.push({
          label: 'No Data',
          data: [
            { x: new Date(tankHistoryFilter?.startDateTime).getTime(), y: tankInfo?.capacity?.value ?? 1 },
            { x: new Date(tankHistoryFilter?.endDateTime).getTime(), y: tankInfo?.capacity?.value ?? 1 },
          ],
          backgroundColor: createDiagonalPattern('#586169'),
          borderColor: 'transparent',
          fill: 'origin',
          pointRadius: 0,
        });
      }

      if (temperaturePointDate && temperaturePointDate?.length > 0) {
        lineChartData.push({
          label: 'Temperature',
          data: temperaturePointDate,
          borderColor: '#ffffff',
          backgroundColor: '#ffffff',
          yAxisID: 'y2',
          lineTension: 0.1,
          pointStyle: 'circle',
          pointRadius: 1,
          pointHoverRadius: 2,
        });
      }
      if (currentPointData && currentPointData?.length > 0) {
        lineChartData.push({
          label: 'Current',
          data: currentPointData,
          borderColor: '#ffd700',
          backgroundColor: '#ffd700',
          yAxisID: 'y3',
          lineTension: 0.1,
          pointStyle: 'circle',
          pointRadius: 1,
          pointHoverRadius: 2,
        });
      }
      lineChartData.push(
        {
          label: 'Water Height',
          data: waterPointData,
          borderColor: '#4C9BEE',
          backgroundColor: '#4C9BEE',
          yAxisID: 'y1',
          lineTension: 0.1,
          fill: true,
          pointRadius: 1,
          pointHoverRadius: 2,
        },
        {
          label: 'Product Height',
          data: productPointData,
          borderColor: '#F28337',
          backgroundColor: '#F28337',
          yAxisID: 'y1',
          lineTension: 0.1,
          fill: {
            target: 'origin',
            above: 'rgba(242,131,55, 0.8)',
          },
          pointRadius: 1,
          pointHoverRadius: 2,
        }
      );

      setLineChartData({
        datasets: lineChartData,
      });
    }
  }, [displayFilterData, historyChartDisplayData]);

  const onBackButtonClick = () => {
    navigate(`/organisations/${orgId}/sites/${siteId}/tanks`);
  };

  function formatStringWith1DPlaces(input: string): string | null {
    const parsedNumber = parseFloat(input);

    if (!isNaN(parsedNumber)) {
      const formattedString = parsedNumber % 1 === 0 ? parsedNumber.toString() : parsedNumber.toFixed(1);
      return formattedString;
    }

    return null;
  }

  useEffect(() => {
    {
      const currentDate = new Date();
      const dateTimeRange = [] as any[];

      if (displayTimeFilter === 'Today') {
        const todayStart = new Date(currentDate);
        todayStart.setHours(0, 0, 0, 0);

        const todayEnd = new Date(currentDate);
        todayEnd.setHours(23, 59, 59, 999);

        setTankHistoryFilter((prevstate) => {
          return {
            ...prevstate,
            dateRange: 'today',
            startDateTime: String(todayStart),
            endDateTime: String(todayEnd),
          };
        });
        setMinDateTimeXaxis(new Date(todayStart).getTime());
        setMaxDateTimeXaxis(new Date(todayEnd).getTime());
        setTimeScaleForXaxis({
          parser: 'HH:mm:ss',
          unit: 'hour',
          displayFormats: {
            hour: 'HH:mm',
          } as any,
        });
        dateTimeRange.push(convertDateStringtoUTCString(convertDateToString(todayStart)));
        dateTimeRange.push(convertDateStringtoUTCString(convertDateToString(todayEnd)));
      }

      if (displayTimeFilter === 'Yesterday') {
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);

        const yesterdayStart = new Date(yesterday);
        yesterdayStart.setHours(0, 0, 0, 0);

        const yesterdayEnd = new Date(yesterday);
        yesterdayEnd.setHours(23, 59, 59, 999);

        setTankHistoryFilter((prevstate) => {
          return {
            ...prevstate,
            dateRange: 'yesterday',
            startDateTime: String(yesterdayStart),
            endDateTime: String(yesterdayEnd),
          };
        });
        setMinDateTimeXaxis(new Date(yesterdayStart).getTime());
        setMaxDateTimeXaxis(new Date(yesterdayEnd).getTime());
        setTimeScaleForXaxis({
          parser: 'HH:mm:ss',
          unit: 'hour',
          displayFormats: {
            hour: 'HH:mm',
          } as any,
        });
        dateTimeRange.push(convertDateStringtoUTCString(convertDateToString(yesterdayStart)));
        dateTimeRange.push(convertDateStringtoUTCString(convertDateToString(yesterdayEnd)));
      }

      if (displayTimeFilter === 'Last 7 Days') {
        const last7DaysStart = new Date(currentDate);
        last7DaysStart.setDate(currentDate.getDate() - 6);

        const last7DaysEnd = new Date(currentDate);
        last7DaysEnd.setHours(23, 59, 59, 999);

        setTankHistoryFilter((prevstate) => {
          return {
            ...prevstate,
            dateRange: 'last7Days',
            startDateTime: String(last7DaysStart),
            endDateTime: String(last7DaysEnd),
          };
        });
        setMinDateTimeXaxis(new Date(last7DaysStart).getTime());
        setMaxDateTimeXaxis(new Date(last7DaysEnd).getTime());
        setTimeScaleForXaxis({
          parser: 'HH:mm:ss',
          unit: 'day',
          displayFormats: {
            day: 'MMM dd',
          } as any,
        });
        dateTimeRange.push(convertDateStringtoUTCString(convertDateToString(last7DaysStart)));
        dateTimeRange.push(convertDateStringtoUTCString(convertDateToString(last7DaysEnd)));
      }

      if (displayTimeFilter === 'Last 30 Days') {
        const last30DaysStart = new Date(currentDate);
        last30DaysStart.setDate(currentDate.getDate() - 29);

        const last30DaysEnd = new Date(currentDate);
        last30DaysEnd.setHours(23, 59, 59, 999);

        setTankHistoryFilter((prevstate) => {
          return {
            ...prevstate,
            dateRange: 'last30Days',
            startDateTime: String(last30DaysStart),
            endDateTime: String(last30DaysEnd),
          };
        });
        setMinDateTimeXaxis(new Date(last30DaysStart).getTime());
        setMaxDateTimeXaxis(new Date(last30DaysEnd).getTime());
        setTimeScaleForXaxis({
          parser: 'HH:mm:ss',
          unit: 'day',
          displayFormats: {
            day: 'MMM dd',
          } as any,
        });
        dateTimeRange.push(convertDateStringtoUTCString(convertDateToString(last30DaysStart)));
        dateTimeRange.push(convertDateStringtoUTCString(convertDateToString(last30DaysEnd)));
      }

      if (displayTimeFilter === 'Last 3 Months') {
        const last3MonthsStart = new Date(currentDate);
        last3MonthsStart.setMonth(currentDate.getMonth() - 3);

        const last3MonthsEnd = new Date(currentDate);
        last3MonthsEnd.setMonth(currentDate.getMonth() + 1, 0);

        setTankHistoryFilter((prevstate) => {
          return {
            ...prevstate,
            dateRange: 'last3Months',
            startDateTime: String(last3MonthsStart),
            endDateTime: String(last3MonthsEnd),
          };
        });
        setMinDateTimeXaxis(new Date(last3MonthsStart).getTime());
        setMaxDateTimeXaxis(new Date(last3MonthsEnd).getTime());
        setTimeScaleForXaxis({
          parser: 'HH:mm:ss',
          unit: 'day',
          displayFormats: {
            day: 'MMM dd',
          } as any,
        });
        dateTimeRange.push(convertDateStringtoUTCString(convertDateToString(last3MonthsStart)));
        dateTimeRange.push(convertDateStringtoUTCString(convertDateToString(last3MonthsEnd)));
      }

      if (displayTimeFilter === 'Last 6 Months') {
        const last6MonthsStart = new Date(currentDate);
        last6MonthsStart.setMonth(currentDate.getMonth() - 6);

        const last6MonthsEnd = new Date(currentDate);
        last6MonthsEnd.setMonth(currentDate.getMonth() + 1, 0);

        setTankHistoryFilter((prevstate) => {
          return {
            ...prevstate,
            dateRange: 'last6Months',
            startDateTime: String(last6MonthsStart),
            endDateTime: String(last6MonthsEnd),
          };
        });
        setMinDateTimeXaxis(new Date(last6MonthsStart).getTime());
        setMaxDateTimeXaxis(new Date(last6MonthsEnd).getTime());
        setTimeScaleForXaxis({
          parser: 'HH:mm:ss',
          unit: 'quarter',
          displayFormats: {
            quarter: 'MMM yyyy',
          } as any,
        });
        dateTimeRange.push(convertDateStringtoUTCString(convertDateToString(last6MonthsStart)));
        dateTimeRange.push(convertDateStringtoUTCString(convertDateToString(last6MonthsEnd)));
      }

      if (displayTimeFilter === 'Custom') {
        setMinDateTimeXaxis(new Date(tankHistoryFilter?.startDateTime).getTime());
        setMaxDateTimeXaxis(new Date(tankHistoryFilter?.endDateTime).getTime());
        dateTimeRange.push(convertDateStringtoUTCString(tankHistoryFilter?.startDateTime));
        dateTimeRange.push(convertDateStringtoUTCString(tankHistoryFilter?.endDateTime));
      }
      return;
    }
  }, [displayTimeFilter]);

  useEffect(() => {
    let minTemp = 0;
    if (tankHistoryData && tankHistoryData?.length > 0) {
      minTemp = Math.min(
        ...tankHistoryData?.map((it) => {
          return it?.temperature?.value;
        })
      );
    }

    let maxHeight = 0;
    if (tankHistoryData && tankHistoryData?.length > 0) {
      maxHeight = Math.max(
        ...tankHistoryData?.map((it) => {
          return it?.product?.height?.value;
        })
      );
    }

    setOptions({
      // Turn off animations and data parsing for performance
      animation: false,
      parsing: false,
      spanGaps: false,
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
        includeInvisible: false,
      },
      plugins: {
        decimation: decimation,
        legend: {
          position: 'bottom',
          labels: {
            color: '#fff',
            padding: 20,
            filter: function (item: any) {
              if (!historyChartDisplayData || historyChartDisplayData?.length === 0) {
                return item?.text?.includes('No Data');
              } else return true;
            },
          },
        },
        tooltip: {
          enabled: true,
          position: 'nearest',
          callbacks: {
            label: tooltipLabel,
            title: tooltipTitle,
          },
          filter: function (item: any) {
            return !item?.dataset?.label?.includes('No Data');
          },
        },
        grayAreaPlugin,
      },
      scales: {
        y: {
          type: 'linear' as const,
          display: displayFilterData !== 'Height',
          position: 'left' as const,
          min: 0,
          max: tankInfo?.capacity?.value,
          ticks: {
            color: '#fff',
            maxTicksLimit: 6,
            callback: function (value: any) {
              return `${formatStringWith1DPlaces(value)} ${getMeasureSign(
                tankHistoryData?.[0]?.product?.volume?.unitOfMeasure
              )}`;
            },
          },
          border: {
            color: '#fff',
          },
        },
        y1: {
          type: 'linear' as const,
          display: displayFilterData === 'Height',
          position: 'left' as const,
          min: 0,
          max: Math.ceil(maxHeight / 50) * 50,
          ticks: {
            color: '#fff',
            maxTicksLimit: 6,
            callback: function (value: any) {
              return `${formatStringWith1DPlaces(value)} ${getMeasureSign(
                tankHistoryData?.[0]?.product?.height?.unitOfMeasure
              )}`;
            },
          },
          border: {
            color: '#fff',
          },
        },
        y2: {
          type: 'linear' as const,
          display: displayTemperatureYAxis,
          position: 'right' as const,
          grid: {
            drawOnChartArea: false,
          },
          min:
            minTemp < 0 && minTemp >= -10
              ? -10
              : minTemp < -10 && minTemp >= -20
                ? -20
                : minTemp < -20 && minTemp >= -30
                  ? -30
                  : 0,
          max: 50,
          ticks: {
            color: '#fff',
            maxTicksLimit: 6,
            callback: function (value: any) {
              return `${formatStringWith1DPlaces(value)} ${getMeasureSign(
                tankHistoryData?.[0]?.temperature?.unitOfMeasure
              )}`;
            },
          },
          border: {
            color: '#fff',
          },
        },
        y3: {
          type: 'linear' as const,
          display: displayCurrentYAxis,
          position: 'right' as const,
          grid: {
            drawOnChartArea: false,
          },
          min: 0,
          max: 30,
          ticks: {
            color: '#fff',
            maxTicksLimit: 6,
            callback: function (value: any) {
              return `${formatStringWith1DPlaces(value)} ${getMeasureSign(
                tankHistoryData?.[0]?.current?.unitOfMeasure
              )}`;
            },
          },
          border: {
            color: '#fff',
          },
        },
        x: {
          display: true,
          type: 'time',
          min: minDateTimeXaxis,
          max: maxDateTimeXaxis,
          time: timeScaleForXaxis,
          ticks: {
            source: 'auto',
            maxRotation: 0,
            autoSkip: true,
            maxTicksLimit: 5,
            color: '#fff',
          },
          border: {
            color: '#fff',
          },
        },
      },
    });
  }, [
    minDateTimeXaxis,
    maxDateTimeXaxis,
    timeScaleForXaxis,
    tankHistoryData,
    tankInfo,
    displayCurrentYAxis,
    displayTemperatureYAxis,
    displayFilterData,
  ]);

  const drawLoadingOverlay = (chart: any) => {
    const { ctx, width, height } = chart;

    ctx.save();

    ctx.fillStyle = '#fff';
    ctx.font = '2em Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    const dotCount = (Math.floor(Date.now() / 300) % 4) + 3;
    ctx.fillText(`Loading${'.'.repeat(dotCount)}`, width / 2, height / 2);

    ctx.restore();
  };

  const loadingPlugin = {
    id: 'loadingPlugin',
    beforeDatasetsDraw: drawLoadingOverlay,
    afterDatasetsDraw: drawLoadingOverlay,
  };

  const [loadingChartOptions, setLoadingChartOptions] = useState({
    animation: false,
    parsing: false,
    spanGaps: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      decimation: decimation,
      grayAreaPlugin,
    },
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        min: 0,
        max: tankInfo?.capacity?.value,
        ticks: {
          display: false,
        },
        border: {
          color: '#fff',
        },
      },
      x: {
        display: true,
        type: 'time',
        min: minDateTimeXaxis,
        max: maxDateTimeXaxis,
        time: timeScaleForXaxis,
        ticks: {
          source: 'auto',
          maxRotation: 0,
          autoSkip: true,
          maxTicksLimit: 5,
          color: '#fff',
        },
        border: {
          color: '#fff',
        },
      },
    },
    layout: {
      padding: { left: 40, right: 20, bottom: 40 },
    },
  });

  useEffect(() => {
    setLoadingChartOptions({
      animation: false,
      parsing: false,
      spanGaps: false,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        decimation: decimation,
        grayAreaPlugin,
      },
      scales: {
        y: {
          type: 'linear' as const,
          display: true,
          position: 'left' as const,
          min: 0,
          max: tankInfo?.capacity?.value,
          ticks: {
            display: false,
          },
          border: {
            color: '#fff',
          },
        },
        x: {
          display: true,
          type: 'time',
          min: minDateTimeXaxis,
          max: maxDateTimeXaxis,
          time: timeScaleForXaxis,
          ticks: {
            source: 'auto',
            maxRotation: 0,
            autoSkip: true,
            maxTicksLimit: 5,
            color: '#fff',
          },
          border: {
            color: '#fff',
          },
        },
      },
      layout: {
        padding: { left: 40, right: 20, bottom: 40 },
      },
    });
  }, [
    minDateTimeXaxis,
    maxDateTimeXaxis,
    timeScaleForXaxis,
    tankHistoryData,
    tankInfo,
    displayCurrentYAxis,
    displayTemperatureYAxis,
    displayFilterData,
  ]);

  const loadingChartData = {
    datasets: [
      {
        label: 'Loading',
        data: [
          { x: new Date(tankHistoryFilter?.startDateTime).getTime(), y: tankInfo?.capacity?.value ?? 1 },
          { x: new Date(tankHistoryFilter?.endDateTime).getTime(), y: tankInfo?.capacity?.value ?? 1 },
        ],
        backgroundColor: createDiagonalPattern('#586169'),
        borderColor: 'transparent',
        fill: 'origin',
        pointRadius: 0,
      },
    ],
  };

  const Modal = useMemo(() => {
    return (
      <TankHistoryFilterForm
        tankHistoryFilterSetting={
          displayTimeFilter === 'Custom' ? ({ tankId: tankId } as TankStatusHistoryFilterModel) : tankHistoryFilter
        }
        saveTankHistoryFilter={saveTankHistoryFilterHandler}
      />
    );
  }, [tankHistoryFilter, displayTimeFilter]);

  return (
    <>
      {<>{Modal}</>}
      {!error && (
        <div className='chart-toolbar-container'>
          <div className='toolbar-back'> {<BackButton onClick={onBackButtonClick} />}</div>

          <div className='chart-toolbar-custom-button'>
            <FloatingMenu
              buttonNode={
                <CustomButton
                  className='chart-toolbar-button'
                  buttonStyle={ButtonStyle.TOOLBAR_FILTER}
                  icon={<ArrowDropDownIcon />}
                >
                  {displayFilterData}
                </CustomButton>
              }
              items={dataFilterItems}
            />

            <FloatingMenu
              buttonNode={
                <CustomButton
                  className='chart-toolbar-button'
                  buttonStyle={ButtonStyle.TOOLBAR_FILTER}
                  icon={<ArrowDropDownIcon />}
                >
                  {displayTimeFilter}
                </CustomButton>
              }
              items={timeFilterItems}
            />
          </div>
        </div>
      )}

      {(!authCheckCompleted || loading) && (
        <div className='loading-chart-container'>
          <CircularProgress className='loading-display' color='inherit' />
          <LineChart data={loadingChartData} options={loadingChartOptions} />
        </div>
      )}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}
      {error && <ErrorDisplayControl />}
      {!loading && !error && (
        <div className='tanks'>
          {historyChartDisplayData && historyChartDisplayData?.length > 0 && lineChartData && (
            <LineChart data={lineChartData} options={options} />
          )}
          {!!historyChartDisplayData && historyChartDisplayData.length < 1 && (
            <LineChart
              data={{
                datasets: [
                  {
                    label: 'No Data',
                    data: [
                      { x: new Date(tankHistoryFilter?.startDateTime).getTime(), y: tankInfo?.capacity?.value ?? 1 },
                      { x: new Date(tankHistoryFilter?.endDateTime).getTime(), y: tankInfo?.capacity?.value ?? 1 },
                    ],
                    backgroundColor: createDiagonalPattern('#586169'),
                    borderColor: 'transparent',
                    fill: 'origin',
                    pointRadius: 0,
                  },
                ],
              }}
              options={options}
            />
          )}
        </div>
      )}
      {
        <div className='for-mobile'>
          <FooterBar
            className={classNames(!!TankHistoryComponent && TankHistoryComponent.length < 1 ? 'footer-no-content' : '')}
          />
        </div>
      }
    </>
  );
};

export default TankHistoryComponent;
