export const LOAD_TERMINALS = 'compac/LOAD_TERMINALS';
export const RELOAD_TERMINALS = 'compac/RELOAD_TERMINALS';
export const DELETE_TERMINAL = 'compac/DELETE_TERMINAL';
export const CREATE_TERMINAL = 'compac/CREATE_TERMINAL';
export const EDIT_TERMINAL = 'compac/EDIT_TERMINAL';
export const LOAD_TERMINALINFO = 'compac/LOAD_TERMINALINFO';
export const SET_SELECTED_TERMINAL = 'compac/SET_SELECTED_TERMINAL';
export const REENROL_TERMINAL = 'compac/REENROL_TERMINAL';
export const LOAD_TERMINALNUMBER_LIST = 'compac/LOAD_TERMINALNUMBER_LIST';
export const CLEAR_TERMINAL_LIST_DATA = 'compac/CLEAR_TERMINAL_LIST_DATA';
export const CANCEL_TERMINAL_RELOAD = 'compac/CANCEL_TERMINAL_RELOAD';
