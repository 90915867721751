
import {initialState} from './transactionInitState';
import { TransactionFilterModel, TransactionModel } from '../../models/transactionModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';


const transactionSlice = createSlice({
    name: 'transactionState',
    initialState,
    reducers: {
        setTransactionState: (state, action: PayloadAction<string>) => {
            state.transactionStatus = action.payload;
            state.hasError = false;
        },
        setTransactionData: (state, action: PayloadAction<TransactionModel[]>) => {
            state.transactionData = action.payload;
            state.hasError = false;
        },
        setTransactionError: (state) => {
            state.hasError = true;
            state.transactionData = [];
            state.transactionStatus = LoadingStatus.ERROR;
        },   
        setTransactionFilter:(state, action: PayloadAction<TransactionFilterModel>) => {
            state.transactionFilter = action.payload;
            state.hasError = false;
        },
        setIsFilterActive:(state, action: PayloadAction<boolean>) => {
            state.isFilterActive = action.payload;            
        }
    }
});

export const {setTransactionState, setTransactionData, setTransactionError, setTransactionFilter, setIsFilterActive} = transactionSlice.actions;
export default transactionSlice.reducer;