import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const usePageLeaveListener = (cancelReloadFunction: () => void) => {
  const location = useLocation();
  const isInitialMount = useRef(true);

  useEffect(() => {
    const handleRouteLeave = () => {
      if (!isInitialMount.current) {
        // trigger cancel reload function
        cancelReloadFunction();
      }
      isInitialMount.current = false;
    };

    const handleBeforeUnload = (event: any) => {
      handleRouteLeave();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      handleRouteLeave();
    };
  }, [location]);
};
