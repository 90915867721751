import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import * as services from './services';
import * as actions from './actions';
import * as fieldHelper from '../../utilities/field-helper';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import { LoadingStatus } from '../../constants/loading-constants';
import { getApiErrorMessage, getGenericErrorMessage } from '../../utilities/errorhandler';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { setGenericErrorData } from '../generic-error/reducers';
import { Disclaimer, ReceiptTemplateItemEntity, ReceiptTemplateResponse } from '../../entities/receiptTemplate';
import {
  setReceiptTemplateStatus,
  setReceiptTemplateContent,
  setReceiptTemplateItem,
  setReceiptTemplateError,
} from './reducers';
import { selectOrganisationId } from '../auth/selectors';
import { PayloadAction } from '@reduxjs/toolkit';
import { setSnackBarError, setSnackBarSuccess } from '../snackbar/reducers';
import { Messages } from '../../constants/messages';
import { setDialogBoxActionStatus, closeDialogBox } from '../dialog-box/reducers';
import { clearAllFieldValidation } from '../fieldValidation/reducers';
import { showBackdrop, hideBackdrop, setBackDropActionStatus, setBackDropError } from '../backdrop/reducers';
import { setIsPageDirty } from '../page-configuration/reducers';
import { selectSelectedSiteId } from '../sites/selectors';
import { ReceiptTemplateItemModel } from '../../models/receiptTemplateModel';
import { loadBinRangeNameList } from '../bin-ranges/sagas';
import { selectBinRangeNameList } from '../bin-ranges/selectors';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_RECEIPTTEMPLATES, loadReceiptTemplates);
  yield takeLatest(actions.LOAD_RECEIPTTEMPLATE_INFO, loadReceiptTemplateInfo);
  yield takeLatest(actions.CREATE_RECEIPTTEMPLATE, createReceiptTemplate);
  yield takeLatest(actions.EDIT_RECEIPTTEMPLATE, editReceiptTemplate);
  yield takeLatest(actions.DELETE_RECEIPTTEMPLATE, deleteReceiptTemplate);
}

export function* loadReceiptTemplates() {
  try {
    yield put(setReceiptTemplateStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);
    const siteId: string = yield select(selectSelectedSiteId);
    let receiptTemplate_response: ReceiptTemplateResponse = yield call(
      services.getAllReceiptTemplate,
      organisationId,
      siteId
    );

    let binRangeNameList: KeyValuePair[] = [];

    let records: ReceiptTemplateItemModel[] = yield call(
      MapReceiptTemplateEntityToModelList,
      receiptTemplate_response,
      binRangeNameList
    );
    yield put(setReceiptTemplateContent(records));
    yield put(setReceiptTemplateStatus(LoadingStatus.SUCCESS));

    if (!binRangeNameList || binRangeNameList?.length <= 0) {
      yield call(loadBinRangeNameList);
      binRangeNameList = yield select(selectBinRangeNameList);

      let records: ReceiptTemplateItemModel[] = yield call(
        MapReceiptTemplateEntityToModelList,
        receiptTemplate_response,
        binRangeNameList
      );
      yield put(setReceiptTemplateContent(records));
    }
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setReceiptTemplateError());
  }
}

export function* loadReceiptTemplateInfo(action: PayloadAction<string>) {
  try {
    if (!!action.payload) {
      yield put(setReceiptTemplateStatus(LoadingStatus.LOADING));

      const organisationId: string = yield select(selectOrganisationId);
      let receiptTempalte_response: ReceiptTemplateItemEntity = yield call(
        services.getReceiptTemplateInfo,
        action.payload,
        organisationId
      );

      let binRangeNameList: KeyValuePair[] = yield select(selectBinRangeNameList);
      if (!binRangeNameList) {
        yield call(loadBinRangeNameList);
        binRangeNameList = yield select(selectBinRangeNameList);
      }

      let receiptTemplateInfo: ReceiptTemplateItemModel = yield call(
        MapReceiptTemplateEntityToModel,
        receiptTempalte_response,
        binRangeNameList
      );
      yield put(setReceiptTemplateItem(receiptTemplateInfo));
      yield put(setReceiptTemplateStatus(LoadingStatus.SUCCESS));
    }
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setReceiptTemplateError());
    yield put(clearAllFieldValidation());
  }
}

export function* createReceiptTemplate(action: PayloadAction<ReceiptTemplateItemModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    let templateEntity = MapReceiptTemplateModelToEntity(action.payload);
    yield call(services.createReceiptTemplate, templateEntity);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.RECEIPT_TEMPLATE_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* editReceiptTemplate(action: PayloadAction<ReceiptTemplateItemModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    let templateEntity = MapReceiptTemplateModelToEntity(action.payload);
    templateEntity.organisationId = yield select(selectOrganisationId);
    yield call(services.editReceiptTemplate, templateEntity, action.payload.id);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.RECEIPT_TEMPLATE_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* deleteReceiptTemplate(action: PayloadAction<string>) {
  try {
    yield put(setDialogBoxActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    yield call(services.deleteReceiptTemplate, action.payload, organisationId);
    yield put(closeDialogBox());
    yield put(setSnackBarSuccess(Messages.RECEIPT_TEMPLATE_DELETE_SUCCESS));
    yield call(loadReceiptTemplates);
  } catch (error) {
    yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export const MapReceiptTemplateEntityToModelList = (
  receiptTempalte_response: ReceiptTemplateResponse,
  binRangeNameList: KeyValuePair[]
) => {
  if (!!receiptTempalte_response && receiptTempalte_response?.items?.length > 0) {
    const result: ReceiptTemplateItemModel[] = receiptTempalte_response?.items?.map((item) => {
      let matchBinRanges = item?.binRanges
        ?.map((s) => {
          let tempGroup = binRangeNameList?.find((e) => e.key === s);
          return tempGroup;
        })
        ?.filter((tempGroup) => tempGroup !== undefined);
      return {
        id: item?.id,
        organisationId: item?.organisationId,
        siteId: item?.siteId,
        name: fieldHelper.getDefaultStringvalue(item?.name),
        binRanges: item?.binRanges,
        binRangeInfos: matchBinRanges?.map((data: any) => {
          return {
            id: data?.key,
            name: fieldHelper.getDefaultStringvalue(data?.value),
          };
        }),
        unitPriceFormat: item?.unitPriceFormat,
        disclaimer: item?.disclaimer,
        headers: item?.headers,
        footers: item?.footers,
      };
    });
    return result;
  }
  return [] as ReceiptTemplateItemModel[];
};

export const MapReceiptTemplateEntityToModel = (
  receiptTempalte_response: ReceiptTemplateItemEntity,
  binRange_response: KeyValuePair[]
) => {
  let matchBinRanges = receiptTempalte_response?.binRanges?.map((s, i) => {
    let tempGroup = binRange_response?.find((e) => e.key === s);
    return tempGroup;
  });
  if (receiptTempalte_response.id) {
    return {
      id: receiptTempalte_response.id,
      organisationId: receiptTempalte_response.organisationId,
      siteId: receiptTempalte_response.siteId,
      name: receiptTempalte_response.name,
      binRanges: receiptTempalte_response.binRanges,
      binRangeInfos: matchBinRanges?.map((data: any) => {
        return {
          id: data?.key,
          name: fieldHelper.getDefaultStringvalue(data?.value),
        };
      }),
      unitPriceFormat: receiptTempalte_response.unitPriceFormat,
      disclaimer: receiptTempalte_response.disclaimer,
      headers: receiptTempalte_response.headers,
      footers: receiptTempalte_response.footers,
    } as ReceiptTemplateItemModel;
  } else throw new Error('Not a valid Receipt Template API Response');
};

export const MapReceiptTemplateModelToEntity = (model: ReceiptTemplateItemModel) => {
  if (model) {
    let updatedDisclaimer = {} as Disclaimer;
    // Remove empty spaces before the new line symbol
    if (model.disclaimer?.credit) {
      updatedDisclaimer.credit = model.disclaimer?.credit.replace(/(\s+)(?=\n)/g, '');
    }
    if (model.disclaimer?.eftpos) {
      updatedDisclaimer.eftpos = model.disclaimer?.eftpos.replace(/(\s+)(?=\n)/g, '');
    }
    let receiptTemplateEmtity = {
      organisationId: fieldMappingHelper.sanitizeStringValue(model.organisationId),
      siteId: fieldMappingHelper.sanitizeStringValue(model.siteId),
      binRanges: model.binRanges,
      name: fieldMappingHelper.sanitizeStringValue(model.name),
      unitPriceFormat: model.unitPriceFormat,
      disclaimer: updatedDisclaimer,
      headers: model.headers,
      footers: model.footers,
    } as ReceiptTemplateItemEntity;

    return receiptTemplateEmtity;
  }

  return {} as ReceiptTemplateItemEntity;
};
