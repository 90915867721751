import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from '../../utilities/field-helper';
import { ModuleName } from '../../constants/module-constants';
import { Messages } from '../../constants/messages';
import { LoadingStatus } from '../../constants/loading-constants';
import { NavPaneStates } from '../../constants/window-constants';
import { ButtonStyle } from '../../constants/button-constants';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { CardFilterModel, CardModel, CardTagModel } from '../../models/cardModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { FilterDetail } from '../../models/baseModels/searchBarFilterModel';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import { BinRangeItemListModel } from '../../models/binRangeModel';
import { getBrowserZoomLevel } from '../../utilities/general-helper';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import EmptyList from '../../components/empty-list/empty-list';
import FooterBar from '../../components/footer/footer';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import DialogBoxComponent from '../../components/dialog-box/dialog-box.container';
import MessageDisplay from '../../components/message-display/message-display';
import CardItem from './card-item';
import classNames from 'classnames';
import FilterGroup from '../../components/filter-group/filter-group';
import CustomButton from '../../components/button/custom-button';

interface CardsProps {
  navPaneState: string;
  authStatus: string;
  cardList: CardModel[];
  cardStatus: string;
  isPageReachBottom: boolean;
  siteListName: KeyValuePair[];
  binRangeListName: KeyValuePair[];
  binRangeList: BinRangeItemListModel[];
  cardFilterSetting: CardFilterModel;
  initLoadCardList: (data?: CardFilterModel) => void;
  loadCardList: (data?: CardFilterModel) => void;
  clearCardList: () => void;
  deleteCard: (id: string) => void;
  userAccess: (moduleName: string) => AuthorisationModel;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  openDialogBox: () => void;
  closeDialogBox: () => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  saveCardFuzzySearch: (data: string) => void;
  clearContinuationToken: () => void;
  setIsScrollRequest: (data: boolean) => void;
  clearSelectedCard: () => void;
}

const Cards: React.FC<CardsProps> = (props: CardsProps) => {
  const {
    navPaneState,
    authStatus,
    cardList,
    cardStatus,
    isPageReachBottom,
    siteListName,
    binRangeListName,
    binRangeList,
    cardFilterSetting,
    initLoadCardList,
    loadCardList,
    clearCardList,
    deleteCard,
    userAccess,
    setHeaderConfiguration,
    openDialogBox,
    closeDialogBox,
    setPageConfiguration,
    setIsPageDirty,
    saveCardFuzzySearch,
    clearContinuationToken,
    setIsScrollRequest,
    clearSelectedCard,
  } = props;

  const navigate = useNavigate();
  const { orgId } = useParams();
  const statusOptions = ['Enabled', 'Disabled'];
  const isInitLoadCardListRequested = useRef(false);

  const [cardDataToDisplay, setCardDataToDisplay] = useState(cardList);
  const [deleteContext, setDeleteContent] = useState('');
  const [selectedCardId, setSelectedCardId] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [cardFilter, setCardFilter] = useState(cardFilterSetting);
  const [displayStatusFilter, setDisplayStatusFilter] = useState('Default');
  const [displayBinRangeFilter, setDisplayBinRangeFilter] = useState('Default');
  const [displaySiteFilter, setDisplaySiteFilter] = useState('Default');
  const [displayCardTagFilter, setDisplayCardTagFilter] = useState('Default');
  const [statusFilterItems, setStatusFilterItems] = useState([] as FloatingMenuItem[]);
  const [binRangeFilterItems, setBinRangeFilterItems] = useState([] as FloatingMenuItem[]);
  const [siteFilterItems, setSiteFilterItems] = useState([] as FloatingMenuItem[]);
  const [cardTagFilterItems, setCardTagFilterItems] = useState([] as FloatingMenuItem[]);
  const [isInitLoad, setIsInitLoad] = useState(false);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [cardListToDisplay, setCardListToDisplay] = useState([] as any);
  const [cardTagFilterList, setCardTagFilterList] = useState([{ key: '', value: '' }] as KeyValuePair[]);
  const [isFuzzySearchTriggered, setIsFuzzySearchTriggered] = useState(false);

  const [filterDetails, setFilterDetails] = useState([
    {
      filterName: 'Status',
      filterOptions: [],
      selectedOption: 'default',
      displayValue: displayStatusFilter,
    },
    {
      filterName: 'BIN Range',
      filterOptions: [],
      selectedOption: 'default',
      displayValue: displayBinRangeFilter,
    },
    {
      filterName: 'Site',
      filterOptions: [],
      selectedOption: 'default',
      displayValue: displaySiteFilter,
    },
    {
      filterName: 'Card Tag',
      filterOptions: [],
      selectedOption: 'default',
      displayValue: displayCardTagFilter,
      keyValuePair: true,
    },
  ] as FilterDetail[]);

  useEffect(() => {
    if (
      cardFilterSetting?.binRangeId &&
      cardFilterSetting?.binRangeId !== 'Default' &&
      cardFilterSetting?.binRangeId !== 'undefined'
    ) {
      setDisplayBinRangeFilter(
        String(binRangeListName?.find((it) => it?.key === cardFilterSetting?.binRangeId)?.value)
      );
    }
    if (
      cardFilterSetting?.siteId &&
      cardFilterSetting?.siteId !== 'Default' &&
      cardFilterSetting?.siteId !== 'undefined'
    ) {
      setDisplaySiteFilter(String(siteListName?.find((it) => it?.key === cardFilterSetting?.siteId)?.value));
    }
    if (cardFilterSetting?.status && cardFilterSetting?.status !== 'Default') {
      setDisplayStatusFilter(cardFilterSetting?.status);
    }
    if (cardFilterSetting?.tags && cardFilterSetting?.tags.length > 0) {
      setCardTagFilterList(cardFilterSetting?.tags);

      if (cardFilterSetting?.tags && cardFilterSetting?.tags?.length > 0) {
        const combineKeys = (tags: CardTagModel[]): string => {
          return `Tags: ${tags.map((tag) => tag.key).join(', ')}`;
        };

        setDisplayCardTagFilter(combineKeys(cardFilterSetting?.tags));
      }
    }
  }, []);

  useEffect(() => {
    let newStatusFilter = [] as FloatingMenuItem[];
    statusOptions?.map((it) => {
      newStatusFilter.push({
        label: it,
        isSelected: it === displayStatusFilter,
        handler: () => {
          setDisplayStatusFilter(it);
        },
      });
    });
    setStatusFilterItems(newStatusFilter);
  }, [displayStatusFilter, setDisplayStatusFilter, setStatusFilterItems]);

  useEffect(() => {
    let newBinRangeFilter = [] as FloatingMenuItem[];
    binRangeListName?.map((it) => {
      newBinRangeFilter.push({
        label: String(it.value),
        isSelected: String(it.value) === displayBinRangeFilter,
        handler: () => {
          setDisplayBinRangeFilter(String(it.value));
        },
      });
    });
    setBinRangeFilterItems(newBinRangeFilter);
  }, [binRangeListName, displayBinRangeFilter, setDisplayBinRangeFilter, setBinRangeFilterItems]);

  useEffect(() => {
    let newSiteFilter = [] as FloatingMenuItem[];
    siteListName?.map((it) => {
      newSiteFilter.push({
        label: String(it.value),
        isSelected: String(it.value) === displaySiteFilter,
        handler: () => {
          setDisplaySiteFilter(String(it.value));
        },
      });
    });
    setSiteFilterItems(newSiteFilter);
  }, [siteListName, displaySiteFilter, setDisplaySiteFilter, setSiteFilterItems]);

  useEffect(() => {
    const createMenuItem = (tag: { key: string | number }, isSelected: boolean) => ({
      label: String(tag.key),
      isSelected,
      handler: () => setDisplayCardTagFilter(String(tag.key)),
    });

    let newCardTagFilter: FloatingMenuItem[] = [];

    if (displayBinRangeFilter !== 'Default') {
      const selectedBinRangeList = binRangeList?.find((it) => it?.name === displayBinRangeFilter);

      if (selectedBinRangeList?.cardTags?.length) {
        newCardTagFilter = selectedBinRangeList.cardTags.map((tag) =>
          createMenuItem(tag, String(tag.key) === displayCardTagFilter)
        );
      } else {
        newCardTagFilter = [
          {
            label: '',
            isSelected: false,
            handler: () => setDisplayCardTagFilter('Default'),
          },
        ];
      }
    } else {
      newCardTagFilter =
        binRangeList?.flatMap(
          (it) => it?.cardTags?.map((tag) => createMenuItem(tag, String(tag.key) === displayCardTagFilter)) || []
        ) || [];
    }

    setCardTagFilterItems(newCardTagFilter);
  }, [binRangeList, displayBinRangeFilter, displayCardTagFilter, setDisplayCardTagFilter, setCardTagFilterItems]);

  const searchInputRef = useRef<HTMLInputElement>(null!);
  const debouncedValue = useDebounce<string>(searchKeyword, 200);
  const emptyListMessage = 'No cards found';

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);
  const [hasCardBulkReadAccess, setHasCardBulkReadAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.CARD).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.CARD).hasCreateAccess);
    setHasDeleteAccess(userAccess(ModuleName.CARD).hasDeleteAccess);
    setHasUpdateAccess(userAccess(ModuleName.CARD).hasUpdateAccess);
    setHasCardBulkReadAccess(userAccess(ModuleName.CARD_OPERATION).hasReadAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(cardStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(cardStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(cardStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, cardStatus]);

  useEffect(() => {
    setHeaderConfiguration({
      showOrganisation: true,
      title: 'Cards',
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, authSuccess, hasCreateAccess, error]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  useEffect(() => {
    if (!isInitLoadCardListRequested.current && authSuccess && hasReadAccess) {
      clearCardList();
      setSearchKeyword('');
      saveCardFuzzySearch('');
      const browserZoomLevel = getBrowserZoomLevel();
      initLoadCardList({
        limit: browserZoomLevel < 0.8 ? Math.trunc(50 / browserZoomLevel) : undefined,
      } as CardFilterModel);
      setCardListToDisplay([]);
      setCardFilter((prevstate) => {
        return {
          ...prevstate,
          limit: Math.trunc(50 / browserZoomLevel),
        };
      });
      setIsInitLoad(true);
      clearSelectedCard();
      isInitLoadCardListRequested.current = true;
    }
  }, [authSuccess, hasReadAccess, cardFilterSetting, initLoadCardList]);

  // Adds a resize event listener to the window. When the window size changes, the event listener reloads the events
  useEffect(() => {
    window.addEventListener('resize', browserResizeHandler);
    return () => window.removeEventListener('resize', browserResizeHandler);
  }, []);

  const browserResizeHandler = () => {
    const browserZoomLevel = getBrowserZoomLevel();
    if (browserZoomLevel < 0.8) {
      setIsFilterUpdated(true);
      setCardFilter((prevstate) => {
        return {
          ...prevstate,
          limit: Math.trunc(50 / browserZoomLevel),
        };
      });
    }
  };

  useEffect(() => {
    setCardDataToDisplay(cardList);
  }, [cardList]);

  useEffect(() => {
    setIsScrollRequest(true);
    if (isPageReachBottom) {
      setIsInitLoad(false);
      setTimeout(() => {
        if (cardFilter) loadCardList(cardFilter);
        else loadCardList();
      }, 10);
    }
  }, [isPageReachBottom]);

  useEffect(() => {
    if (searchKeyword && isFuzzySearchTriggered) {
      initLoadCardList(cardFilter);
      setCardListToDisplay([]);
      setIsInitLoad(true);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (isFilterUpdated && !isFuzzySearchTriggered) {
      setIsInitLoad(true);
      initLoadCardList(cardFilter);
      setCardListToDisplay([]);
    }
  }, [cardFilter, isFilterUpdated, isFuzzySearchTriggered]);

  useEffect(() => {
    setFilterDetails([
      {
        filterName: 'Status',
        filterOptions: statusFilterItems,
        selectedOption: 'default',
        displayValue: displayStatusFilter,
      },
      {
        filterName: 'BIN Range',
        filterOptions: binRangeFilterItems,
        selectedOption: 'default',
        displayValue: displayBinRangeFilter,
      },
      {
        filterName: 'Site',
        filterOptions: siteFilterItems,
        selectedOption: 'default',
        displayValue: displaySiteFilter,
      },
      {
        filterName: 'Card Tags',
        filterOptions: cardTagFilterItems,
        selectedOption: 'default',
        displayValue: displayCardTagFilter,
        keyValuePair: true,
        keyValueList: cardTagFilterList,
      },
    ] as FilterDetail[]);
  }, [
    statusFilterItems,
    binRangeFilterItems,
    siteFilterItems,
    cardTagFilterItems,
    displayStatusFilter,
    displayBinRangeFilter,
    displaySiteFilter,
    displayCardTagFilter,
    cardTagFilterList,
  ]);

  useEffect(() => {
    if (!isFilterUpdated && displayStatusFilter === 'Default') return;

    if (displayStatusFilter === 'Default') {
      if (isFilterUpdated) {
        setCardFilter((prevstate) => {
          return {
            ...prevstate,
            status: displayStatusFilter,
          };
        });
        return;
      } else return;
    }

    setIsFilterUpdated(true);
    setIsFuzzySearchTriggered(false);
    setCardFilter((prevstate) => {
      return {
        ...prevstate,
        status: displayStatusFilter,
      };
    });
  }, [displayStatusFilter]);

  useEffect(() => {
    if (!isFilterUpdated && displayBinRangeFilter === 'Default') return;
    const selectedBinRangeId = String(binRangeListName?.find((it) => it?.value == displayBinRangeFilter)?.key);
    setIsFilterUpdated(true);
    setIsFuzzySearchTriggered(false);
    setCardFilter((prevstate) => {
      return {
        ...prevstate,
        binRangeId: selectedBinRangeId,
      };
    });
  }, [displayBinRangeFilter]);

  useEffect(() => {
    if (!isFilterUpdated && displaySiteFilter === 'Default') return;
    const selectedSiteId = String(siteListName?.find((it) => it?.value == displaySiteFilter)?.key);
    setIsFilterUpdated(true);
    setIsFuzzySearchTriggered(false);
    setCardFilter((prevstate) => {
      return {
        ...prevstate,
        siteId: selectedSiteId,
      };
    });
  }, [displaySiteFilter]);

  useEffect(() => {
    if (cardList && cardList?.length > 0 && (isInitLoad ? isInitLoad && !loading : true)) {
      const displayCardList = cardList?.map((card) => (
        <CardItem
          onClickHandler={onClickHandler}
          onDelete={onDeleteHandler}
          onEdit={onEditHandler}
          key={card.id}
          cardItem={card}
          hasDeleteAccess={hasDeleteAccess}
          hasUpdateAccess={hasUpdateAccess}
        ></CardItem>
      ));
      setCardListToDisplay(displayCardList);
    } else if (cardList?.length === 0) {
      const cardList = [] as any[];
      setCardListToDisplay(cardList);
    }
  }, [cardList, isInitLoad, loading]);

  /** DIALOG BUTTONS */
  const confirmDialog = () => {
    deleteCard(selectedCardId);
  };

  const cancelDialog = () => {
    closeDialogBox();
  };

  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    let enteredSearchValue = searchInputRef.current?.value.trim();
    clearContinuationToken();
    setIsScrollRequest(false);
    if (enteredSearchValue === '') {
      clearCardList();
      setSearchKeyword('');
      saveCardFuzzySearch('');
      initLoadCardList(cardFilter);
      setCardListToDisplay([]);
    }
    setSearchKeyword(enteredSearchValue);
    saveCardFuzzySearch(enteredSearchValue);
    setIsFuzzySearchTriggered(true);
    window.scrollTo(0, 0);
  };

  /** ACTION MENU (Create, Edit, Delete) */
  const onCreateHandler = () => {
    navigate(`/organisations/${orgId}/cards/create`);
  };

  const onBulkHandler = () => {
    navigate(`/organisations/${orgId}/cards/card-operations`);
  };

  const onDeleteHandler = useCallback(
    (id: string, name: string) => {
      setSelectedCardId(id);
      setDeleteContent(`Are you sure you want to delete ${name}?`);
      openDialogBox();
    },
    [openDialogBox, setDeleteContent]
  );

  const onEditHandler = (id: string) => {
    setSelectedCardId(id);
    navigate(`/organisations/${orgId}/cards/${id}/details/edit`);
  };

  const onClickHandler = (id: string) => {
    navigate(`/organisations/${orgId}/cards/${id}/details`);
  };

  const clearSearchHandler = (filterName: string) => {
    if (filterName === 'Status') {
      setDisplayStatusFilter('Default');
    }
    if (filterName === 'BIN Range') {
      setDisplayBinRangeFilter('Default');
    }
    if (filterName === 'Site') {
      setDisplaySiteFilter('Default');
    }
    if (filterName === 'Card Tag') {
      setDisplayCardTagFilter('Default');
    }
  };

  const clearFiltersHandler = () => {
    setSearchKeyword(' ');
    saveCardFuzzySearch(' ');
    setDisplayStatusFilter('Default');
    setDisplayBinRangeFilter('Default');
    setDisplaySiteFilter('Default');
    setDisplayCardTagFilter('Default');
    setCardTagFilterList([]);
    setIsFilterUpdated(true);
  };

  const keyValueListUpdateHandler = useCallback(
    (newValue: KeyValuePair) => {
      if (newValue?.key?.toString()?.includes('key')) {
        let updatedKeyIndex = Number(newValue?.key?.toString()?.replace('key', ''));

        setCardTagFilterList((prevList) => {
          const updatedList = [...(prevList || [])];
          updatedList[updatedKeyIndex] = {
            key: String(newValue.value),
            value: updatedList[updatedKeyIndex]?.value || '',
          };
          return updatedList;
        });
        return;
      }

      if (newValue?.key?.toString()?.includes('value')) {
        let updatedKeyIndex = Number(newValue?.key?.toString()?.replace('value', ''));

        setCardTagFilterList((prevList) => {
          const updatedList = [...(prevList || [])];
          updatedList[updatedKeyIndex] = {
            key: updatedList[updatedKeyIndex]?.key || '',
            value: String(newValue.value),
          };
          return updatedList;
        });
        return;
      }

      // Add a new tag if the key doesn't include "key" or "value"
      setCardTagFilterList((prevList) => [...(prevList || []), newValue]);
    },
    [setCardTagFilterList]
  );

  const keyValueListRemoveHandler = useCallback(
    (index: number) => {
      let tags = cardTagFilterList ? [...cardTagFilterList] : [];
      tags?.splice(index, 1);
      setCardTagFilterList(tags);

      if (tags?.length === 0) {
        setIsFilterUpdated(true);
        setCardFilter((prevstate) => {
          return {
            ...prevstate,
            tags: [],
          };
        });
        setDisplayCardTagFilter('Default');
      }
    },
    [cardTagFilterList]
  );

  useEffect(() => {
    if (cardTagFilterList?.length === 0 && cardFilter?.tags?.length !== 0) {
      setCardFilter((prevstate) => {
        return {
          ...prevstate,
          tags: [],
        };
      });
    }
  }, [cardTagFilterList, cardFilter?.tags?.length]);

  const handleApplyFilterHandler = () => {
    const tagList: CardTagModel[] = cardTagFilterList?.map((item) => {
      return {
        key: item.key,
        value: item.value,
      } as CardTagModel;
    });
    setIsFilterUpdated(true);
    setCardFilter((prevstate) => {
      return {
        ...prevstate,
        tags: tagList,
      };
    });

    if (tagList && tagList?.length > 0) {
      const combineKeys = (tags: CardTagModel[]): string => {
        return `Tags: ${tags.map((tag) => tag.key).join(', ')}`;
      };

      setDisplayCardTagFilter(combineKeys(tagList));
    }
  };

  return (
    <>
      {
        <DialogBoxComponent
          context={deleteContext}
          closeTextButton='No'
          confirmTextButton='Yes'
          confirmDialog={confirmDialog}
          onClose={cancelDialog}
          header='Delete Card'
        />
      }

      {error && <ErrorDisplayControl />}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}
      {
        <div className={classNames('cards')}>
          {!error && (
            <div
              className={classNames('search-container', {
                'list-expanded-width': navPaneState === NavPaneStates.EXPANDED,
                'list-collapsed-width':
                  navPaneState === NavPaneStates.COLLAPSED || navPaneState === NavPaneStates.COLLAPSED_AND_FLOATING,
                'list-hamburger-width':
                  navPaneState === NavPaneStates.HAMBURGER || navPaneState === NavPaneStates.HAMBURGER_AND_FLOATING,
              })}
            >
              <div className='filter-list'>
                {
                  <FilterGroup
                    searchBarPlaceholder='Search PAN'
                    searchRef={searchInputRef}
                    filterDetails={filterDetails}
                    clearSearch={clearSearchHandler}
                    clearFilters={clearFiltersHandler}
                    onKeyUp={onKeyUpHandler}
                    keyValueListUpdate={keyValueListUpdateHandler}
                    keyValueListRemove={keyValueListRemoveHandler}
                    handleApplyFilter={handleApplyFilterHandler}
                  />
                }
                {hasCardBulkReadAccess && (
                  <CustomButton
                    className={classNames('create-button')}
                    buttonStyle={ButtonStyle.CREATE}
                    onClick={onBulkHandler}
                  >
                    Bulk
                  </CustomButton>
                )}
                {hasCreateAccess && (
                  <CustomButton
                    className={classNames('create-button')}
                    buttonStyle={ButtonStyle.CREATE}
                    onClick={onCreateHandler}
                  >
                    Create New
                  </CustomButton>
                )}
              </div>
              {cardDataToDisplay?.length > 0 && (
                <div className='card-list-header'>
                  <div className='card-pan'>PAN</div>
                  <div className='card-status'>Status</div>
                  <div className='associated-bin-ranges'>BIN Range</div>
                  <div className='card-allowed-sites'>Sites</div>
                </div>
              )}
            </div>
          )}
          <div className='card-list'>
            <div className='card-list-container'>
              {searchKeyword.length > 0
                ? searchKeyword && !loading && cardListToDisplay
                : (displayStatusFilter !== 'Default' ||
                      displayBinRangeFilter !== 'Default' ||
                      displaySiteFilter !== 'Default' ||
                      displayCardTagFilter !== 'Default') &&
                    isInitLoad
                  ? !loading && cardListToDisplay
                  : cardListToDisplay}
            </div>
            {(!authCheckCompleted || loading) && (
              <div className='card-list-loading-container'>
                <LoadingDisplay />
              </div>
            )}
          </div>

          {!loading && success && !!cardList && !!cardDataToDisplay && cardDataToDisplay?.length < 1 && (
            <div className='empty-div-container-extra-spacing'>
              <EmptyList message={emptyListMessage}></EmptyList>
            </div>
          )}
        </div>
      }
      {
        <div className='for-mobile'>
          <FooterBar
            className={classNames(
              !!cardList && !!cardDataToDisplay && cardDataToDisplay?.length < 1
                ? 'footer-no-content'
                : 'footer-with-content'
            )}
          />
        </div>
      }
    </>
  );
};

export default Cards;
