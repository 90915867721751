import { SITE_ADDRESS_STORAG_KEY } from './../../constants/sites-constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import {
  setSiteStatus,
  setSiteConfiguration,
  setSiteContent,
  setSiteError,
  setSiteItem,
  setSiteNameList,
  setSelectedSiteId,
} from './reducers';
import * as actions from './actions';
import * as services from './services';
import * as fieldHelper from '../../utilities/field-helper';
import * as datetimeHelper from '../../utilities/datetime-helper';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import { LoadingStatus } from '../../constants/loading-constants';
import { Messages } from '../../constants/messages';
import { SITE_ID_STORAG_KEY, SITE_NAME_STORAG_KEY } from '../../constants/sites-constants';
import { currency, timezone } from '../../constants/dropdown-constants';
import { setGenericErrorData } from '../generic-error/reducers';
import { setDialogBoxActionStatus, closeDialogBox } from '../dialog-box/reducers';
import { clearAllFieldValidation } from '../fieldValidation/reducers';
import { SetDataInLocalStorage } from '../../utilities/localStorage-helper';
import { hideBackdrop, setBackDropActionStatus, setBackDropError, showBackdrop } from '../backdrop/reducers';
import { setIsPageDirty } from '../page-configuration/reducers';
import { selectSelectedAddress, selectSelectedAddressTimezone } from '../azure-map/selectors';
import { getApiErrorMessage, getGenericErrorMessage } from '../../utilities/errorhandler';
import { setSnackBarSuccess, setSnackBarError } from '../snackbar/reducers';
import { selectOrganisationId, selectUserId } from '../auth/selectors';
import { setHeaderState } from '../header/reducers';
import { LocationEntity, SiteResponseEntity } from '../../entities/site';
import { SiteConfiguration } from '../../entities/site';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { setModalActionStatus, setModalError } from '../modals/reducers';
import {
  AddressDetailsModel,
  SiteConfigurationModel,
  SiteItemModel,
  TimezoneDetailsModel,
} from '../../models/siteModel';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_SITES, loadSite);
  yield takeLatest(actions.LOAD_SITES_STATUS, loadSitesStatus);
  yield takeLatest(actions.LOAD_SITENAME_LIST, loadSiteNameList);
  yield takeLatest(actions.DELETE_SITE, deleteSiteItem);
  yield takeLatest(actions.EDIT_SITE, editSiteItem);
  yield takeLatest(actions.CREATE_SITE, createSiteItem);
  yield takeLatest(actions.SITE_CONFIGURATION, loadSiteConfiguration);
  yield takeLatest(actions.SITE_ITEM, loadSiteItem);
  yield takeLatest(actions.SET_SELECTED_SITE_ID, setSelectedSiteIdInState);
}

export function* loadSite() {
  try {
    yield put(setSiteStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);

    let response: SiteResponseEntity = yield call(services.getSiteList, organisationId);
    let records: SiteItemModel[] = yield call(MapSiteEntityToModel, response);
    yield put(setSiteContent(records));
    yield put(setSiteStatus(LoadingStatus.SUCCESS));
  } catch (error: any) {
    if (!!error || error?.message?.length < 1) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setSiteError());
  }
}

export function* loadSitesStatus() {
  try {
    yield put(setSiteStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);

    let response: SiteResponseEntity = yield call(services.getSiteStatusList, organisationId);
    let records: SiteItemModel[] = yield call(MapSiteEntityToModel, response);
    yield put(setSiteContent(records));
    yield put(setSiteStatus(LoadingStatus.SUCCESS));
  } catch (error: any) {
    if (!!error || error?.message?.length < 1) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setSiteError());
  }
}

export function* loadSiteNameList() {
  try {
    const organisationId: string = yield select(selectOrganisationId);
    let response: SiteResponseEntity = yield call(services.getSiteList, organisationId);
    let records: KeyValuePair[] = yield call(MapSiteItemEntityToKeyValuePair, response);
    yield put(setSiteNameList(records));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setSiteError());
  }
}

export function* loadSiteConfiguration(action: PayloadAction<string>) {
  try {
    if (!!action.payload) {
      yield put(setSiteStatus(LoadingStatus.LOADING));
      const organisationId: string = yield select(selectOrganisationId);
      let response: SiteConfiguration = yield call(
        services.getSiteConfigurationModalData,
        action.payload,
        organisationId
      );
      // map the entity to model
      let siteConfigurationModel: SiteConfigurationModel = yield call(MapSiteConfigurationEntityToModel, response);
      yield put(setSiteConfiguration(siteConfigurationModel));

      yield put(setSiteStatus(LoadingStatus.SUCCESS));
    }
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(clearAllFieldValidation());
    yield put(setSiteError());
  }
}

export function* loadSiteItem(action: PayloadAction<string>) {
  try {
    if (!!action.payload) {
      yield put(setModalActionStatus(LoadingStatus.LOADING));
      yield put(setHeaderState(LoadingStatus.LOADING));
      const organisationId: string = yield select(selectOrganisationId);
      const userId: string = yield select(selectUserId);
      let response: SiteItemModel = yield call(services.getSiteItemData, action.payload, organisationId);
      // map the entity to model
      let siteItemModel: SiteItemModel = yield call(MapSiteItemEntityToModel, response);

      yield call(
        SetDataInLocalStorage,
        `${SITE_NAME_STORAG_KEY}-${userId}`,
        siteItemModel.name ? siteItemModel.name : ''
      );
      yield call(
        SetDataInLocalStorage,
        `${SITE_ADDRESS_STORAG_KEY}-${userId}`,
        siteItemModel.address ? siteItemModel.address : ''
      );

      yield put(setSiteItem(siteItemModel));
      yield put(setHeaderState(LoadingStatus.SUCCESS));
    }
  } catch {
    yield put(setHeaderState(LoadingStatus.ERROR));
    yield put(setModalError(true));
  }
}

export function* deleteSiteItem(action: PayloadAction<string>) {
  try {
    yield put(setDialogBoxActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    yield call(services.deleteSiteData, action.payload, organisationId);
    yield put(closeDialogBox());
    yield put(setSnackBarSuccess(Messages.SITE_DELETE_SUCESS));
    yield call(loadSitesStatus);
  } catch (error) {
    yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* editSiteItem(action: PayloadAction<SiteConfigurationModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    let siteEntity: SiteConfiguration = MapSiteConfigModelToEntity(action.payload);
    if (action.payload.address !== action.payload.location.address.freeformAddress) {
      const timezone: string = yield select(selectSelectedAddressTimezone);
      const addressModel: AddressDetailsModel = yield select(selectSelectedAddress);
      const location: LocationEntity = {
        address: addressModel.address,
        timezone: { id: timezone } as TimezoneDetailsModel,
      };
      siteEntity.location = location;
    }

    yield call(services.editSiteData, siteEntity, action.payload.id);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.SITE_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* createSiteItem(action: PayloadAction<SiteConfigurationModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    let siteEntity: SiteConfiguration = MapSiteConfigModelToEntity(action.payload);
    const organisationId: string = yield select(selectOrganisationId);
    const timezone: string = yield select(selectSelectedAddressTimezone);
    const addressModel: AddressDetailsModel = yield select(selectSelectedAddress);
    const location: LocationEntity = {
      address: addressModel.address,
      timezone: { id: timezone } as TimezoneDetailsModel,
    };
    siteEntity.organisationId = organisationId;
    siteEntity.location = location;

    yield call(services.createSiteData, siteEntity);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.SITE_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* setSelectedSiteIdInState(action: PayloadAction<string>) {
  try {
    const userId: string = yield select(selectUserId);
    yield call(SetDataInLocalStorage, `${SITE_ID_STORAG_KEY}-${userId}`, action.payload);
    yield put(setSelectedSiteId(action.payload));
  } catch {
    yield put(setSiteError());
  }
}

const MapSiteItemEntityToModel = (response: SiteItemModel) => {
  if (response) {
    return {
      id: response.id,
      organisationId: response.organisationId,
      name: response.name,
      address: response.address,
      health: response.health,
      status: response.status,
      lastStatus: response.lastStatus,
      lastUpdatedDateTimeUtc: response.lastUpdatedDateTimeUtc,
      path: response.path,
      taxRate: response.taxRate,
    } as SiteItemModel;
  }
  return {} as SiteItemModel;
};

export const MapSiteItemEntityToKeyValuePair = (response: SiteResponseEntity) => {
  if (response && response.items.length > 0) {
    const result: KeyValuePair[] = response.items.map((item) => {
      return {
        key: item.id,
        value: item.name,
      };
    });

    return result;
  }
};

const MapSiteConfigurationEntityToModel = (response: SiteConfiguration) => {
  if (response) {
    return {
      id: response.id,
      address: response.location.address.freeformAddress,
      currencyCode: fieldMappingHelper.validateEnumValue(response.currencyCode, currency),
      organisationId: response.organisationId,
      name: response.name,
      timeZoneId: fieldMappingHelper.validateEnumValue(response.timeZoneId, timezone),
      taxRate: response.taxRate,
      location: response.location,
    } as unknown as SiteConfigurationModel;
  }

  return {} as SiteConfigurationModel;
};

export const MapSiteEntityToModel = (response: SiteResponseEntity) => {
  if (response && response.items.length > 0) {
    const result: SiteItemModel[] = response.items.map((item) => {
      return {
        id: item.id,
        organisationId: item.organisationId,
        name: fieldHelper.getDefaultStringvalue(item.name),
        address: item.address,
        status: fieldHelper.getDefaultStringvalue(item.status),
        lastUpdatedDateTimeUtc: datetimeHelper.getDayCounter(item.dateTimeUtc),
        health: fieldHelper.getHealthStatusIndicator(item.status),
        lastStatus: '',
        path: '',
        icon: item.icon,
        timezone: item.timeZoneId,
        taxRate: item.taxRate,
        terminalStatus: item.terminals?.sort((a: any, b: any) => a.number - b.number),
        pumpStatus: item.pumps?.sort((a: any, b: any) => a.number - b.number),
        tankStatus: item.tanks?.sort((a: any, b: any) => a.number - b.number),
        priceSignStatus: item.priceSigns?.sort((a: any, b: any) => a.number - b.number),
        forecourtControllerStatus: item.forecourtControllers?.sort((a: any, b: any) => a.number - b.number),
      };
    });

    return result;
  }

  return [] as SiteItemModel[];
};

const MapSiteConfigModelToEntity = (model: SiteConfigurationModel) => {
  if (model) {
    let siteConfigDetails = {
      organisationId: model.organisationId,
      name: fieldMappingHelper.sanitizeStringValue(model.name),
      currencyCode: fieldMappingHelper.sanitizeStringDropDownValue(model.currencyCode),
      taxRate: fieldMappingHelper.sanitizeNumericValue(model.taxRate),
      location: model.location,
    } as unknown as SiteConfiguration;

    return siteConfigDetails;
  }

  return {} as SiteConfiguration;
};
